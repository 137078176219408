import fetch from '@/common/fetch/index.js'

//获取文章列表
export function list(params) {
	return fetch({
		url: 'article',
		method: 'get',
		params,
	})
}
//获取常见问题
export function faq(params) {
	return fetch({
		url: 'faq',
		method: 'get',
		params,
	})
}
//获取文章详情
export function detail(code) {
	return fetch({
		url: `article/${code}`,
		method: 'get',
	})
}
//获取案件报告数据
export function caseReportData(params) {
	return fetch({
		url: `lawcaseReport`,
		method: 'get',
		params,
	})
}
//查询暗号
export function getSignal(params) {
	return fetch({
		url: `report`,
		method: 'get',
		params,
	})
}

//获取微信h5跳转小程序的参数
export function getH5ToWxminiParams(params) {
	return fetch({
		url: `getH5ToWxminiParams`,
		method: 'get',
		params,
	})
}
//获取微信h5跳转小程序的参数
export function downloadApp(params = {}) {
	return fetch({
		url: `getAppVersion/1`,
		method: 'get',
		params,
	})
}
