<template>
   <div style="background: #fff;">
        <div class="item_wrap step1">
            <div class="item_imgbox">
                <img :src="item_img1" width="100%" />
            </div>
            <div class="item_btm">
                <div class="item_tit">
                碎片时间随时记录<br/>关联案件自动归档
                </div>
                <div class="item_txt">事项太多总怕有遗漏？案件云的日程管理自定义时间提醒、业务日程、待办清单功能一应俱全，同时与案件、项目和客户深度关联。团队可以共享日程，相互知晓任务安排，管理者可以根据团队成员的整体时间规划制定行之有效的团队进度计划，促进团队成员协同办公。
                </div>
                <div class="item_btn">
                <span @click="jumpGuide">立即下载</span>
                </div>
            </div>
        </div>
        <div class="item_wrap1" v-for="(item,index) in wrap_data" :key="index">
            <div class="item_wrap1_tit">{{item.tit}}</div>
            <p class="item_wrap1_txt" v-html="item.txt"></p>
            <div>
                <img :src="item.img" width="100%" />
            </div>
        </div>
        <div class="ptp50">
            <nowdownload />
        </div>
        <Footer />
   </div>
</template>

<script>
import nowdownload from '@/components/nowdownload/index'
import { Footer} from '@/components'
function requireImg(str) {
    return require("pbimages/calendar/" + str);
}
export default {
    components:{
        nowdownload,
        Footer
    },
    data(){
        return{
            item_img1:requireImg("step1.png"),
            wrap_data:[
                {tit:'日程即时提醒',
                    txt:"设置日程提醒时间后，系统通过微信、App、桌面等多种方式，发送即时提醒，让工作不再遗漏。",
                    img:requireImg('step2.png'),
                },
                {tit:'日程关联案件',
                    txt:"日程选择关联到案件、项目或客户关联后，同步在案件、项目或客户记录页面展示。",
                    img:requireImg('step3.png'),
                },
                {tit:'待办清单',
                    txt:"待办清单自动罗列每日事项安排，用户可按清单来规划时间，从冗杂的待办事项中解脱出来。",
                    img:requireImg('step4.png'),
                }, 
                {tit:'日程协作',
                    txt:"根据日程任务的需要，成员任一发起待办时，添加同事为协办来完成任务，帮助更快完成目标任务。",
                    img:requireImg('step5.png'),
                },
                {tit:'团队日程',
                    txt:"团队日程共享，团队的每一项工作事项都有记录可查，便于快速复盘整理。",
                    img:requireImg('step6.png'),
                },
                {tit:'私密日程',
                    txt:"若您不想公开某条日程安排，可在创建日程时选择设置为私密，则该条日程仅自己可见。",
                    img:requireImg('step7.png'),
                },
            ],
        }
    },
    mounted(){
    },
    methods:{
        jumpGuide () {
            this.$router.push({ name: 'downApp' })
        },
    }
    
}
</script>

<style lang="stylus" scoped>
    .item_wrap{
        &.step1{
            //background: linear-gradient(180deg, #F2F8FE 0%, #EAF1FF 100%);
            background: url("../../public/images/case/bg.jpg") no-repeat;
            background-size: cover;
            background-position-x: 70%;
        }
        .item_imgbox{
        padding: 65px 63px 60px;
        }
        .item_btm{
            //background: #F2F3F5;
            padding: 0 0 60px 0;
            .item_tit{
                font-size: 48px;
                font-weight: 800;
                color: #303443;
                line-height: 58px;
                text-align: center;
                
            }
            .item_txt{
                font-size: 26px;
                font-weight: 500;
                color: #5E606B;
                line-height: 46px;;
                padding: 39px 35px 97px;
            }
            .item_btn{
                display: flex;
                justify-content: center;
                span{
                    width: 228px;
                    height: 80px;
                    background: #FABA4A;
                    border-radius: 10px;
                    color: #fff;
                    font-size: 30px;
                    font-weight: bold;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .item_wrap1{
        padding: 80px 40px 50px;
        &_tit{
            font-size: 34px;
            font-weight: bold;
            color: #303443;
            text-align: left;
            padding: 0 0 20px 0;
        }
        &_txt{
            font-size: 26px;
            font-weight: 500;
            color: #7E818F;
            line-height: 46px;
            text-align: left;
        }
        &:nth-child(even){
            background: #fff;
        }
        &:nth-child(odd){
            background: #F8F9FB;
        }
    }
    .ptp50{
        padding-top: 50px;
    }
    .tab_wrap{
        display: flex;
        justify-content: center;
        height: 140px;
        background: #F5F8FF;
        .tab_main{
            display: flex;
            align-items: center;
            span{
                font-size: 36px;
                font-weight: 500;
                color: #7E818F;
                display: flex;
                height: 100%;
                align-items: center;
                position relative;
                &:first-child{
                    margin: 0 143px 0 0;
                }
                img{
                    width 40px;
                    margin: 0 8px 0 0;
                }
                &.active{
                    &:after{
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        background: #303443;
                        height: 4px;
                        content: "";
                    }
                    
                }
            }
        }
    }
</style>