<template>
<div class="sign-wrap">
    <div class="common-banner sign-banner">
        <p class="common-banner__chinese">电子签章</p>
        <p class="common-banner__english">Electronic seal</p>
    </div>
    <div class="sign-content">
        <div class="title"></div>
    </div>
    <div style="background: #fff;">
        <JumpForm />
    </div>
    <Footer/>
</div>
</template>

<script>
import { JumpForm } from '@/components'
import { Footer } from '@/components'
export default {
    name: 'safe',
    components: {
        JumpForm,
        Footer
    },
    data () {
        return {
            
        }
    },
    mounted () {

    },
    methods: {
        
    }
}
</script>
<style lang="stylus">

</style>


