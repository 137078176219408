<template>
<div class="personal-web">
    <div class="top-banner">
        <div class="create-btn">
            <span class="create-txt" @click="createWeb">极速创建</span>
            <i class="iconfont icon-weibiaoti40"></i>
        </div>
        <div class="top-title">全方位形象展示</div>
    </div>
    <div class="web-back2">
        <div class="web-item-wrap">
            <div class="web-item">
                <img class="item-icon" src="../../public/images/personalWeb/icon1.png" alt="">
                <div class="item-line"></div>
                <div class="item-title">个人名片</div>
            </div>
            <div class="web-item">
                <img class="item-icon" src="../../public/images/personalWeb/icon2.png" alt="">
                <div class="item-line"></div>
                <div class="item-title">精彩案例</div>
            </div>
            <div class="web-item">
                <img class="item-icon" src="../../public/images/personalWeb/icon3.png" alt="">
                <div class="item-line"></div>
                <div class="item-title">律师文集</div>
            </div>
            <div class="web-item">
                <img class="item-icon" src="../../public/images/personalWeb/icon4.png" alt="">
                <div class="item-line"></div>
                <div class="item-title">留言板</div>
            </div>
        </div>
    </div>
    <div class="web-title">多风格模板可选</div>
    <div class="web-txt-wrap">
        <div class="num">01</div>
        <div class="title">
            <div class="rectangle"></div>
            <div class="title-txt">时尚出彩风格</div>
            <div class="rectangle rectangle2"></div>
        </div>
        <div class="content-txt">采用一屏式网站设计，风格独特，样式新颖，在<br>视觉体验上带来耳目一新的感觉</div>
    </div>
    <img src="../../public/images/personalWeb/web-img1.png" class="web-img1" alt="">
    <div class="rectangle-back">
        <div class="web-txt-wrap pd-top-50">
            <div class="num">02</div>
            <div class="title">
                <div class="rectangle"></div>
                <div class="title-txt">简约社交风格</div>
                <div class="rectangle rectangle2"></div>
            </div>
            <div class="content-txt">左右结构样式设计，画面一目了然，清新<br>简约，自成一派</div>
        </div>
        <img src="../../public/images/personalWeb/web-img2.png" class="web-img2" alt="">
    </div>
    <div class="web-txt-wrap">
        <div class="num">03</div>
        <div class="title">
            <div class="rectangle"></div>
            <div class="title-txt">沉静严谨风格</div>
            <div class="rectangle rectangle2"></div>
        </div>
        <div class="content-txt">暗黑系列，黄色辅助，低调中不缺活力，严谨中带丝<br>愉悦，采用居中式设计，聚焦客户的目光</div>
        <img src="../../public/images/personalWeb/web-img3.png" class="web-img3" alt="">
    </div>
    <div class="web-bottom">
        <img class="gray-back" src="../../public/images/personalWeb/gray-back.png" alt="">
        <img class="blue-back" src="../../public/images/personalWeb/blue-back.png" alt="">
        <div class="web-title mg-top--500">傻瓜式后台管理</div>
        <div class="web-item-wrap mg-top-40">
            <div class="web-item web-item2">
                <img class="item-icon item-icon2" src="../../public/images/personalWeb/icon5.png" alt="">
                <div class="item-line"></div>
                <div class="item-title">名片信息自动<br>同步</div>
            </div>
            <div class="web-item web-item2">
                <img class="item-icon item-icon2" src="../../public/images/personalWeb/icon6.png" alt="">
                <div class="item-line"></div>
                <div class="item-title">内容顺序即时<br>调整</div>
            </div>
            <div class="web-item web-item2">
                <img class="item-icon item-icon2" src="../../public/images/personalWeb/icon7.png" alt="">
                <div class="item-line"></div>
                <div class="item-title">移动端同步<br>更新</div>
            </div>
        </div>
    </div>
    <JumpForm />
    <Footer/>
</div>
</template>

<script>
import { JumpForm } from '@/components'
import { Footer } from '@/components'
export default {
    name: 'personalWeb',
    components: {
        JumpForm,
        Footer
    },
    data () {
        return {
            
        }
    },
    created () {
    },
    methods: {
        createWeb() {
            this.$dialog.open('请去PC端进行创建')
        }
    }
}
</script>
