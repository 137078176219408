<template>
   <div style="background: #fff;">
        <div class="item_wrap step1">
            <div class="item_imgbox">
                <img :src="item_img1" width="100%" />
            </div>
            <div class="item_btm">
                <div class="item_tit">
               安全便捷的电子化<br/>签约服务
                </div>
                <div class="item_txt">全面电子化的律师事务所，协助律所完成一切法律协议的电子化签署。短短几分钟内便可完成文件签署或签名索取，减少在行政事务中耗费的时间。简化签约流程让律师把更多精力放在客户的案子上，才有更多时间服务客户，保持竞争力！
                </div>
                <div class="item_btn">
                <span @click="jumpGuide">立即下载</span>
                </div>
            </div>
        </div>
        <div class="item_wrap2">
            <div class="item_wrap2_tit">
                案件云电子签章解决律所<br/>线下签署四大痛点
            </div>
            <ul class="item_wrap2_list">
                <li class="li_item" v-for="(item,index) in ht_data" :key="index" :class="{active:activeindex == index}">
                    <div class="li_item_tp" @click="activeindex = index">
                        <i class="iconfont icon1" :class="item.icon"></i>
                        <div class="rt_item">
                            <div class="tit">{{item.tit}}</div>
                            <p class="txt" v-if="activeindex !== '' && activeindex == index">{{item.txt}}</p>
                        </div>
                    </div>
                    <transition name="slide-down">
                        <div class="li_item_btm" v-if="activeindex !== '' && activeindex == index">
                            <div class="item_wrap2_tit1">
                                {{item.tit1}}
                            </div>
                            <p class="item_wrap2_txt">{{item.txt1}}</p>
                            <div class="item_wrap2_imgbox">
                                <img width="100%" :src="item.img"  />
                            </div>
                        </div>
                    </transition>
                </li>
            </ul>
        </div>
        <div class="item_wrap3">
            <div class="item_wrap3_tit">案件云电子签章使用流程</div>
            <div class="item_wrap3_tablist">
                <span class="tabitem" @click="processAc = 0" :class="{active:processAc == 0}">内部签章</span>
                <span class="tabitem" @click="processAc = 1" :class="{active:processAc == 1}">外部签约</span>
            </div>
            <div v-if="processAc == 0" class="item_wrap3_list">
                <div>创建申请表单</div>
                <i class="iconfont icon-xiangshangjiantou icon1"></i>
                <div>上传附件，选择签章类型</div>
                <i class="iconfont icon-xiangshangjiantou icon1"></i>
                <div>提交申请</div>
                <i class="iconfont icon-xiangshangjiantou icon1"></i>
                <div>审批者签章</div>
            </div>
            <div v-if="processAc == 1" class="item_wrap3_list">
                <div>创建申请表单</div>
                <i class="iconfont icon-xiangshangjiantou icon1"></i>
                <div>上传附件，选择签章类型</div>
                <i class="iconfont icon-xiangshangjiantou icon1"></i>
                <div>提交申请</div>
                <i class="iconfont icon-xiangshangjiantou icon1"></i>
                <div>审批者签章并发起电子签约</div>
                <i class="iconfont icon-xiangshangjiantou icon1"></i>
                <div>客户接收通知</div>
                <i class="iconfont icon-xiangshangjiantou icon1"></i>
                <div>客户签约</div>
            </div>
        </div>
        <div class="item_wrap4">
            <div class="item_wrap4_tit">
                案件云电子签章的优势
            </div>
            <swiper class="main_item_swiper" :options="swiperOption">   
                <swiper-slide class="main_item_slide" v-for="(item,index) in advantageData" :key="index">
                    <div class="slide_item">
                        <img :src="item.icon" />
                        <div class="main_item_titbox" :class='`color${index}`'>
                            {{item.tit}}
                        </div>
                        <p class="txt">
                            {{item.txt}}
                        </p>
                    </div>
                </swiper-slide>
                <div class="swiper-pagination swiper-pagination0" slot="pagination"></div>
            </swiper>
        </div>
        <div class="item_wrap5">
            <div class="item_wrap5_tit">
                案件云电子签章价格方案
            </div>
            <div class="item_wrap5_table">
                <div class="table_item head">
                    <span>购买量<br/>（次）</span>
                    <span>契约锁官方价<br/> （元/次）</span>
                    <span>案件云团购价<br/> （元/次）</span>
                </div>
                <div class="table_item body">
                    <span>0~500</span>
                    <span>5</span>
                    <span>4</span>
                </div>
                <div class="table_item body">
                    <span>500~1000</span>
                    <span>4</span>
                    <span>3</span>
                </div>
                <div class="table_item body">
                    <span>1001~5000</span>
                    <span>3</span>
                    <span>2</span>
                </div>
                <div class="table_item body">
                    <span>5001~10000</span>
                    <span>2.5</span>
                    <span>1.5</span>
                </div>
                <div class="table_item body">
                    <span>10000以上</span>
                    <span>1.5</span>
                    <span>1</span>
                </div>
            </div>
            <p class="item_wrap5_txt">备注：律所与外部签约1份合同时，双方各盖章1次总计2次</p>
        </div>
        <div>
            <nowdownload />
        </div>
        <Footer />
   </div>
</template>

<script>
import nowdownload from '@/components/nowdownload/index'
import { Footer} from '@/components'
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/swiper.min.css";
function requireImg(str) {
    return require("pbimages/prosign/" + str);
}
export default {
    components:{
        nowdownload,
        swiper,
        swiperSlide,
        Footer
    },
    data(){
        return{
            item_img1:requireImg("step1.png"),
            item_img2:requireImg("img1.png"),
            activeindex: 0,
            ht_data:[
                {icon:'icon-qianshu',
                tit:'合同签署成本高',txt:'线下签署合同纸张成本、打印成本、异地签署的快递成本高昂。',
                tit1:'合同签署成本直降80%',txt1:'全程线上完成，无需打印、邮寄',img:requireImg('tab0.png')},
                {icon:'icon-xiaoshuaigao',
                tit:'纸质签署效率低',txt:'异地盖章需经历审批、线下盖章、来回快递等流程，周期长效率低，严重影响业务进度',
                tit1:'1分钟搞定异地签署',txt1:'律所线上审批和盖章同时进行，只要有网就可以操作',img:requireImg('tab1.png')},
                {icon:'icon-hetong',
                tit:'纸质合同管理难',txt:'海量纸质合同管理环境要求高，查找困难，容易丢失',
                tit1:'永久存档，随用随查',txt1:'系统自动保存签约合同，支持分类查询、下载',img:requireImg('tab2.png')},
                {icon:'icon-fengxian',
                tit:'潜在签约风险大',txt:'线下签署盗用、私刻公章和篡改合同频发，存在较大假冒代签的风险',
                tit1:'法律效力有保障',txt1:'签署双方需经CA认证身份并核验数字证书，杜绝假冒',img:requireImg('tab3.png')},
            ],
            processAc: 0,
            swiperOption: {
            　　pagination: {
                    el:'.swiper-pagination0',
                    clickable: true,
                },
            　　 slidesPerView: 1.5,
                spaceBetween: 20,
                loop: false,
            },
            advantageData:[
                {tit:'安全',icon:requireImg('icon1.png'),txt:'电子签章接入公安系统验证，身份绝无冒充；案件云与第三方平台对签署过程进行联合技术保障，信息全程加密，点对点签署，确保安全无虞。'},
                {tit:'便捷',icon:requireImg('icon2.png'),txt:'电子签章署与律所审批流程无缝对接，一键发起，多方传达，申请者与审批者都可以实时跟踪签署状态，签署进程尽在掌握。'},
                {tit:'灵活',icon:requireImg('icon3.png'),txt:'电子印章无需交由固定人员保管，可实现“集中管控”与“分权管理”相结合，管理员指定人员都可以盖章，盖章记录可查，灵活提效。'}
            ],
        }
    },
    methods:{
        jumpGuide () {
            this.$router.push({ name: 'downApp' })
        },
    }
    
}
</script>

<style lang="stylus" scoped>
.slide-down-enter-to,
.slide-down-leave {
  max-height: 5000px;
  overflow: hidden;
}

.slide-down-enter,
.slide-down-leave-to {
  overflow: hidden;
  max-height: 0;
}
    .item_wrap{
        &.step1{
            background: url("../../public/images/case/bg.jpg") no-repeat;
            background-size: cover;
            background-position-x: 60%;
        }
        .item_imgbox{
        padding: 65px 65px 60px;
        
        }
        .item_btm{
            //background: #F2F3F5;
            padding: 0 0 60px 0;
            .item_tit{
                font-size: 48px;
                font-weight: 800;
                color: #303443;
                line-height: 58px;
                text-align: center;
                
            }
            .item_txt{
                font-size: 26px;
                font-weight: 500;
                color: #5E606B;
                line-height: 46px;;
                padding: 39px 35px 30px;
            }
            .item_btn{
                display: flex;
                justify-content: center;
                span{
                    width: 228px;
                    height: 80px;
                    background: #FABA4A;
                    border-radius: 10px;
                    color: #fff;
                    font-size: 30px;
                    font-weight: bold;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .item_wrap2{
        &_tit{
            font-size: 48px;
            font-weight: bold;
            color: #303443;
            line-height: 60px;
            text-align: center;
            padding: 93px 0 53px;
        }
        &_tit1{
            font-size: 46px;
            font-weight: bold;
            color: #303443;
            text-align: center;
            padding: 60px 0 40px;
        }
        .item_wrap2_list{
            padding: 0 35px;
            .li_item{
                margin: 0 0 40px;
                .li_item_tp{
                    display: flex;
                    align-items: center;
                    height: 112px;
                    background: #F5F7FA;
                    border-radius: 10px;
                    padding: 0 20px;
                    .icon1{
                        font-size: 52px;
                        color: #959BA7;
                        margin: 0 20px 0 0;
                    }
                    .rt_item{
                        .tit{
                             font-size: 34px;
                            font-weight: bold;
                            color: #959BA7;
                        }
                        .txt{
                            font-size: 26px;
                            font-weight: 500;
                            color: #7E818F;
                            line-height: 46px;
                        }
                    }
                }
                .li_item_btm{
                    .item_wrap2_txt{
                        font-size: 26px;
                        font-weight: 500;
                        color: #303443;
                        text-align: center;
                    }
                    .item_wrap2_imgbox{
                        padding: 40px 0 10px;
                    }
                }
                &.active{
                    .li_item_tp{
                        background: #FFFFFF;
                        border: 1px solid #DEE3ED;
                        box-shadow: 1px 2px 10px 0px rgba(202, 204, 217, 0.7);
                        border-radius: 10px;
                        height: auto;
                        align-items: flex-start;
                        padding: 30px 20px 35px;
                        .icon1{
                            color: #FABA4A;
                        }
                        .rt_item{
                            .tit{
                                color: #303443;
                                padding: 0 0 25px 0;
                            }
                        }
                    }
                    
                }
            }
            
        }
    }
    .item_wrap3{
        padding: 80px 0;
        &_tit{
            font-size: 48px;
            font-weight: bold;
            color: #303443;
            text-align: center;
        }
        &_tablist{
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 60px 0 40px;
            .tabitem{
                text-align: center;
                position relative;
                font-size: 30px;
                color: #959BA7;
                min-height: 50px;
                font-weight: bold;
                &.active{
                    font-size: 36px;
                    color: #FABA4A;
                    &::after{
                        width: 80px;
                        height: 4px;
                        background: #303443;
                        left: 50%;
                        margin-left: -40px;
                        content: '';
                        position: absolute;
                        bottom: 0;
                    } 
                }
                
            }
        }
        &_list{
            text-align: center;
            padding: 0 35px;
            .icon1{
                color: #FFD699;
                font-size: 40px;
                padding: 10px 0;
            }
            >div{
                color: #303443;
                font-size: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 82px;
                background: #FDF1DF;
                &:first-child{
                    background: #EAEDF8;
                }
            }
        }
    }
    .item_wrap4{
        background: #F8F9FC;
        padding: 0 35px 80px;
        &_tit{
            font-size: 48px;
            font-weight: bold;
            color: #303443;
            text-align: center;
            padding: 60px 0;
        }
        .slide_item{
            background: #fff;
            padding: 98px 40px 67px;
            img{
                width: 70px;
                position: absolute;
                top: 25px;
                right: 20px;
            }
            .main_item_titbox{
                font-size: 34px;
                font-weight: bold;
                color: #303443;
                position relative;
                padding: 0 0 48px 0;
                &.color1{
                    &:after{
                        background: #B3C5FF;
                    }
                    
                }
                &.color2{
                    &:after{
                        background: #FDADAD;
                    }
                    
                }
                &:after{
                    width: 30px;
                    height: 6px;
                    background: #FABA4A;
                    content: "";
                    position: absolute;
                    left: 0;
                    top: -30px;
                }       
            }
            .txt{
                font-size: 26px;
                font-weight: 500;
                color: #7E818F;
                line-height: 46px;
                text-align: justify;
                min-height: 270px;
            }

        }
        
    } 
    .item_wrap5{
        padding: 0 35px 95px;
        &_tit{
            font-size: 48px;
            font-weight: bold;
            color: #303443;
            text-align: center;
            padding: 100px 0 50px;
        }
        &_table{
            .table_item{
                display: flex;
                align-items: center;
                justify-content: center;
                &.head{           
                    span{
                        min-height: 110px;
                        background: #F8F9FC;
                        &:last-child{
                            background: #FEE0B3;
                            border-right: 0;
                        }
                    }
                }
                &.body{
                    span{
                        min-height: 80px;
                        background: #F8F9FC;
                        &:last-child{
                            background: #FDF1DF;
                            border-right: 0;
                        }
                    }
                }
                span{
                    font-size: 28px;
                    font-family: PingFang SC;
                    font-weight: bold;
                    color: #303443;
                    line-height: 36px;
                    display: flex;
                    align-items: center;
                    width: calc(100% / 3);
                    justify-content: center;
                    border-right: 3px solid #fff;
                    border-bottom: 3px solid #fff;
                }
            }
        }
        &_txt{
            padding: 35px 0 0;
            font-size: 26px;
            font-weight: 500;
            color: #303443;
            line-height: 36px;
        }
    }
    .ptp50{
        padding-top: 50px;
    }
</style>