//导入模块
// import vue
import axios from 'axios'

import {
    server_base_url
} from '@/common/config/index'

import Vue from 'vue'
import {
    Toast
} from 'cube-ui'

Vue.use(Toast)

import { dialog as myDialog } from '../../components/dialog/index.js';



const toast = Toast.$create({
    time: 0,
    txt: 'Loading...',
})
export default function fetch(options) {
    if (!options && !options.params && !options.params.hideToast) {
        toast.show()
    }
    return new Promise((resolve, reject) => {
        //创建一个axios实例
        const instance = axios.create({
            //设置默认根地址
            baseURL: server_base_url,
            //设置请求超时设置
            timeout: 3000,
            transformRequest: [function (options) {
                // Do whatever you want to transform the data
                let ret = ''
                for (let it in options) {
                    ret += encodeURIComponent(it) + '=' + encodeURIComponent(options[it]) + '&'
                }
                return ret
            }],
            //设置请求时的header
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
        

        //请求处理
        instance(options)
            .then(({
                data: {
                    code,
                    msg,
                    data
                }
            }) => {

                toast.hide()
                //请求成功时,根据业务判断状态
                if (code === 0) {

                    resolve({
                        code,
                        msg,
                        data
                    })
                    return false
                }
                if (msg === '暗号查询失败') {
                    myDialog.open('您输入的查阅码有误，请联系案件负责人进行确认。')
                } else {
                    myDialog.open(msg)
                }
                

                reject({
                    code,
                    msg,
                    data
                })
            })
            .catch((error) => {
                toast.hide()
                //请求失败时,根据业务判断状态
                if (error.response) {
                    console.log(error.response)
                    let resError = error.response
                    let resCode = resError.status
                    let resMsg = resError.data.msg
                    if (resError.status === 429) {
                        myDialog.open('您的操作过于频繁，我都忙不过来了哟！')
                    } else {
                        myDialog.open('网络请求异常')
                    }
                    reject({
                        code: resCode,
                        msg: resMsg
                    })
                }
            })
    })
}