<template>
  <div class="article-wrap">
    <div class="banner-wrap article-banner">
      <div class="banner-content">
        <h2 class="banner-content__h2">AI LEGAL VISION</h2>
        <p class="banner-content__h3">AI法律视野</p>
        <span class="banner-content__tag"></span>
      </div>
    </div>
    <div class="article">
      <h3 class="article-title">{{articleData.title}}</h3>
      <div class="article-date">文章发表于 {{articleData.updated_at}}</div>
      <div
        class="markdown article-text"
        v-html="articleData.content_html"
      ></div>
    </div>
    <Footer/>
  </div>

</template>

<script>
import { Footer } from '@/components'
export default {
  name: 'Detail',
  components: {Footer},
  data () {
    return {
      articleData: {},
    }
  },
  created () {
    this.getData()
  },

  methods: {

    getData () {
      let query = this.$route.query
      this.$fetch.api_article.detail(query.code)
        .then(({
          data
        }) => {
          this.articleData = data
        })
        .catch((data) => {
          this.$createToast({
            txt: data.msg,
            type: 'error'
          }).show()
        })
    },

  }

}
</script>

<style lang="stylus">
@require 'assets/stylus/color-varaibles';

.article {
  padding: 60px 30px;

  &-title {
    text-align: center;
    font-size: 30px;
    line-height: 1.5;
    color: $color-primary-black;
    font-weight: 600;
    margin-bottom: 28px;
  }

  &-date {
    text-align: center;
    font-size: 24px;
    color: $color-light-black;
    margin-bottom: 50px;
  }

  &-text {
    font-size: 24px;
    color: $color-light-black;

    img {
      width: 100%;
    }

    b {
      color: $color-primary-black;
    }

    h4 {
      font-size: 26px;
      color: $color-primary-black;
    }
  }
}
</style>