
import fetch from '@/common/fetch/index.js'

//获取财务数据
export function getData (params) {
    return fetch({
        url: '/api/finance',
        method: 'get',
        params
    })
}