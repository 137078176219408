<template>
   <div style="background: #fff;">
        <div class="item_wrap step1">
            <div class="item_imgbox">
                <img :src="item_img1" width="100%" />
            </div>
            <div class="item_btm">
                <div class="item_tit">
                数据全方位汇总<br/>清晰量化投入产出比
                </div>
                <div class="item_txt">上半年新增了多少案件？今年办结了多少业务？我的案子花费了多少工时？问题千千万，一个统计就可搞定！<br/>
                    案件云统计功能将案件、项目、客户、财务等模块的数据进行了多维度汇总和可视化展示，即使数据再分散琐碎，也能有效整合，直观传达。
                </div>
                <div class="item_btn">
                <span @click="jumpGuide">立即下载</span>
                </div>
            </div>
        </div>
        <div class="item_wrap1" v-for="(item,index) in wrap_data" :key="index">
            <div class="item_wrap1_tit">{{item.tit}}</div>
            <p class="item_wrap1_txt" v-html="item.txt"></p>
            <div>
                <img :src="item.img" width="100%" />
            </div>
        </div>
        <div class="ptp50">
            <nowdownload />
        </div>
        <Footer />
   </div>
</template>

<script>
import nowdownload from '@/components/nowdownload/index'
import { Footer} from '@/components'
function requireImg(str) {
    return require("pbimages/statics/" + str);
}
export default {
    components:{
        nowdownload,
        Footer
    },
    data(){
        return{
            item_img1:requireImg("step1.png"),
            wrap_data:[
                {tit:'案件/项目统计',
                    txt:"工时统计<br/>存量、增量变化统计<br/>胜诉率、达成诉求量<br/>类型分布、增量分时",
                    img:requireImg('step2.png'),
                },
                {tit:'客户统计',
                    txt:"存量、增量变化统计<br/>来源分布、增量分时",
                    img:requireImg('step3.png'),
                },
                {tit:'财务统计',
                    txt:"财务应收、已收、待收、收益自动统计<br/>实际支出分布、成本分摊、收益分布统计",
                    img:requireImg('step4.png'),
                }, 
            ],
        }
    },
    mounted(){
    },
    methods:{
        jumpGuide () {
            this.$router.push({ name: 'downApp' })
        },
    }
    
}
</script>

<style lang="stylus" scoped>
    .item_wrap{
        &.step1{
            //background: linear-gradient(180deg, #F2F8FE 0%, #EAF1FF 100%);
            background: url("../../public/images/case/bg.jpg") no-repeat;
            background-size: cover;
            background-position-x: 70%;
        }
        .item_imgbox{
        padding: 65px 63px 60px;
        }
        .item_btm{
            //background: #F2F3F5;
            padding: 0 0 60px 0;
            .item_tit{
                font-size: 48px;
                font-weight: 800;
                color: #303443;
                line-height: 58px;
                text-align: center;
                
            }
            .item_txt{
                font-size: 26px;
                font-weight: 500;
                color: #5E606B;
                line-height: 46px;;
                padding: 39px 35px 97px;
            }
            .item_btn{
                display: flex;
                justify-content: center;
                span{
                    width: 228px;
                    height: 80px;
                    background: #FABA4A;
                    border-radius: 10px;
                    color: #fff;
                    font-size: 30px;
                    font-weight: bold;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .item_wrap1{
        padding: 80px 40px 50px;
        &_tit{
            font-size: 34px;
            font-weight: bold;
            color: #303443;
            text-align: left;
            padding: 0 0 20px 0;
        }
        &_txt{
            font-size: 26px;
            font-weight: 500;
            color: #7E818F;
            line-height: 46px;
            text-align: left;
        }
        &:nth-child(even){
            background: #fff;
        }
        &:nth-child(odd){
            background: #F8F9FB;
        }
    }
    .ptp50{
        padding-top: 50px;
    }
    .tab_wrap{
        display: flex;
        justify-content: center;
        height: 140px;
        background: #F5F8FF;
        .tab_main{
            display: flex;
            align-items: center;
            span{
                font-size: 36px;
                font-weight: 500;
                color: #7E818F;
                display: flex;
                height: 100%;
                align-items: center;
                position relative;
                &:first-child{
                    margin: 0 143px 0 0;
                }
                img{
                    width 40px;
                    margin: 0 8px 0 0;
                }
                &.active{
                    &:after{
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        background: #303443;
                        height: 4px;
                        content: "";
                    }
                    
                }
            }
        }
    }
</style>