<template>
   <div style="background: #fff;">
        <div class="item_wrap step1">
            <div class="item_imgbox">
                <img :src="item_img1" width="100%" />
            </div>
            <div class="item_btm">
                <div class="item_tit">
                非诉动态完全掌握<br/>项目报告自动生成
                </div>
                <div class="item_txt">法律顾问、资本市场项目、专项法律服务，信托项目……，法律项目太多怎么办？要迅速找到具体的项目需要的时间越来越长，时间都花在了梳理文件夹、找材料的路上，不用担心，案件云非诉项目管理让每一个非诉项目都得心应手！
                </div>
                <div class="item_btn">
                <span @click="jumpGuide">立即下载</span>
                </div>
            </div>
        </div>
        <div class="item_wrap1" v-for="(item,index) in wrap_data" :key="index">
            <div class="item_wrap1_tit">{{item.tit}}</div>
            <p class="item_wrap1_txt" v-html="item.txt"></p>
            <div>
                <img :src="item.img" width="100%" />
            </div>
        </div>
        <div class="ptp50">
            <nowdownload />
        </div>
        <Footer />
   </div>
</template>

<script>
import nowdownload from '@/components/nowdownload/index'
import { Footer} from '@/components'
function requireImg(str) {
    return require("pbimages/prt/" + str);
}
export default {
    components:{
        nowdownload,
        Footer
    },
    data(){
        return{
            item_img1:requireImg("step1.png"),
            wrap_data:[
                {tit:'非诉业务精细化管理',
                    txt:"将项目拆解为若干个阶段，明确每一阶段的待办事项和主协办人员，通过日程将项目的关键事件设定提醒，对项目的整体流程做统筹规划。",
                    img:requireImg('step2.png'),
                },
                {tit:'通过项目管理批量案件',
                    txt:"在项目中可以将关联的批量案件进行统一管理，全局把控每一关联案件的最新动态。",
                    img:requireImg('step3.png'),
                },
                {tit:'项目协同办理',
                    txt:"团队版可以设置项目的主协办，让成员共同参与项目办理。通过分工与协作，细化工作内容，将人员效能最大化。在项目记录中可以通过@的方式，提醒其他协办人员事项的完成情况。",
                    img:requireImg('step4.png'),
                }, 
            ],
        }
    },
    mounted(){
    },
    methods:{
        jumpGuide () {
            this.$router.push({ name: 'downApp' })
        },
    }
    
}
</script>

<style lang="stylus" scoped>
    .item_wrap{
        &.step1{
            background: linear-gradient(180deg, #F2F8FE 0%, #EAF1FF 100%);
            background: url("../../public/images/case/bg.jpg") no-repeat;
            background-size: cover;
            background-position-x: 70%;
        }
        .item_imgbox{
        padding: 65px 63px 60px;
        }
        .item_btm{
            //background: #F2F3F5;
            padding: 0 0 60px 0;
            .item_tit{
                font-size: 48px;
                font-weight: 800;
                color: #303443;
                line-height: 58px;
                text-align: center;
                
            }
            .item_txt{
                font-size: 26px;
                font-weight: 500;
                color: #5E606B;
                line-height: 46px;;
                padding: 39px 35px 97px;
            }
            .item_btn{
                display: flex;
                justify-content: center;
                span{
                    width: 228px;
                    height: 80px;
                    background: #FABA4A;
                    border-radius: 10px;
                    color: #fff;
                    font-size: 30px;
                    font-weight: bold;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .item_wrap1{
        padding: 80px 40px 50px;
        &_tit{
            font-size: 34px;
            font-weight: bold;
            color: #303443;
            text-align: left;
            padding: 0 0 20px 0;
        }
        &_txt{
            font-size: 26px;
            font-weight: 500;
            color: #7E818F;
            line-height: 46px;
            text-align: left;
        }
        &:nth-child(even){
            background: #fff;
        }
        &:nth-child(odd){
            background: #F8F9FB;
        }
    }
    .ptp50{
        padding-top: 50px;
    }
    .tab_wrap{
        display: flex;
        justify-content: center;
        height: 140px;
        background: #F5F8FF;
        .tab_main{
            display: flex;
            align-items: center;
            span{
                font-size: 36px;
                font-weight: 500;
                color: #7E818F;
                display: flex;
                height: 100%;
                align-items: center;
                position relative;
                &:first-child{
                    margin: 0 143px 0 0;
                }
                img{
                    width 40px;
                    margin: 0 8px 0 0;
                }
                &.active{
                    &:after{
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        background: #303443;
                        height: 4px;
                        content: "";
                    }
                    
                }
            }
        }
    }
</style>