<template>
   <div style="background: #fff;">
        <div class="item_wrap step1">
            <div class="item_imgbox">
                <img :src="item_img1" width="100%" />
            </div>
            <div class="item_btm">
                <div class="item_tit">
                一键生成与转发<br/>接收存储方便
                </div>
                <div class="item_txt">名片没带够，递着递着就没了？纸质名片太多，保存管理麻烦，翻遍了名片夹也找不到“他”！案件云的电子名片功能支持即时更新，一键转发、生成名片图、下载名片二维码等，扫一扫即刻保存到通讯录，高效地管理您的人脉资源。
                </div>
                <div class="item_btn">
                <span @click="jumpGuide">立即下载</span>
                </div>
            </div>
        </div>
        <div class="item_wrap1" v-for="(item,index) in wrap_data" :key="index">
            <div class="item_wrap1_tit">{{item.tit}}</div>
            <p class="item_wrap1_txt" v-html="item.txt"></p>
            <div>
                <img :src="item.img" width="100%" />
            </div>
        </div>
        <div>
            <nowdownload />
        </div>
        <Footer />
   </div>
</template>

<script>
import nowdownload from '@/components/nowdownload/index'
import { Footer} from '@/components'
function requireImg(str) {
    return require("pbimages/card/" + str);
}
export default {
    components:{
        nowdownload,
        Footer
    },
    data(){
        return{
            item_img1:requireImg("step1.png"),
            wrap_data:[
                {tit:'专属名片二维码',
                    txt:"云端管理名片信息，实时更新，系统自动生成独立的名片二维码。<br/>将二维码印刷在纸质名片上，作为纸质名片的拓展介绍。",
                    img:requireImg('step2.png'),
                },
                {tit:'生成名片图',
                    txt:"打破了传统纸质名片的局限性，方便存储与转发。<br/>采用图文结合，信息直观丰富，让人眼前一亮。",
                    img:requireImg('step3.png'),
                },
                {tit:'一键保存通讯录',
                    txt:"微信扫一扫快速了解律师、律师团队或律所的执行信息、业务专长。<br/>即刻将名片资料保存到手机通讯录，无需打字。",
                    img:requireImg('step4.png'),
                },   
            ]
        }
    },
    methods:{
        jumpGuide () {
            this.$router.push({ name: 'downApp' })
        },
    }
    
}
</script>

<style lang="stylus" scoped>
    .item_wrap{
        &.step1{
            background: url("../../public/images/case/bg.jpg") no-repeat;
            background-size: cover;
            background-position-x: 60%;
        }
        .item_imgbox{
        padding: 65px 65px 60px;
        
        }
        .item_btm{
            //background: #F2F3F5;
            padding: 0 0 60px 0;
            .item_tit{
                font-size: 48px;
                font-weight: 800;
                color: #303443;
                line-height: 58px;
                text-align: center;
                
            }
            .item_txt{
                font-size: 26px;
                font-weight: 500;
                color: #5E606B;
                line-height: 46px;;
                padding: 39px 35px 97px;
            }
            .item_btn{
                display: flex;
                justify-content: center;
                span{
                    width: 228px;
                    height: 80px;
                    background: #FABA4A;
                    border-radius: 10px;
                    color: #fff;
                    font-size: 30px;
                    font-weight: bold;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .item_wrap1{
        padding: 80px 40px 50px;
        &_tit{
            font-size: 34px;
            font-weight: bold;
            color: #303443;
            text-align: left;
            padding: 0 0 20px 0;
        }
        &_txt{
            font-size: 26px;
            font-weight: 500;
            color: #7E818F;
            line-height: 46px;
            text-align: left;
        }
        &:nth-child(odd){
            background: #F8F9FB;
        }
        &:nth-child(even){
            background: #fff;
        }
    }
    .ptp50{
        padding-top: 50px;
    }
</style>