/*
 * @Date: 2019-02-18 19:08:58
 * @Author: tombear
 * @LastEditors: tombear
 * @LastEditTime: 2019-02-19 09:59:47
 * @Descriptption:
 */

//接口
// export const server_base_url = 'http://api.law086.com/api/home/'
// export const img_url = 'https://oa.law086.com/'
// export const img_base_url = 'http://admin.law086.com/uploads/'

//测试环境
export const server_base_url = 'https://api.ineedtm.com/api/'
export const img_url = 'https://oa.ineedtm.com/'
export const img_base_url = 'https://admin.ineedtm.com/uploads/'
