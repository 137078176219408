<template>
	<div style="background: #fff;">
		<div class="item_wrap step1">
			<div class="item_imgbox">
				<img :src="item_img1" width="100%" />
			</div>
			<div class="item_btm">
				<div class="item_tit">
					业务收支留痕记录
					<br />财务申请在线管理
				</div>
				<div class="item_txt">案件存在多次收款和多笔支出，如何清晰了解当前案件剩余应收款项情况？案子的钱是否到账？我的报销流程到那一步了？律所今年整体的收支情况如何？案件云财务功能不仅能详细记录每一笔应收款及成本支出情况，还能留痕律所的每一笔费用申请和审批记录，通过流程化管理保障律所业务收支统计的真实性和准确性，提高财务管理效率。</div>
				<div class="item_btn">
					<span @click="jumpGuide">立即下载</span>
				</div>
			</div>
		</div>
		<div class="item_wrap1" v-for="(item, index) in wrap_data" :key="index">
			<div class="item_wrap1_tit">{{ item.tit }}</div>
			<p class="item_wrap1_txt" v-html="item.txt"></p>
			<div>
				<img :src="item.img" width="100%" />
			</div>
		</div>
		<div class="ptp50">
			<nowdownload />
		</div>
		<Footer />
	</div>
</template>

<script>
import nowdownload from '@/components/nowdownload/index'
import { Footer } from '@/components'
function requireImg(str) {
	return require('pbimages/cw/' + str)
}
export default {
	components: {
		nowdownload,
		Footer,
	},
	data() {
		return {
			item_img1: requireImg('step1.png'),
			wrap_data: [
				{ tit: '业务收支清晰记录 智能汇总统计', txt: '律师的每笔收款和支出都有记录可查，且每项收支记录关联对应的案件/项目/客户, 可以快速链接至单个业务的细账, 数据动态统计, 实时反映律师当前的收支状况。', img: requireImg('step2.png') },
				{ tit: '财务联动合同 一键锁定/解锁', txt: '集中管理和维护所内律师提交的所有案件/项目/客户合同。点击合同可进入到合同信息详情页，查看详细的合同信息、财务收费和关联申请。还可以一键锁定审核通过的合同信息，有效防止合同数据篡改。', img: requireImg('step3.png') },
				{ tit: '财务在线发布认领 多笔款项合并认领', txt: '财务人员在线发布款项，律师在线申请认领，并关联具体合同及对应的收款账单。 律所对已认领的收款进行确认，核实认领人、认领金额、对应的合同信息是否正确，避免遗漏减少坏账，有效控制业务收款。', img: requireImg('step4.png') },
				{ tit: '在线费用申请和管理 提高财务管理效率', txt: '律师在线提交开票/收款/报销/付款申请，律所管理人员在线完成审核和审批。同时，系统可以自动留存和导出所有财务申请记录。审批人员能快速获取各项收支数据, 财务人员可以大幅降低核账时间成本，律所管理者也能实时掌握事务所整体的业务回款和费用开支情况。', img: requireImg('step5.png') },
			],
		}
	},
	mounted() {},
	methods: { 
		jumpGuide() {
			this.$router.push({ name: 'downApp' })
		},
	},
}
</script>

<style lang="stylus" scoped>
.item_wrap{
    &.step1{
        //background: linear-gradient(180deg, #F2F8FE 0%, #EAF1FF 100%);
        background: url("../../public/images/case/bg.jpg") no-repeat;
        background-size: cover;
        background-position-x: 70%;
    }
    .item_imgbox{
    padding: 65px 63px 60px;
    }
    .item_btm{
        //background: #F2F3F5;
        padding: 0 0 60px 0;
        .item_tit{
            font-size: 48px;
            font-weight: 800;
            color: #303443;
            line-height: 58px;
            text-align: center;

        }
        .item_txt{
            font-size: 26px;
            font-weight: 500;
            color: #5E606B;
            line-height: 46px;;
            padding: 39px 35px 97px;
        }
        .item_btn{
            display: flex;
            justify-content: center;
            span{
                width: 228px;
                height: 80px;
                background: #FABA4A;
                border-radius: 10px;
                color: #fff;
                font-size: 30px;
                font-weight: bold;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
.item_wrap1{
    padding: 80px 40px 50px;
    &_tit{
        font-size: 34px;
        font-weight: bold;
        color: #303443;
        text-align: left;
        padding: 0 0 20px 0;
    }
    &_txt{
        font-size: 26px;
        font-weight: 500;
        color: #7E818F;
        line-height: 46px;
        text-align: left;
    }
    &:nth-child(even){
        background: #fff;
    }
    &:nth-child(odd){
        background: #F8F9FB;
    }
}
.ptp50{
    padding-top: 50px;
}
.tab_wrap{
    display: flex;
    justify-content: center;
    height: 140px;
    background: #F5F8FF;
    .tab_main{
        display: flex;
        align-items: center;
        span{
            font-size: 36px;
            font-weight: 500;
            color: #7E818F;
            display: flex;
            height: 100%;
            align-items: center;
            position relative;
            &:first-child{
                margin: 0 143px 0 0;
            }
            img{
                width 40px;
                margin: 0 8px 0 0;
            }
            &.active{
                &:after{
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    background: #303443;
                    height: 4px;
                    content: "";
                }

            }
        }
    }
}
</style>
