<template>
  <div class="contact-wrap">
    <div class="banner-wrap">
      <img
        src="../../public/images/common/logo.png"
        alt=""
      >
    </div>
    <div class="section-item">
      <h3 class="section-item__title">案件云微信小程序版</h3>
      <span class="section-item__light">沟通协作两不误</span>
      <div v-show="isWx">
        <wx-open-launch-weapp id="launch-btn" username="gh_0e94cc427778" path="/pages/login-guide/index.html">
          <script type="text/wxtag-template">
            <style>.btn{width: 250px;height: 32px;background: #F8C272;border: none;color: #fff;font-size: 15px;font-weight: bold;border-radius: 5px;}</style>
            <button class="btn">点击进入案件云小程序</button>
          </script>
        </wx-open-launch-weapp>
      </div>
      <div v-show="!isWx">
        <p class="section-item__text">打开微信，在【发现】点击【微信小程序】，“搜索小程</p>
        <p class="section-item__text">序”输入框中搜索「案件云」，点击卡片进入;</p>
        <img
          class="section-item__img"
          src="../../public/images/contact/section_01.png"
          alt="section_01.png"
        >
      </div>
    </div>
    <div class="section-item">
      <h3 class="section-item__title">电脑访问案件云网页版</h3>
      <span class="section-item__light">体验更全面快捷的功能</span>
      <p class="section-item__text">复制链接，用电脑通过浏览器访问案件云</p>
      <div class="copy-box">
        <span class="copy-text">{{copyText}}</span>
        <span
          class="copy-btn"
          v-clipboard:copy="copyText"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >复制链接</span>
      </div>
      <img
        class="section-item__img"
        src="../../public/images/contact/section_02.png"
        alt="section_02.png"
      >
    </div>
    <div class="section-item">
      <h3 class="section-item__title">案件、项目、客户</h3>
      <h3 class="section-item__title">尽在“掌握”</h3>
      <p class="section-item__text">更多帮助可以通过客服获取</p>
      <ul class="tencent-list">
        <li class="item">
          <img
            class="item-img"
            src="../../public/images/qa/qq.jpg"
            alt=""
          >
          <p class="item-label">客服QQ</p>
          <p
            class="item-content"
            v-clipboard:copy="copyQQ"
            v-clipboard:success="onCopyQQ"
          >{{copyQQ}}</p>
        </li>
        <li class="item">
          <img
            class="item-img"
            src="../../public/images/qa/wx.png"
            alt=""
          >
          <p class="item-label">客服微信</p>
          <p
            class="item-content"
            v-clipboard:copy="copyWX"
            v-clipboard:success="onCopyWX"
          >{{copyWX}}</p>
        </li>
      </ul>
    </div>
    <Footer/>
  </div>
</template>

<script>
import { Footer } from '@/components'
import wx from 'weixin-js-sdk';
export default {
  name: 'home',
  components: {Footer},
  data () {
    return {
      copyText: 'https://oa.law086.com',
      copyQQ: '2904108050',
      copyWX: 'law086',
      isWx: true
    }
  },
  mounted () {
    this.isWeiXin();
    if (this.isWx) {
      this.getWxData()
    }
  },
  methods: {
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        this.isWx = true
      } else {
        this.isWx = false
      }
    },
    // 复制成功
    onCopy () {
      const toast = this.$createToast({
        time: 1000,
        txt: `已复制到剪贴板`,
        type: 'correct'
      })
      toast.show()
    },
    onCopyQQ () {
      const toast = this.$createToast({
        time: 1000,
        txt: `已复制到剪贴板`,
        type: 'correct'
      })
      toast.show()
    },
    onCopyWX () {
      const toast = this.$createToast({
        time: 1000,
        txt: `已复制到剪贴板`,
        type: 'correct'
      })
      toast.show()
    },
    // 复制失败
    onError () {
      const toast = this.$createToast({
        time: 1000,
        txt: `失败`,
        type: 'error'
      })
      toast.show()
    },
    getWxData() {
      let params = {
        url: window.location.href,
        hideToast: true
      }
      this.$fetch.api_article.getH5ToWxminiParams(params).then(({data}) => {
        wx.config({
          appId: data.appId,
          timestamp: data.timestamp,
          nonceStr: data.noncestr,
          signature: data.signature,
          jsApiList: ['openLocation','getLocation','openCard'],
          openTagList: ['wx-open-launch-weapp']
        });
        var btn = document.getElementById('launch-btn');
        btn.addEventListener('launch', function (e) {
          console.log('success',e.detail);
        });
        btn.addEventListener('error', function (e) {
          console.log('fail', e.detail);
        });
      }).catch((data) => {
          this.$createToast({
              txt: data.msg,
              type: 'error'
          }).show()
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
@require 'assets/stylus/color-varaibles';

.contact-wrap {
  text-align: center;

  .banner-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 128px;
    background-color: $color-bold-black;

    img {
      width: 170px;
      height: 42px;
    }
  }

  .section-item {
    text-align: center;
    padding-top: 100px;
    background-color: $color-light-grey-s;

    &__title {
      font-size: 36px;
      color: $color-bold-black;
      margin-bottom: 30px;
      font-weight: bold;
    }

    &__light {
      display: block;
      font-size: 24px;
      color: $color-light-black;
      margin-bottom: 60px;
    }

    &__text {
      font-size: 26px;
      color: $color-primary-black;
      margin-bottom: 20px;
    }

    &__img {
      width: 100%;
    }
  }

  .copy-box {
    display: flex;
    justify-content: center;
    margin: 50px 0;

    .copy-text {
      display: inline-block;
      text-align: left;
      box-sizing: border-box;
      width: 350px;
      height: 65px;
      line-height: 65px;
      font-size: 26px;
      padding-left: 20px;
      color: $color-light-black;
      border-left: 2px solid #E1E1E1;
      border-top: 2px solid #E1E1E1;
      border-bottom: 2px solid #E1E1E1;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      background-color: $color-white;
    }

    .copy-btn {
      display: inline-block;
      text-align: center;
      width: 150px;
      height: 65px;
      line-height: 65px;
      font-size: 26px;
      font-weight: 500;
      color: $color-light-black;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      background-color: #E1E1E1;
    }
  }

  .tencent-list {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
    margin-top: 60px;

    .item {
      display: inline-block;
      text-align: center;
      width: 250px;
      height: 290px;
      padding: 40px 0;
      margin-right: 60px;
      border: 1px solid #D7D7D7;

      &-label {
        font-size: 26px;
        color: #888;
        margin: 20px 0;
      }

      &-content {
        font-size: 30px;
        font-weight: 500;
        color: $color-primary-black;
      }
    }
  }
}
</style>
