<template>
    <div id="app">
        <div class="main">
            <Header />
            <transition name="fade" mode="out-in">
                <router-view></router-view>
            </transition>
            <!-- <Footer /> -->
        </div>
    </div>
</template>
<script>
import { Header,Footer } from '@/components'
    export default {
        components: {
            Header,
            Footer,
        },
        data () {
            return {
                selectedLabelSlots: 'Like'
            }
        },
        methods: {}

    }
</script>

<style lang="stylus">
    @import "assets/stylus/main.styl"
    #app {
        position relative;
        overflow auto;
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }


</style>
