<template>
  <transition name="bgFade">
    <div class="my-dialog" v-if="visible">
      <div class="dialog-wrap">
        <div class="dialog-part">
          <div class="dialog-content">{{msg}}</div>
          <div class="dialog-footer">
            <div class="footer-btn" @click="close">确定</div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'MyDialog',
  props: ['tipData'],
  data () {
    return {
      visible: false,
      msg: ''
    }

  },
  watch: {
    tipData(val) {
      this.visible = val.visible;
      this.msg = val.msg;
    }
  },
  methods: {
    close() {
      this.visible = false
    }
  }

}
</script>

<style lang="stylus">
@require 'assets/stylus/color-varaibles';
.bgFade-leave-active {
    animation-duration: 300ms;
}
.bgFade-leave-to{
    animation-name: bgFadeOut;
    .dialog_content {
        animation-duration: 300ms;
        animation-name: contentZoomOut;
    }
}
@keyframes bgFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes bgFadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes contentZoomIn {
    0% {
        transform: scale(1.1);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes contentZoomOut {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(0.9);
        opacity: 0;
    }
}
.my-dialog{
  .dialog-wrap{
    position: fixed;
    z-index: 99999;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0,0,0,.6);
    animation-duration: 300ms;
    animation-name: bgFadeIn;
    .dialog-part{
      position: fixed;
      display: table;
      z-index: 5000;
      width: 80%;
      max-width: 600px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      background-color: #fff;
      text-align: center;
      border-radius: 6px;
      overflow: hidden;
      animation-duration: 300ms;
      animation-name: contentZoomIn;
      .dialog-content{
        color: #999;
        font-size: 30px;
        line-height: 40px;
        padding: 40px 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 80px;
        border-bottom: 1px solid #e1e4ee;
      }
      .dialog-footer{
        display: flex;
        align-items: center;
        .footer-btn{
          flex: 1;
          height: 90px;
          line-height: 90px;
          color: #ecb86c;
          font-size: 30px;
        }
      }
    }
  }
}
</style>
