<template>
<div class="help">
    <div class="help-banner">
        <div class="big-txt">帮助中心</div>
        <div class="small-txt">Help center</div>
    </div>
    <div class="help-title">使用教程</div>
    <div class="help-item-wrap">
        <div class="help-item" v-for="(item, index) in items" :key="index" @click="jumpUse(item.id, 1)">
            <svg class="iconcolor help-item-icon">
                <use :xlink:href="item.iconSrc"></use>
            </svg>
            <div class="help-item-txt">{{item.txt}}</div>
        </div>
    </div>
    <div class="help-title">常见问题</div>
    <div class="help-item-wrap pd-bottom-60">
        <div class="help-item" v-for="(item2, index2) in items2" :key="index2" @click="jumpUse(item2.id, 2)">
            <svg class="iconcolor help-item-icon">
                <use :xlink:href="item2.iconSrc"></use>
            </svg>
            <div class="help-item-txt">{{item2.txt}}</div>
        </div>
    </div>
    <Footer/>
</div>
</template>

<script>
import { Footer } from '@/components'
export default {
    name: 'help',
    components: {
        Footer
    },
    data () {
        return {
            items: [{
                txt: '免费体验',
                id: '0',
                iconSrc: '#icon-mianfeitiyan'
            }, {
                txt: '案件管理',
                id: '1',
                iconSrc: '#icon-anjianguanli'
            }, {
                txt: '项目管理',
                id: '2',
                iconSrc: '#icon-xiangmuguanli'
            }, {
                txt: '客户管理',
                id: '3',
                iconSrc: '#icon-kehuguanli'
            }, {
                txt: '日程管理',
                id: '4',
                iconSrc: '#icon-richengguanli'
            }, {
                txt: '团队管理',
                id: '5',
                iconSrc: '#icon-tuanduiguanli'
            }, {
                txt: '律所管理',
                id: '6',
                iconSrc: '#icon-lvsuoguanli'
            }, {
                txt: '数据统计',
                id: '7',
                iconSrc: '#icon-shujutongji1'
            }, {
                txt: '工具/合同模板',
                id: '8',
                iconSrc: '#icon-gongju1'
            }],
            items2: [{
                txt: '账号/版本问题',
                id: '1',
                iconSrc: '#icon-zhanghao'
            }, {
                txt: '常见操作问题',
                id: '2',
                iconSrc: '#icon-changjianwenti'
            }, {
                txt: '小程序使用问题',
                id: '3',
                iconSrc: '#icon-xiaochengxu'
            }, {
                txt: '数据安全问题',
                id: '4',
                iconSrc: '#icon-shujuanquan'
            }, {
                txt: '购买/续费问题',
                id: '5',
                iconSrc: '#icon-goumai'
            }, {
                txt: '关于案件云',
                id: '6',
                iconSrc: '#icon-yun'
            }]
        }
    },
    mounted () {

    },
    methods: {
        jumpUse(id, type) {
            console.log('xsss');
            // return false;
            this.$router.push({
                name: 'HelpList',
                query: {
                    id: id,
                    type: type
                }
            })
        }
    }
}
</script>
