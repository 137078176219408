<template>
    <div class="report-result">
        <div class="top-back">
        </div>
        <div class="report-card">
            <div class="title">{{baseData.case_name}}</div>
            <div class="info">
              <span class="date">委案：{{baseData.pr_time | changeTime}}</span>
              <span class="reason">
                <span class="txt">案由：</span>
                <span class="txt2">{{baseData.anyou}}</span>
              </span>
            </div>
            <div class="divide">
              <div class="left"></div>
              <div class="dot"></div>
              <div class="right"></div>
            </div>
            <div class="tab-wrap">
              <div class="wrap-item" @click="tabChange(0, '案件进展')" v-if="process.visible == 1">
                <img src="../../public/images/reportResult/icon0.png" alt="">
                <p :class="tabFlag == 0 ? 'active' : ''">案件进展</p>
              </div>
              <div class="wrap-item" @click="tabChange(1, '所需协助')" v-if="needhelp.visible == 1">
                <img src="../../public/images/reportResult/icon1.png" alt="">
                <p :class="tabFlag == 1 ? 'active' : ''">所需协助</p>
              </div>
              <div class="wrap-item" @click="tabChange(2, '办案策略')" v-if="strategy.visible == 1">
                <img src="../../public/images/reportResult/icon2.png" alt="">
                <p :class="tabFlag == 2 ? 'active' : ''">办案策略</p>
              </div>
              <div class="wrap-item" @click="tabChange(3, '财产线索')" v-if="property.visible == 1">
                <img src="../../public/images/reportResult/icon3.png" alt="">
                <p :class="tabFlag == 3 ? 'active' : ''">财产线索</p>
              </div>
              <div class="wrap-item" @click="tabChange(4, '执行情况')" v-if="execution.visible == 1">
                <img src="../../public/images/reportResult/icon4.png" alt="">
                <p :class="tabFlag == 4 ? 'active' : ''">执行情况</p>
              </div>
              <div class="wrap-item" @click="tabChange(5, '办理情况')" v-if="records.visible == 1">
                <img src="../../public/images/reportResult/icon5.png" alt="">
                <p :class="tabFlag == 5 ? 'active' : ''">办理情况</p>
              </div>
              <div class="wrap-item" @click="tabChange(6, '律师简介')" v-if="lawyer_des.visible == 1">
                <img src="../../public/images/reportResult/icon6.png" alt="">
                <p :class="tabFlag == 6 ? 'active' : ''">律师简介</p>
              </div>
            </div>
          </div>
          <div class="report-part-title">
            <span>{{titleName}}</span>
            <img src="../../public/images/reportResult/title-line.png" alt="">
          </div>
          <div class="content-part" v-if="tabFlag == 0 && process.visible == 1">
            <div class="process-card" v-for="(item, index) in process.items" :key="index">
              <div class="card-title">
                <span class="name">{{item.name}}</span>
                <span class="date">{{item.date}}</span>
              </div>
              <div class="card-content" v-if="item.mark">
                <span>备注：</span>
                <span class="txt">{{item.mark}}</span>
              </div>
            </div>
          </div>
          <div class="content-part" v-if="tabFlag == 1 && needhelp.visible == 1">
            <div class="process-card help-card">
              <div class="card-content" style="margin-top: 0">
                <span class="txt">
                  {{needhelp.content}}
                </span>
              </div>
            </div>
          </div>
          <div class="content-part" v-if="tabFlag == 2 && strategy.visible == 1">
            <div v-for="(item, index) in strategy.items" :key="index" v-if="item.visible">
              <div class="small-title">策略{{item.index | numberChinese}}</div>
              <div class="strategy-card">
                <div class="strategy-title">【问题描述】</div>
                <div class="strategy-content">{{item.difficulty}}</div>
                <div class="line" style="margin-left: 0.266667rem;margin-right: 0.266667rem;"></div>
                <div class="strategy-title">【解决方案】</div>
                <div class="strategy-content">{{item.solution}}</div>
                <div class="line" style="margin-left: 0.266667rem;margin-right: 0.266667rem;"></div>
                <div class="strategy-title">【处理结果】</div>
                <div class="strategy-content">{{item.result}}</div>
              </div>
            </div>
          </div>
          <div class="content-part" v-if="tabFlag == 3 && property.visible == 1">
            <div class="clue-card" v-for="(item, index) in property.items" :key="index" v-if="item.visible">
              <div class="clue-top">
                <div class="top-left">线索{{item.index | numberChinese}}</div>
                <div class="top-right">
                  <div class="month">{{item.cf_date | changeMonth}}</div>
                  <div class="year">{{item.cf_date | changeYear}}</div>
                </div>
              </div>
              <div class="clue-content">
                <div class="item">
                  <span>财产保全：</span>
                  <span>{{item.p_type_text}}</span>
                </div>
                <div class="item">
                  <span>保全金额：</span>
                  <span>{{item.bq_amount}}元</span>
                </div>
              </div>
            </div>
          </div>
          <div class="content-part" v-if="tabFlag == 4 && execution.visible == 1">
            <div class="process-card" v-for="(item, index) in execution.items" :key="index" v-if="item.visible">
              <img class="status-img" src="../../public/images/reportResult/already.png" alt="" v-if="item.status == '执行中'">
              <img class="status-img" src="../../public/images/reportResult/not.png" alt="" v-if="item.status == '未执行'">
              <img class="status-img" src="../../public/images/reportResult/close.png" alt="" v-if="item.status == '终止执行'">
              <img class="status-img" src="../../public/images/reportResult/finish.png" alt="" v-if="item.status == '执行终结'">
              <div class="card-title">
                <span class="name" style="position: relative;z-index: 99999">{{item.type_text}}</span>
              </div>
              <div class="card-content">
                <span class="txt">履行情况：</span>
                <span>{{item.remark}}</span>
              </div>
            </div>
          </div>
          <div class="content-part" v-if="tabFlag == 5 && records.visible == 1">
            <div class="records-wrap" v-for="(item, index) in records.items" :key="index" v-if="item.visible">
              <div class="records-date" v-if="monthIndex.indexOf(item.index) > -1&&item.htime">{{item.htime.substring(0,4)}}-{{item.htime.substring(5,7)}}</div>
              <div class="records-card">
                <div class="top">
                  <div class="title text-ellipsis">【{{item.title}}】</div>
                  <div class="time">{{item.htime.substring(5)}}</div>
                </div>
                <div class="content">{{item.content}}</div>
                <div class="bottom">
                  <div class="item">
                    <i class="iconfont icon-ren"></i>
                    <span>{{item.host}}</span>
                  </div>
                  <div class="item">
                    <i class="iconfont icon-shijian1"></i>
                    <span>{{item.time_cost}}分钟</span>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="content-part" v-if="tabFlag == 6 && lawyer_des.visible == 1">
            <div class="lawyer-card">
              <div class="top">
                <div class="name">{{lawyer_des.data.name}}</div>
                <div class="type">主办律师</div>
              </div>
              <div class="bottom">
                <div class="info-item">
                  <i class="iconfont icon-dianhua1"></i>
                  <a :href="mobile" style="padding-left: 0.2rem;">{{lawyer_des.data.mobile}}</a>
                </div>
                <div class="info-item">
                  <i class="iconfont icon-youxiang1"></i>
                  <span>{{lawyer_des.data.email}}</span>
                </div>
                <div class="info-item">
                  <i class="iconfont icon-gongsi1"></i>
                  <span style="padding-left: 0.25rem;">{{lawyer_des.data.lawfirm}}</span>
                </div>
                <div class="info-item info-item2">
                  <i class="iconfont icon-gerenjianjie"></i>
                  <span style="padding-left: 0.25rem;">律师简介：</span>
                </div>
                <div class="info-item">
                  <i class="iconfont icon-gerenjianjie" style="visibility: hidden;"></i>
                  <span style="padding-left: 0.25rem;">{{lawyer_des.data.description}}</span>
                </div>
              </div>
            </div>
          </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'ReportResult',
  components: {
  },
  data () {
    return {
      tabFlag: 0,
      titleName: '案件进展',
      baseData: {},
      process: {},
      needhelp: {},
      strategy: {},
      property: {},
      execution: {},
      records: {},
      lawyer_des: {},
      monthIndex: [],
      monthDate: [],
      mobile: ''
    }
  },
  created () {
  },
  filters: {
    changeMonth(val) {
      if (val) {
        return val.substring(5)
      } else {
        return ''
      }
    },
    changeYear(val) {
      if (val) {
        return val.substring(0, 4)
      } else {
        return ''
      }
    }
  },
  mounted () {
    this.getResult(this.$route.query.code)
  },
  beforeDestroy () {
    Object.assign(this.$data, this.$options.data())
  },
  methods: {
    tabChange(val, name) {
      this.tabFlag = val,
      this.titleName = name
    },
    getResult(code) {
      const toast = this.$createToast({
        time: 0,
        txt: `Loading...`,
      })
      toast.show()
      let data = {
        code: code
      }
      axios.get(`http://m.law086.com/api/report`, {params: data}).then((res) => {
        this.baseData = res.data.data
        this.process = JSON.parse(res.data.data.process)
        if (this.process.visible == 1) {
          this.tabFlag = 0
          this.titleName = '案件进展'
        }
        this.needhelp = JSON.parse(res.data.data.needhelp)
        if (this.process.visible == 0 && this.needhelp.visible == 1) {
          this.tabFlag = 1
          this.titleName = '所需协助'
        }
        this.strategy = JSON.parse(res.data.data.strategy)
        if (this.process.visible == 0 && this.needhelp.visible == 0 && this.strategy.visible == 1) {
          this.tabFlag = 2
          this.titleName = '办案策略'
        }
        this.property = JSON.parse(res.data.data.property)
        if (this.process.visible == 0 && this.needhelp.visible == 0 && this.strategy.visible == 0 && this.property.visible == 1) {
          this.tabFlag = 3
          this.titleName = '财产线索'
        }
        this.execution = JSON.parse(res.data.data.execution)
        if (this.process.visible == 0 && this.needhelp.visible == 0 && this.strategy.visible == 0 && this.property.visible == 0 && this.execution.visible == 1) {
          this.tabFlag = 4
          this.titleName = '执行情况'
        }
        this.records = JSON.parse(res.data.data.records)
        console.log( this.records )
        if (this.process.visible == 0 && this.needhelp.visible == 0 && this.strategy.visible == 0 && this.property.visible == 0 && this.execution.visible == 0 && this.records.visible == 1) {
          this.tabFlag = 5
          this.titleName = '办理情况'
        }
        this.lawyer_des = JSON.parse(res.data.data.lawyer_des)
        if (this.process.visible == 0 && this.needhelp.visible == 0 && this.strategy.visible == 0 && this.property.visible == 0 && this.execution.visible == 0 && this.records.visible == 0 && this.lawyer_des.visible == 1) {
          this.tabFlag = 6
          this.titleName = '律师简介'
        }
        this.mobile = 'tel://' + this.lawyer_des.data.mobile
        this.monthIndex = []
        this.monthDate = []
        this.records.items.forEach((item, index) => {
          if (item.visible && this.monthDate.indexOf(item.htime.substring(0, 7)) < 0) {
            this.monthIndex.push(index + 1)
            this.monthDate.push(item.htime.substring(0, 7))
          }
        })
        console.log(this.monthIndex,this.monthDate)
        toast.hide()
      }).then(() => {
        toast.hide()
      })
    }
  }
}
</script>