<template>
  <div class="report-guide" :style="backHeight">
    <div class="report-wrap">
      <div class="logo-wrap">
        <img src="../../public/images/caseReport/new-logo.png" alt="">
        <p>法律人首选的案件管理云平台</p>
      </div>
      <div class="rectangle">
        <p>案件查阅密码</p>
        <input type="text" class="code-input" placeholder="请输入对接案号" v-model="caseCode" @blur="inputBlur" />
        <div class="code-btn" @click="jumpDetail">确认查看</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'cube-ui'
export default {
  name: 'home',
  components: {
    Toast
  },
  data() {
    return {
      backHeight: '',
      caseCode: '',
      tipData: {}
    }
  },
  created() {
  },
  mounted() {
    this.backHeight = 'height: ' + document.body.clientHeight + 'px'
  },
  beforeDestroy() {
    Object.assign(this.$data, this.$options.data())
  },
  methods: {
    showToastTime(val) {
      const toast = this.$createToast({
        time: 1000,
        txt: val,
        type: 'warn'
      })
      toast.show()
    },
    jumpDetail() {
      if (!this.caseCode) {
        this.showToastTime('对接案号不能为空')
        return false
      }
      let params = {
        code: this.caseCode
      }
      localStorage.setItem('fromGuide', '1');
      this.$fetch.api_article.getSignal(params).then(({ code }) => {
        if (code === 0) {
          this.$router.push({ name: 'ReportResult', query: { code: this.caseCode } })
        }
      }).catch(() => {
      })
    },
    inputBlur() {
      window.scrollTo(0, 0)
    }
  }
}
</script>