<template>
   <div style="background: #fff;">
        <div class="item_wrap step1">
            <div class="item_imgbox">
                <img :src="item_img1" width="100%" />
            </div>
            <div class="item_btm">
                <div class="item_tit">
                多人实时协作<br/>激发团队效能
                </div>
                <div class="item_txt">规范团队办案标准，提高团队的工作效率和执行力，实现“1+1+1>3”的协作效应。
                </div>
                <div class="item_btn">
                <span @click="jumpGuide">立即下载</span>
                </div>
            </div>
        </div>
        <div class="tab_wrap">
            <div class="tab_main">
                <span @click="checkFu(index)" v-for="(item,index) in tab_list" :class="{active:activeindex == index}"  :key="index">
                    <em><img :src="item.img" /></em>{{item.name}}
                </span>
            </div>
        </div>
        <div class="item_wrap1" v-for="(item,index) in wrap_data" :key="index">
            <div class="item_wrap1_tit">{{item.tit}}</div>
            <p class="item_wrap1_txt" v-html="item.txt"></p>
            <div>
                <img :src="item.img" width="100%" />
            </div>
        </div>
        <div class="ptp50">
            <nowdownload />
        </div>
   </div>
</template>

<script>
import nowdownload from '@/components/nowdownload/index'
function requireImg(str) {
    return require("pbimages/coop/" + str);
}
export default {
    components:{
        nowdownload
    },
    data(){
        return{
            item_img1:requireImg("step1.png"),
            tab_list:[
                {name:'团队协作',img:requireImg("tab1.png"),},
                {name:'团队管理',img:requireImg("tab2.png"),}
            ],
            wrap_data:[],
            coop_data:[
                {tit:'协作办案',
                    txt:"将案件进行分配，进展自动通知到相关人，做到及时共享案件状态和工作进度，从而解决主、协办律师对同一案件/项目实时情况无法及时同步了解的窘境。",
                    img:requireImg('step2.png'),
                },
                {tit:'协同编辑',
                    txt:"同一个文档内，多人同时在线编辑，可实时看到成员文档修改和评论动态。无需反复传递文档；分工合作，及时修正，全面提升多人协同办公效率。",
                    img:requireImg('step3.png'),
                },
                {tit:'团队模板',
                    txt:"创建团队阶段模板和文档模板，成员可直接调用，协助团队规范成员的案件/项目办理流程，同时也能给办案新手提供一定程度的指引。",
                    img:requireImg('step4.png'),
                }, 
                {tit:'共享日程',
                    txt:"支持团队日程共享，在团队日程模式下，所有成员的日程记录一目了然，团队的每一项工作事项都有记录可查，便于快速复盘整理。",
                    img:requireImg('step5.png'),
                },
                {tit:'日程协作',
                    txt:"团队任一成员可根据日程任务的需要发起一个待办，添加同事为协办来完成任务，该条日程会同步展示在协办人员的日程界面。",
                    img:requireImg('step6.png'),
                },
                {tit:'评论互动',
                    txt:"成员可在任一待办事项下添加评论并@相关人员，系统及时通知团队成员查看。评论内容会完整保存并同步到案件记录页面。",
                    img:requireImg('step7.png'),
                },  
            ],
            admin_data:[
                {tit:'团队工时统计',
                    txt:"统计功能从多维度分析团队整体业务的存量与增量现状，方便管理者及时了解各成员工作进度,并及时调整工作计划。",
                    img:requireImg('step8.png'),
                },
                {tit:'团队动态追踪',
                    txt:"团队工作动态信息实时推送,工作进展随时随地掌控，支持人员、类型和区间等进一步的筛选，协助管理者高效团队管理，提升团队工作效率。",
                    img:requireImg('step9.png'),
                },
                {tit:'团队名片展示与分发',
                    txt:"团队名片包含基本信息、服务简介、团队案例、团队成员四大模块，采取“PC网页创建、编辑，微信小程序展示、分发”的操作模式，可对外展示团队竞争力与成员风采。",
                    img:requireImg('step10.png'),
                }, 
                {tit:'团队公告发布',
                    txt:"内部的规章制度、新闻简报、公告事项等通过公告形式能够在团队内部成员之间得到泛的传播，组织内部的发展动态及时获悉。",
                    img:requireImg('step11.png'),
                },
                {tit:'团队权限设置',
                    txt:"推行三级权限管理模式，团队管理员基于人员角色赋予团队成员不同的数据查看权限，通过精细化管理保障数据安全性。",
                    img:requireImg('step12.png'),
                }, 
            ],
            activeindex:0,
        }
    },
    mounted(){
        this.wrap_data = this.coop_data;
    },
    methods:{
        jumpGuide () {
            this.$router.push({ name: 'downApp' })
        },
        checkFu(index){
            this.activeindex = index;
            this.activeindex == 0 ? this.wrap_data = this.coop_data : this.wrap_data = this.admin_data;
        }
    }
    
}
</script>

<style lang="stylus" scoped>
    .item_wrap{
        &.step1{
            background: linear-gradient(180deg, #F2F8FE 0%, #EAF1FF 100%);;
        }
        .item_imgbox{
        padding: 65px 65px 60px;
        
        }
        .item_btm{
            //background: #F2F3F5;
            padding: 0 0 60px 0;
            .item_tit{
                font-size: 48px;
                font-weight: 800;
                color: #303443;
                line-height: 58px;
                text-align: center;
                
            }
            .item_txt{
                font-size: 26px;
                font-weight: 500;
                color: #5E606B;
                line-height: 46px;;
                padding: 39px 35px 97px;
            }
            .item_btn{
                display: flex;
                justify-content: center;
                span{
                    width: 228px;
                    height: 80px;
                    background: #FABA4A;
                    border-radius: 10px;
                    color: #fff;
                    font-size: 30px;
                    font-weight: bold;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .item_wrap1{
        padding: 80px 40px 50px;
        &_tit{
            font-size: 34px;
            font-weight: bold;
            color: #303443;
            text-align: left;
            padding: 0 0 20px 0;
        }
        &_txt{
            font-size: 26px;
            font-weight: 500;
            color: #7E818F;
            line-height: 46px;
            text-align: left;
        }
        &:nth-child(odd){
            background: #fff;
        }
        &:nth-child(even){
            background: #F8F9FB;
        }
    }
    .ptp50{
        padding-top: 50px;
    }
    .tab_wrap{
        display: flex;
        justify-content: center;
        height: 140px;
        background: #F5F8FF;
        .tab_main{
            display: flex;
            align-items: center;
            span{
                font-size: 36px;
                font-weight: 500;
                color: #7E818F;
                display: flex;
                height: 100%;
                align-items: center;
                position relative;
                &:first-child{
                    margin: 0 143px 0 0;
                }
                img{
                    width 40px;
                    margin: 0 8px 0 0;
                }
                &.active{
                    color: #303443;
                    font-weight: bold;
                    &:after{
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        background: #303443;
                        height: 4px;
                        content: "";
                        left: 0;
                    }
                    
                }
            }
        }
    }
</style>