<template>
    <div class="home-wrap">
        <!-- <div class="banner-wrap price-banner">
            <p class="price-banner-title">案件云，律师的专属办公云</p>
            <p class="price-banner-txt">无需下载部署，注册即用，让您和团队的案件管理更轻松</p>
            <a href="http://oalaw86com.mikecrm.com/8nB6eWV"><div class="price-btn" >免费预约演示</div></a>
        </div>
        <div class="price-tab">
            <div class="tab-item" :class="tabIndex == 0 ? 'tab-active' : ''" @click="tabChange(0)">个人版</div>
            <div class="tab-item" :class="tabIndex == 1 ? 'tab-active' : ''" @click="tabChange(1)">企业版</div>
        </div>
        <div class="price-content" v-if="tabIndex == 0">
            <div class="price-content-item">
                <div class="price-content-top price-content-top3">
                    <p class="price-content-top-title co-333">个人高阶版</p>
                    <p class="price-content-top-txt co-333">适合法律人士个人使用</p>
                </div>
                <div class="price-content-money">
                    <p class="price-content-money-num"><span>￥</span>360<span>/人/年</span></p>
                    <div class="price-content-money-btn price-content-money-btn3" @click="openPop">立即使用</div>
                </div>
                <div class="price-content-feature">
                    <div class="title">
                        <img src="../../public/images/price/icon-left.png" alt="" class="left-icon">
                        <span>产品功能</span>
                        <img src="../../public/images/price/icon-left.png" alt="" class="right-icon">
                    </div>
                    <div class="feature-item">
                        <span>个人基础版全部功能</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>案件管理【增加】</span>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>自定义案件阶段模板</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>导出案件列表</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>共享案件</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>案件报告</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>案件财务</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>案件文档</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item pd-bottom-40">
                        <div class="left-dot"></div>
                        <span>上传文书模板</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>项目管理【增加】</span>
                    </div>
                    <div class="feature-second-item ">
                        <div class="left-dot"></div>
                        <span>自定义项目阶段模板</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item ">
                        <div class="left-dot"></div>
                        <span>导出项目列表</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item ">
                        <div class="left-dot"></div>
                        <span>共享项目</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item pd-bottom-40">
                        <div class="left-dot"></div>
                        <span>项目财务</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>客户管理【增加】</span>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>导出客户列表</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>共享客户</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item pd-bottom-40">
                        <div class="left-dot"></div>
                        <span>客户财务</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>知识库</span>
                    </div>
                    <div class="feature-second-item pd-bottom-40">
                        <div class="left-dot"></div>
                        <span>个人知识库</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>数据统计</span>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>案件统计</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>项目统计</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>客户统计</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item pd-bottom-40">
                        <div class="left-dot"></div>
                        <span>财务统计</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>电子名片</span>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>专属名片二维码</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>导出电子名片</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item pd-bottom-40">
                        <div class="left-dot"></div>
                        <span>一键保存通讯录</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item pd-bottom-100">
                        <span>单文件上传限【100M】</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="price-content" style="margin-bottom: 80px;" v-if="tabIndex == 0">
            <div class="price-content-item">
                <div class="price-content-top price-content-top4">
                    <p class="price-content-top-title co-333">基础版</p>
                    <p class="price-content-top-txt co-333">适合法律人士个人使用</p>
                </div>
                <div class="price-content-money">
                    <p class="price-content-money-num">免费</p>
                    <div class="price-content-money-btn price-content-money-btn3" @click="jumpGuide">立即使用</div>
                </div>
                <div class="price-content-feature">
                    <div class="title">
                        <img src="../../public/images/price/icon-left.png" alt="" class="left-icon">
                        <span>产品功能</span>
                        <img src="../../public/images/price/icon-left.png" alt="" class="right-icon">
                    </div>
                    <div class="feature-item">
                        <span>案件管理</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>项目管理</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>客户管理</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>日程管理</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>待办清单</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>工具</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>微信小程序</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item pd-bottom-100">
                        <span>单文件上传限【10M】</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="price-content" v-if="tabIndex == 1">
            <div class="price-content-item">
                <div class="price-content-top">
                    <p class="price-content-top-title">团队版</p>
                    <p class="price-content-top-txt">适合个人、律所团队、法务团队使用</p>
                </div>
                <div class="price-content-money">
                    <p class="price-content-money-num"><span>￥</span>480<span>/人/年</span></p>
                    <div class="price-content-money-btn" @click="openPop">立即使用</div>
                </div>
                <div class="price-content-feature">
                    <div class="title">
                        <img src="../../public/images/price/icon-left.png" alt="" class="left-icon">
                        <span>产品功能</span>
                        <img src="../../public/images/price/icon-left.png" alt="" class="right-icon">
                    </div>
                    <div class="feature-item">
                        <span>个人高阶版全部功能</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>团队管理</span>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>团队公告</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>团队成员管理</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>团队名片</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item pd-bottom-40">
                        <div class="left-dot"></div>
                        <span>离职交接</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>案件管理【增加】</span>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>团队案件</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>案件协办</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>案件阶段、记录模板共享</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item pd-bottom-40">
                        <div class="left-dot"></div>
                        <span>案件文档目录共享</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>项目管理【增加】</span>
                    </div>
                    <div class="feature-second-item pd-bottom-40">
                        <div class="left-dot"></div>
                        <span>团队项目</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item pd-bottom-40">
                        <div class="left-dot"></div>
                        <span>项目协办</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item pd-bottom-40">
                        <div class="left-dot"></div>
                        <span>项目文档目录共享</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>客户管理【增加】</span>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>团队客户</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item pd-bottom-40">
                        <div class="left-dot"></div>
                        <span>客户文档目录共享</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>日程管理【增加】</span>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>日程协办</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item pd-bottom-40">
                        <div class="left-dot"></div>
                        <span>团队日程</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>数据统计【增加】</span>
                    </div>
                    <div class="feature-second-item pd-bottom-40">
                        <div class="left-dot"></div>
                        <span>团队数据统计</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>知识库</span>
                    </div>
                    <div class="feature-second-item pd-bottom-40">
                        <div class="left-dot"></div>
                        <span>团队知识库</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item pd-bottom-100">
                        <span>单文件上传限【100M】</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="price-content" style="margin-bottom: 80px;" v-if="tabIndex == 1">
            <div class="price-content-item">
                <div class="price-content-top price-content-top2">
                    <p class="price-content-top-title">律所版</p>
                    <p class="price-content-top-txt">律师/企业适用</p>
                </div>
                <div class="price-content-money">
                    <p class="price-content-money-num"><span>￥</span>720<span>/人/年</span></p>
                    <div class="price-content-money-btn price-content-money-btn2" @click="openPop">立即使用</div>
                </div>
                <div class="price-content-feature">
                    <div class="title">
                        <img src="../../public/images/price/icon-left.png" alt="" class="left-icon">
                        <span>产品功能</span>
                        <img src="../../public/images/price/icon-left.png" alt="" class="right-icon">
                    </div>
                    <div class="feature-item">
                        <span>团队版全部功能</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>案件管理【增加】</span>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>立案申请</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>案件冲突检索</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>律所存量案件批量导入</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item pd-bottom-40">
                        <div class="left-dot"></div>
                        <span>律所案件归档</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>项目管理【增加】</span>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>立项申请</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>项目冲突检索</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>律所存量项目批量导入</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item pd-bottom-40">
                        <div class="left-dot"></div>
                        <span>律所项目归档</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>律所管理</span>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>律师管理</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>部门管理</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>团队管理</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>律所名片</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>律所案号管理</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>审批流程管理</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>电子签印章管理</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item pd-bottom-40">
                        <div class="left-dot"></div>
                        <span>LOGO自定义</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>律所事务</span>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>律所公告</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>立案/立项申请</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>利益冲突检索</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>盖章申请/审批</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>电子签章申请/审批</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item pd-bottom-40">
                        <div class="left-dot"></div>
                        <span>审批催办</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>财务管理【增加】</span>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>发票申请/审批</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>报销申请/审批</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>付款申请/审批</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>收款记录</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>收支流水</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item pd-bottom-40">
                        <div class="left-dot"></div>
                        <span>财务概览</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>律所文件</span>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>申请/审批中的附件管理</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item">
                        <div class="left-dot"></div>
                        <span>申请/审批中的附件管理</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-second-item pd-bottom-40">
                        <div class="left-dot"></div>
                        <span>电子签章文件管理</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item">
                        <span>知识库</span>
                    </div>
                    <div class="feature-second-item pd-bottom-40">
                        <div class="left-dot"></div>
                        <span>律所知识库</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                    <div class="feature-item pd-bottom-100">
                        <span>单文件上传大小不限</span>
                        <i class="iconfont icon-duihao-"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="price-pop" v-show="showPop" @touchmove="popMove">
            <div class="pop-content">
                <div class="pop-close" @click="showPop = false"></div>
                <div class="pop-qr">
                    <p style="padding-top: 54px;">扫一扫加客服微信了解付费事宜</p>
                    <p>添加时请备注“购买咨询”</p>
                </div>
            </div>
        </div> -->
        <div class="item_wrap_main">
            <!-- <div class="item_imgbox">
            <img :src="item_img1" width="100%" />
            </div> -->
            <div class="item_wrap step1">
                <div class="item_btm">
                    <div class="item_tit">
                        案件云，律师的专属办公云
                    </div>
                    <div class="item_txt">无需下载部署，注册即用，让您和团队的案件管理更轻松
                    </div>
                    <div class="item_btn">
                        <span @click="jumpGuide">预约演示</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="price-section">
            <div class="section-box">
                <div class="section-box__center">
                    <div class="item">
                        <div class="lt">
                            <h3>基础版</h3>
                            <p>适合法律人士个人使用</p>
                        </div>
                        <div class="center-text">
                            <strong>免费</strong>
                        </div>
                    </div>
                    <a class="center-btn" href="https://oa.law086.com/register" target="_blank">立即体验</a>
                </div>
                <div class="section-box__bottom">
                    <div class="bottom-title">
                        <span>产品功能</span>
                    </div>
                    <ul class="bottom-list">
                        <li class="item clearfix">
                            <span>案件管理</span>

                        </li>
                        <li class="item clearfix">
                            <span>项目管理</span>

                        </li>
                        <li class="item clearfix">
                            <span>客户管理</span>

                        </li>
                        <li class="item clearfix">
                            <span>日程管理</span>

                        </li>
                        <li class="item clearfix">
                            <span>待办清单</span>

                        </li>
                        <li class="item clearfix">
                            <span>工具</span>

                        </li>
                        <li class="item clearfix">
                            <span>微信小程序</span>

                        </li>
                        <li class="item clearfix">
                            <span>单文件上传限<strong>【10M】</strong></span>

                        </li>
                    </ul>
                </div>

            </div>
            <div class="section-box">
                <div class="section-box__center">
                    <div class="item">
                        <div class="lt">
                            <h3>个人高阶版</h3>
                            <p>适合法律人士个人使用</p>
                        </div>
                        <div class="center-text">
                            ¥
                            <strong>360</strong>
                            /人/年
                        </div>
                    </div>
                    <!-- <div class="old-price">原价<span class="old-num">￥360</span></div> -->
                    <a @click="jumpGuide1" class="center-btn center-btn1" target="_blank">免费试用</a>
                    <!-- <span class="center-btn center-btn1 byn_btn">立即使用</span> -->
                </div>
                <div class="section-box__bottom">
                    <div class="bottom-title">
                        <span>产品功能</span>
                    </div>
                    <ul class="bottom-list">
                        <li class="item clearfix item-title">
                            <span style="font-size: 15px;color: #FF9601;">个人基础版全部功能</span>

                        </li>
                        <li class="item clearfix">
                            <span>案件管理<strong>【增加】</strong></span>
                        </li>
                        <li class="second-item clearfix pt50">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                自定义添加案件字段
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                自定义案件阶段模板
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                导出案件列表
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                共享案件
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                案件报告
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                案件财务
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                案件文档
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                上传文书模板
                            </span>
                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                案件字段提醒
                            </span>
                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                时间线
                            </span>
                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                图片OCR、转Word/Excel
                            </span>
                        </li>
                        <li class="item clearfix">
                            <span>项目管理<strong>【增加】</strong></span>
                        </li>
                        <li class="second-item clearfix pt50">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                自定义项目阶段模板
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                自定义添加项目字段
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                导出项目列表
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                共享项目
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                项目财务
                            </span>

                        </li>
                        <li class="item clearfix">
                            <span>客户管理<strong>【增加】</strong></span>
                        </li>
                        <li class="second-item clearfix pt50">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                自定义添加客户字段
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                导出客户列表
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                共享客户
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                客户财务
                            </span>

                        </li>
                        <li class="item clearfix">
                            <span>财务管理</span>
                        </li>
                        <li class="second-item clearfix pt50">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                收款记录
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                支出记录
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                收支流水
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                财务概览
                            </span>

                        </li>
                        <li class="item clearfix">
                            <span>知识库</span>
                        </li>
                        <li class="second-item clearfix pt50">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                个人知识库
                            </span>

                        </li>
                        <li class="item clearfix">
                            <span>数据统计</span>
                        </li>
                        <li class="second-item clearfix pt50">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                案件统计
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                项目统计
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                客户统计
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                财务统计
                            </span>

                        </li>
                        <li class="item clearfix">
                            <span>电子名片</span>
                        </li>
                        <li class="second-item clearfix pt50">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                专属名片二维码
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                导出电子名片
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                一键保存通讯录
                            </span>

                        </li>
                        <li class="item clearfix">
                            <span>单文件上传限<strong>【100M】</strong></span>

                        </li>
                    </ul>
                </div>
            </div>
            <div class="section-box">
                <div class="section-box__center">
                    <div class="item">
                        <div class="lt">
                            <h3>团队版</h3>
                            <p>律师 / 法务团队适用</p>
                        </div>
                        <div class="center-text">
                            ¥
                            <strong>480</strong>
                            /人/年
                        </div>
                    </div>
                    <!-- <div class="old-price">原价<span class="old-num">￥480</span></div> -->
                    <!-- <span class="center-btn center-btn2 byn_btn">立即使用</span> -->
                    <a @click="jumpGuide1" class="center-btn center-btn2" target="_blank">免费试用</a>
                </div>
                <div class="section-box__bottom">
                    <div class="bottom-title">
                        <span>产品功能</span>
                    </div>
                    <ul class="bottom-list">
                        <li class="item clearfix  item-title">
                            <span style="color: #FF9601;font-size: 15px;">个人高阶版全部功能</span>

                        </li>
                        <li class="item clearfix">
                            <span>团队管理</span>
                        </li>
                        <li class="second-item clearfix pt50">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                团队公告
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                团队成员管理
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                团队名片
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                离职交接
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                团队动态
                            </span>

                        </li>
                        <li class="item clearfix">
                            <span>案件管理<strong>【增加】</strong></span>
                        </li>
                        <li class="second-item clearfix pt50">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                团队案件
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                案件协办
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                案件阶段、记录模板共享
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                案件文档目录共享
                            </span>

                        </li>
                        <li class="item clearfix">
                            <span>项目管理<strong>【增加】</strong></span>
                        </li>
                        <li class="second-item clearfix pt50">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                团队项目
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                项目协办
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                项目阶段、记录模板共享
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                项目文档目录共享
                            </span>

                        </li>
                        <li class="item clearfix">
                            <span>客户管理<strong>【增加】</strong></span>
                        </li>
                        <li class="second-item clearfix pt50">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                团队客户
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                客户文档目录共享
                            </span>

                        </li>
                        <li class="item clearfix">
                            <span>日程管理<strong>【增加】</strong></span>
                        </li>
                        <li class="second-item clearfix pt50">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                日程协办
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                团队日程
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                日程评论
                            </span>

                        </li>
                        <li class="item clearfix">
                            <span>数据统计<strong>【增加】</strong></span>
                        </li>
                        <li class="second-item clearfix pt50">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                团队数据统计
                            </span>

                        </li>
                        <li class="item clearfix">
                            <span>单文件上传限<strong>【100M】</strong></span>

                        </li>
                    </ul>
                </div>
            </div>
            <div class="section-box" style="margin-right: 0;">
                <div class="section-box__center">
                    <div class="item">
                        <div class="lt">
                            <h3>律所版</h3>
                            <p>律所 / 企业适用</p>
                        </div>
                        <div class="center-text">
                            ¥
                            <strong>720</strong>
                            /人/年
                        </div>
                    </div>
                    <!-- <div class="old-price">原价<span class="old-num">￥720</span></div> -->
                    <!-- <span class="center-btn center-btn3 byn_btn">立即使用</span> -->
                    <a @click="jumpGuide1" class="center-btn center-btn3" target="_blank">免费试用</a>
                </div>
                <div class="section-box__bottom">
                    <div class="bottom-title">
                        <span>产品功能</span>
                    </div>
                    <ul class="bottom-list">
                        <li class="item clearfix  item-title">
                            <span style="color: #FF9601;font-size: 15px;">团队版全部功能</span>

                        </li>
                        <li class="item clearfix">
                            <span>案件管理<strong>【增加】</strong></span>
                        </li>
                        <li class="second-item clearfix pt50">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                立案申请
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                案件冲突检索
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                律所存量案件批量导入
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                律所案件归档
                            </span>

                        </li>
                        <li class="item clearfix">
                            <span>项目管理<strong>【增加】</strong></span>
                        </li>
                        <li class="second-item clearfix pt50">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                立项申请
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                项目冲突检索
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                律所存量项目批量导入
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                律所项目归档
                            </span>

                        </li>
                        <li class="item clearfix">
                            <span>律所管理</span>
                        </li>
                        <li class="second-item clearfix pt50">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                律师管理
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                部门管理
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                团队管理
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                律所名片
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                律所案号管理
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                审批流程管理
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                电子签印章管理
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                LOGO自定义
                            </span>

                        </li>
                        <li class="item clearfix">
                            <span>律所事务</span>
                        </li>
                        <li class="second-item clearfix pt50">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                律所公告
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                立案/立项申请
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                利益冲突检索
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                盖章申请/审批
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                电子签章申请/审批
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                审批催办
                            </span>

                        </li>
                        <li class="item clearfix">
                            <span>财务管理<strong>【增加】</strong></span>
                        </li>
                        <li class="second-item clearfix pt50">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                发票申请/审批
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                报销申请/审批
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                付款申请/审批
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                收款记录
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                收支流水
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                财务概览
                            </span>

                        </li>
                        <li class="item clearfix">
                            <span>律所文件</span>
                        </li>
                        <li class="second-item clearfix pt50">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                申请/审批中的附件管理
                            </span>

                        </li>
                        <li class="second-item clearfix">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                电子签章文件管理
                            </span>

                        </li>
                        <li class="item clearfix">
                            <span>知识库</span>
                        </li>
                        <li class="second-item clearfix pt50">
                            <span class="dot-wrap">
                                <span class="dot">•</span>
                                律所知识库
                            </span>

                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <nowdownload />
        <Footer />
    </div>
</template>

<script>
import nowdownload from '@/components/nowdownload/index'
import { Footer } from '@/components'
export default {
    name: 'home',
    components: {
        nowdownload,
        Footer
    },
    data() {
        return {
            showPop: false,
            tabIndex: 0
        }
    },
    created() {
    },
    methods: {
        jumpGuide() {
            //this.$router.push({name:'Contact'});
            window.location = "http://oalaw86com.mikecrm.com/8nB6eWV";
        },
        jumpGuide1() {
            this.$router.push({ name: 'Contact' });
        },
        openPop() {
            this.showPop = true;
        },
        popMove(e) {
            //阻止弹窗在手机上穿透移动
            e.preventDefault();
        },
        tabChange(index) {
            this.tabIndex = index
        }
    }
}
</script>
<style scoped lang="stylus">
    .item_wrap_main{
            background: linear-gradient(90deg, #F3F5FA 0%, #ECF0F7 100%);
        }
    .item_wrap{
        
        &.step1{
            background-image: url("../../public/images/price/new/banner.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position-x: 30%;
            /* min-height: 1000px; */
            display: flex;
            align-items: flex-end;
            width: 100%;
        }
        .item_imgbox{
        padding: 65px 65px 60px;
        
        }
        .item_btm{
            //background: #F2F3F5;
            padding: 128px 0 128px 0;
            width: 100%;
            .item_tit{
                font-size: 48px;
                font-weight: 800;
                color: #303443;
                line-height: 58px;
                text-align: center;
                
            }
            .item_txt{
                font-size: 26px;
                font-weight: 500;
                color: #5E606B;
                line-height: 46px;;
                padding: 39px 35px 70px;
                text-align: center;
            }
            .item_btn{
                display: flex;
                justify-content: center;
                span{
                    width: 228px;
                    height: 80px;
                    background: #FABA4A;
                    border-radius: 10px;
                    color: #fff;
                    font-size: 30px;
                    font-weight: bold;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
.price-section {
  text-align: center;
  padding: 100px 96px 50px;
}
.price-section .section-box:nth-of-type(1) .section-box__center {
  background: linear-gradient(131deg, #FFFFFF 0%, #FBFBFB 100%) !important;
}
.price-section .section-box:nth-of-type(2) .section-box__center {
  background: linear-gradient(131.99deg, #F9FAFE 0%, #F0F2F7 100%) !important;
}
.price-section .section-box:nth-of-type(3) .section-box__center {
  background: linear-gradient(135deg, #FFD883 0%, #FF9921 100%) !important;
}
.price-section .section-box:nth-of-type(3) .section-box__center h3,
.price-section .section-box:nth-of-type(3) .section-box__center .center-text,
.price-section .section-box:nth-of-type(3) .section-box__center strong,
.price-section .section-box:nth-of-type(3) .section-box__center p {
  color: #fff!important;
}
.price-section .section-box:nth-of-type(4) .section-box__center {
  background: linear-gradient(132.27deg, #616161 0%, #2E2E2E 100%) !important;
}
.price-section .section-box:nth-of-type(4) .section-box__center h3,
.price-section .section-box:nth-of-type(4) .section-box__center .center-text,
.price-section .section-box:nth-of-type(4) .section-box__center strong,
.price-section .section-box:nth-of-type(4) .section-box__center p {
  color: #fff!important;
}
.price-section .section-box {
  display: inline-block;
  vertical-align: middle;
  margin-right: 30px;
  box-shadow: 0px 4px 10px 0px rgba(102, 102, 102, 0.2);
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 60px;
}
.price-section .section-box__top {
  width: 278px;
  height: 103px;
  padding-top: 26px;
  padding-bottom: 30px;
  color: #505050;
  background: -webkit-linear-gradient(#FFFFFF, #FBFBFB);
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(#FFFFFF, #FBFBFB);
  /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(#FFFFFF, #FBFBFB);
  /* Firefox 3.6 - 15 */
  background: linear-gradient(#FFFFFF, #FBFBFB);
  /* 标准的语法 */
}
.price-section .section-box__top h3 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 10px;
}
.price-section .section-box__top p {
  font-size: 14px;
}
.price-section .section-box__center {
  position: relative;
  //height: 278px;
  padding: 30px;
  background-color: #fff;
  border-bottom: 1px solid  #EDECEB;
}
.price-section .section-box__center .old-price {
  width: 100%;
  color: #999;
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 150px;
}
.price-section .section-box__center h3 {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #303443;
}
.price-section .section-box__center .center-text {
  font-size: 14px;
  color: #303443;
}
.price-section .section-box__center .center-text strong {
  font-size: 60px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #303443;
}
.price-section .section-box__center .center-btn {
    height: 70px;
    border: 1px solid #FABA4A;
    border-radius: 10px;
    width: 100%;
    display: flex;
    font-size: 30px;
    font-weight: bold;
    color: #FABA4A;
    align-items: center;
    justify-content: center;
    margin: 70px 0 0;
}
.price-section{
    .section-box__center{
        padding: 60px 40px;
        .item{
            display: flex;
            .lt{
                flex: 1;
                text-align: left;
                h3{
                    font-size: 36px;
                    font-weight: 500;
                    color: #303443;
                    padding: 0 0 20px 0;
                }
                p{
                    font-size: 24px;
                    font-weight: 500;
                    color: #5D5F6A;
                }
            }
        }
    }
}

.price-section .section-box__center .center-btn:hover {
  background: #FF9601;
  border: 1px solid #FF9601;
  box-shadow: 0px 2px 4px 0px rgba(182, 180, 180, 0.35);
  color: #fff;
}
.price-section .section-box__center .center-btn2 {
  background: #fff;
  border-color: #fff;
}
.price-section .section-box__center .center-btn3 {
  border-color: #fff;
  background: #fff;
}
.price-section .section-box__center .center-icon {
  position: absolute;
  top: 25px;
  width: 100%;
}
.price-section .section-box__center .center-icon i {
  font-size: 20px;
  color: #EC5050;
}
.price-section .section-box__center .center-icon span {
  font-size: 18px;
  color: #FF7F01 ;
}
.price-section .section-box__center .center-done {
  position: absolute;
  text-decoration: line-through;
  width: 100%;
  bottom: 130px;
  color: #999;
}
.price-section .section-box__bottom {
  box-sizing: border-box;
  //height: 1410px;
  text-align: left;
  font-size: 14px;
  padding: 40px 24px 100px;
}
.price-section .section-box__bottom .bottom-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #5D5F6A;
}
.price-section .section-box__bottom .bottom-title span {
  font-size: 30px;
  padding: 0 3px;
  border-bottom: 5px solid #FABA4A ;
}
.price-section .section-box__bottom .item {
  padding-top: 50px;
  padding-left: 45px;
  position: relative;
}
.price-section .section-box__bottom .item::before {
  content: '\e75b';
  font-family: "iconfont";
  position: absolute;
  left: 0;
  font-size: 30px;
  bottom: 0;
  color: #ffc000;
}
.price-section .section-box__bottom .item.item-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #FF9600;
}
.price-section .section-box__bottom .item.item-title::before {
  content: '\e651';
  font-family: "iconfont";
  position: absolute;
  left: 0;
  font-size: 11px;
  bottom: 2px;
  color: #ffc000;
}
.price-section .section-box__bottom .item span {
  float: left;
  color: #333;
  font-size: 26px;
}
.price-section .section-box__bottom .second-item {
  margin-bottom: 38px;
}
.price-section .section-box__bottom .second-item .dot-wrap {
  font-size: 26px;
  color: #5D5F6A;
  float: left;
  padding-left: 20px;
}
.price-section .section-box__bottom .second-item .dot-wrap .dot {
  color: #666;
  padding-left: 7px;
  padding-right: 3px;
  font-size: 40px;
  vertical-align: middle;
}
.price-section .section-box__bottom .second-item .iconfont {
  float: right;
  font-size: 18px;
  color: #666;
}
.dialog-box-price {
  display: none;
  /* 默认隐藏 */
  position: fixed;
  left: 0;
  top: 0;
  /*设置弹窗位置*/
  padding-top: 200px;
  padding-bottom: 300px;
  /*浮在全屏上*/
  width: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
  z-index: 999;
}
.dialog-price {
  position: relative;
  display: inline-block;
  padding: 0 20px;
  width: 830px;
  border-radius: 10px;
  background-color: #fff;
}
.dialog-price-top {
  text-align: center;
  height: 50px;
  line-height: 50px;
}
.dialog-price-top__icon {
  display: inline-block;
  float: right;
  cursor: pointer;
}
.dialog-price .price-ewm {
  width: 476px;
  height: 478px;
  background: url('../../public/images/price/new/price_ewm.png') no-repeat;
  background-size: 100% 100%;
  margin: 0 auto;
}
.dialog-price .price-ewm p {
  font-size: 18px;
  line-height: 28px;
}
.pt50{
    padding-top: 50px;
}
</style>
