<template>
   <div style="background: #fff;">
        <div class="item_wrap step1">
            <div class="item_imgbox">
                <img :src="item_img1" width="100%" />
            </div>
            <div class="item_btm">
                <div class="item_tit">
                律师/法务团队管理
                </div>
                <div class="item_txt">在线实时协同，提高团队作战能力和效率
                </div>
                <div class="item_btn">
                <span @click="jumpGuide">立即下载</span>
                </div>
            </div>
        </div>
        <div class="item_wrap1" v-for="(item,index) in wrap_data" :key="index">
            <div class="item_wrap1_tit1" v-if="index == 0">解决问题</div>
            <div>
                <img :src="item.img" :class="item.class" width="100%" />
            </div>
            <div class="item_wrap1_tit" v-html="item.tit"></div>
            <p class="item_wrap1_txt" v-html="item.txt"></p>
        </div>
        <div class="item_wrap2">
            <div class="item_wrap2_tit">
                方案优势
            </div>
            <swiper class="main_item_swiper" :options="swiperOption">   
                <swiper-slide class="main_item_slide" v-for="(item,index) in advantageData" :key="index">
                    <div class="slide_item" :style="`background:${item.bg}`">
                        <div class="slide_imgbox">
                            <img :src="item.img" />
                            <img :src="item.img1" />
                        </div>
                        <div class="main_item_titbox">
                            {{item.tit}}
                        </div>
                        <p class="txt">
                            {{item.txt}}
                        </p>
                    </div>
                </swiper-slide>
                <div class="swiper-pagination swiper-pagination0" slot="pagination"></div>
            </swiper>
        </div>
        <div class="ptp50">
            <nowdownload />
        </div>
        <Footer />
   </div>
</template>

<script>
import nowdownload from '@/components/nowdownload/index'
import { Footer} from '@/components'
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/swiper.min.css";
function requireImg(str) {
    return require("pbimages/solution/solution_child/" + str);
}
export default {
    components:{
        nowdownload,
        Footer,
        swiper,
        swiperSlide
    },
    data(){
        return{
            item_img1:requireImg("step3.png"),
            wrap_data:[
                {tit:'主、协办人员难以感知对方的工作<br/>态动，文件来回传递繁琐',
                    txt:"记录添加主协办，进展自动通知到相关人；主协办在线编辑文档，高效协同且记录留底",
                    img:requireImg('q9.png'),
                    class:'wd124'
                },
                {tit:'团队成员行事风格各异，整体服务<br/>质量难把握',
                    txt:"案件、项目阶段模板一键共享给团队，便于规范服务标准",
                    img:requireImg('q10.png'),
                    class:'wd132'
                },
                {tit:'团队客户积累越来越多，客情维护<br/>难以跟进',
                    txt:"给客户设置定期维护计划，到期自动提醒，过往交流记录方便可查",
                    img:requireImg('q11.png'),
                    class:'wd120'
                },
                {tit:'团队整体收支状况难以精确分析',
                    txt:"“财务管理”功能自动汇总业务收支，团队资金流水一目了然",
                    img:requireImg('q12.png'),
                    class:'wd145'
                },
            ],
            swiperOption: {
            　　pagination: {
                    el:'.swiper-pagination0',
                    clickable: true,
                },
            　　 slidesPerView: 1.5,
                spaceBetween: 20,
                loop: false,
            },
            advantageData:[
                {tit:'合作办案，让协作者实时知晓案件处理进展',
                    txt:"合作办案的律师分头行动，处理案件相关事项，事情办结后归档办理结果，以便协作者实时查看。重要日程安排设置协办和提醒，及时知会协作者。",
                    img:requireImg('01.png'),
                    img1:requireImg('card7.png'),
                    bg:"#F2F6FF"
                },
                {tit:'掌握成员日程，合理派发任务',
                    txt:"客户主动上门咨询，派谁接待？打开团队日程，结合团队案件、项目进展情况，根据各成员忙碌程度进行分配调度，力求资源配置最优化。",
                    img:requireImg('02.png'),
                    img1:requireImg('card8.png'),
                    bg:"#ECFDF9"
                },
                {tit:'把控财务情况，辅助服务决策',
                    txt:"这个项目投入的人力和临时出差比预计的多很多，担心会亏本怎么办？打开项目收支记 录看一眼，还好，待收金额和入账收益除了覆盖成本，盈余较丰，可以放心干下去。",
                    img:requireImg('03.png'),
                    img1:requireImg('card9.png'),
                    bg:"#FEF7E9"
                },
            ]
        }
    },
    mounted(){
    },
    methods:{
        jumpGuide () {
            this.$router.push({ name: 'downApp' })
        },
    }
    
}
</script>

<style lang="stylus" scoped>
    .item_wrap{
        &.step1{
            background: linear-gradient(90deg, #FDF9FE 0%, #F4F4FB 100%);
        }
        .item_imgbox{
            padding: 0;
            margin: 90px 0 70px 0;
        }
        .item_btm{
            //background: #F2F3F5;
            padding: 0 0 78px 0;
            .item_tit{
                font-size: 48px;
                font-weight: 800;
                color: #303443;
                line-height: 58px;
                text-align: center;
                
            }
            .item_txt{
                font-size: 26px;
                font-weight: 500;
                color: #5E606B;
                line-height: 46px;;
                padding: 39px 35px 70px;
                text-align: center;
            }
            .item_btn{
                display: flex;
                justify-content: center;
                span{
                    width: 228px;
                    height: 80px;
                    background: #FABA4A;
                    border-radius: 10px;
                    color: #fff;
                    font-size: 30px;
                    font-weight: bold;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .item_wrap1{
        padding: 67px 40px 55px;
        &_tit{
            font-size: 34px;
            font-weight: bold;
            color: #303443;
            text-align: left;
            padding: 65px 0 20px 0;
            text-align: center;
            line-height: 56px;
        }
        &_tit1{
            text-align: center;
            font-size: 48px;
            font-weight: bold;
            color: #303443;
            padding: 45px 0 65px;
        }
        &_txt{
            font-size: 26px;
            font-weight: 500;
            color: #7E818F;
            line-height: 46px;
            text-align: center;
        }
        &:nth-child(even){
            background: #fff;
        }
        &:nth-child(odd){
            background: #F8F9FB;
        }
        img{
            display: block;
            margin: auto;
            &.wd124{
                width: 124px;
            }
            &.wd132{
                width: 132px;
            }
            &.wd120{
                width: 120px;
            }
            &.wd145{
                width: 145px;
            }
        }
    }
    .item_wrap2{
        padding: 0 0 60px 35px;
        overflow: hidden;
        // .swiper-wrapper{
        //     padding-right: 1px;
        // }
        &_tit{
            font-size: 48px;
            font-weight: bold;
            color: #303443;
            text-align: center;
            padding: 100px 0 60px;
        }
        .main_item_slide{
            margin: 0 21px 0 0!important;
            /deep/ .slide_item{
                border-radius: 10px;
                padding: 58px 40px 0;
                .slide_imgbox{
                    display: flex;
                    justify-content: space-between;
                    &:first-child{
                        height: 45px;
                    }
                    &:last-child{
                        width: 38px;
                    }
                    img{
                        width: auto;
                    }
                }
                .main_item_titbox{
                    font-size: 34px;
                    font-weight: bold;
                    color: #303443;
                    line-height: 48px;
                    padding: 60px 0 16px;
                }
                .txt{
                    font-size: 26px;
                    font-weight: 500;
                    color: #7E818F;
                    line-height: 44px;
                    text-align: justify
                    min-height: 320px;
                }
            }
        }
    }
    .ptp50{
        padding-top: 50px;
    }
    
</style>