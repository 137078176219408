<template>
    <div class="loading">
        页面正在加载中，请稍等...
    </div>
</template>

<script>
    export default {
        created() {
            let path = this.$route.path
            console.log('route', this.$route.path);
            if (path != '/empty') {
                this.$route.push({
                    path: path
                })
            }
        },
    }
</script>
<style lang="stylus" scoped>
    .loading {
        text-align: center;
        margin-top: 200px;
        font-size: 22px;
    }
</style>