<template>
   <div style="background: #fff;">
        <div class="item_wrap step1">
            <div class="item_imgbox">
                <img :src="item_img1" width="100%" />
            </div>
            <div class="item_btm">
                <div class="item_tit">
                案件信息清晰记录<br/>案件进度一目了然
                </div>
                <div class="item_txt">
                    多个案件并行，如何有序推进？案件云的案件管理能记录每个案件的当前进度和流程节点，个性化的案件列表满足不同的查阅需求，便捷的批处理功能减少重复性操作，更能通过日程将案件的关键事件设定提醒，轻松hold住多个案件不再是难题。
                </div>
                <div class="item_btn">
                <span @click="jumpGuide">立即下载</span>
                </div>
            </div>
        </div>
        <div class="item_wrap1" v-for="(item,index) in wrap_data" :key="index">
            <div class="item_wrap1_tit">{{item.tit}}</div>
            <p class="item_wrap1_txt" v-html="item.txt"></p>
            <div>
                <img :src="item.img" width="100%" />
            </div>
        </div>
        <div class="ptp50">
            <nowdownload />
        </div>
        <Footer />
   </div>
</template>

<script>
import nowdownload from '@/components/nowdownload/index'
import { Footer} from '@/components'
function requireImg(str) {
    return require("pbimages/case/" + str);
}
export default {
    components:{
        nowdownload,
        Footer
    },
    data(){
        return{
            item_img1:requireImg("header.png"),
            wrap_data:[
                {tit:'自定义设置案件列表',
                    txt:"列表式和卡片式两种展示模式切换，既能全局纵览所有案件进度，也可聚焦单个案件的办理情况。允许用户根据自己的使用场景自定义表头展示项,比如添加字段、调整顺序、高级筛选、参与排序等功能。",
                    img:requireImg('step1.png'),
                },
                {tit:'便捷的案件信息录入',
                    txt:"内置丰富的法律人常用字段，创建案件信息时，可以智能联想推荐或自动匹配数据，帮您快速录入案件信息。<br/>同时支持移动端操作，随时随地管理案件信息。",
                    img:requireImg('step2.png'),
                },
                {tit:'办案记录与日程互通',
                    txt:"将案件办理中的事项通过日程管理起来，定时提醒工作安排，不必担心在繁杂事务中遗忘重要事项。<br/>在案件结案时，自动整合碎片化的记录，并生成一份工作报告给客户查看，给客户全流程的法律服务体验。",
                    img:requireImg('step3.png'),
                },
                {tit:'案件应收款与支出',
                    txt:"管理与案件相关的每一笔收款和支出，结合可视化图表，直观呈现案件的总体成本收益情况。<br/>律所版用户还能在收款中申请开票，在支出中申请费用报销。",
                    img:requireImg('step4.png'),
                },
                {tit:'案件文档及目录管理',
                    txt:"支持在线编辑与新建，同时满足多人在线协作。<br/>目录复用、拖拽上传、文件夹上传、批量下载、下载文件夹等各种文件操作一应具备。<br/>还能从模板创建，在线生成常用的起诉书、所函等文件。",
                    img:requireImg('step5.png'),
                },
                {tit:'批量案件管理',
                    txt:"支持案件的批量导入、导出和批量操作，如结案、归档、设置跟进人、修改案件字段、删除。<br/>在案件、项目和客户中还可以将关联的批量案件进行统一管理，再多的案件也能轻松管理起来。",
                    img:requireImg('step6.png'),
                },
                {tit:'协同办案与审批',
                    txt:"团队版可以设置案件的主协办，让成员共同参与办案。在办案记录中可以通过@的方式，提醒其他协办人员事项的完成情况。<br/>律所版还能围绕案件发起利益冲突检索、立案申请、用印和财务申请等流程。",
                    img:requireImg('step7.png'),
                },   
                {tit:'回收站',
                    txt:"回收站里保存了近期删除的数据，可以立即“恢复”误删数据。",
                    img:requireImg('step5.png'),
                },
            ]
        }
    },
    methods:{
        jumpGuide () {
            this.$router.push({ name: 'downApp' })
        },
    }
    
}
</script>

<style lang="stylus" scoped>
    .item_wrap{
        &.step1{
            background: url("../../public/images/case/bg.jpg") no-repeat;
            background-size: cover;
            background-position-x: 70%;
        }
        .item_imgbox{
        padding: 65px 65px 60px;
        
        }
        .item_btm{
            //background: #F2F3F5;
            padding: 0 0 60px 0;
            .item_tit{
                font-size: 48px;
                font-weight: 800;
                color: #303443;
                line-height: 58px;
                text-align: center;
                
            }
            .item_txt{
                font-size: 26px;
                font-weight: 500;
                color: #5E606B;
                line-height: 46px;;
                padding: 39px 35px 97px;
            }
            .item_btn{
                display: flex;
                justify-content: center;
                span{
                    width: 228px;
                    height: 80px;
                    background: #FABA4A;
                    border-radius: 10px;
                    color: #fff;
                    font-size: 30px;
                    font-weight: bold;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .item_wrap1{
        padding: 80px 40px 50px;
        &_tit{
            font-size: 34px;
            font-weight: bold;
            color: #303443;
            text-align: left;
            padding: 0 0 20px 0;
        }
        &_txt{
            font-size: 26px;
            font-weight: 500;
            color: #7E818F;
            line-height: 46px;
            text-align: left;
        }
        &:nth-child(odd){
            background: #F8F9FB;
        }
        &:nth-child(even){
            background: #fff;
        }
    }
    .ptp50{
        padding-top: 50px;
    }
</style>