<template>
   <div style="background: #fff;">
        <div class="item_wrap step1">
            <div class="item_imgbox">
                <img :src="item_img1" width="100%" />
            </div>
            <div class="item_btm">
                <div class="item_tit">
                线上流程管控<br/>规范律所管理
                </div>
                <div class="item_txt">规范律所案件管理流程,助力律所管理信息化,全面提升律所竞争力。
                </div>
                <div class="item_btn">
                <span @click="jumpGuide">立即下载</span>
                </div>
            </div>
        </div>
        <div class="tab_wrap">
            <div class="tab_main">
                <span @click="checkFu(index)" v-for="(item,index) in tab_list" :class="{active:activeindex == index}"  :key="index">
                    <em><img :src="item.img" /></em>{{item.name}}
                </span>
            </div>
        </div>
        <div class="item_wrap1" v-for="(item,index) in wrap_data" :key="index">
            <div class="item_wrap1_tit">{{item.tit}}</div>
            <p class="item_wrap1_txt" v-html="item.txt"></p>
            <div>
                <img :src="item.img" width="100%" />
            </div>
        </div>
        <div class="ptp50">
            <nowdownload />
        </div>
        <Footer />
   </div>
</template>

<script>
import nowdownload from '@/components/nowdownload/index'
import { Footer} from '@/components'
function requireImg(str) {
    return require("pbimages/firm/" + str);
}
export default {
    components:{
        nowdownload,
        Footer
    },
    data(){
        return{
            item_img1:requireImg("step1.png"),
            tab_list:[
                {name:'律所流程',img:requireImg("tab1.png"),},
                {name:'律所管理',img:requireImg("tab2.png"),}
            ],
            wrap_data:[],
            coop_data:[
                {tit:'立案冲突检索',
                    txt:"在发起立案申请时，系统根据录入的双方当事人信息自动到律所库中进行关键词检索。<br>如该案件与律所既有客户存在利益冲突，系统会直接提示。在立案流程管理中，审核专员也可以对案件进行冲突检索，避免内部利益冲突的发生。",
                    img:requireImg('step2.png'),
                },
                {tit:'立案立项审批',
                    txt:"律师通过系统发起立案后，各审批节点人员会通过查看案件基本信息，审查利益冲突,并从形式上核验立案的基本要素是否齐备。",
                    img:requireImg('step3.png'),
                },
                {tit:'自动生成律所案号',
                    txt:"在案件立案审批通过后，根据案件类型，系统会自动、有序的生成律所案号，方便律师安排代理函的编号。",
                    img:requireImg('step4.png'),
                }, 
                {tit:'线上申请与审批',
                    txt:"律所常规的行政、财务事宜都可以通过在线审批完成，不受地域和时间的限制，随时随地在线进行确认，无需任何纸质文本的流转。",
                    img:requireImg('step5.png'),
                },
                {tit:'自定义审批流程',
                    txt:"支持预先设置流程的审批走向以及处理人员（审批节点），支持在任何节点进行加签或减签。",
                    img:requireImg('step6.png'),
                }, 
            ],
            admin_data:[
                {tit:'自定义添加律所logo',
                    txt:"支持添加律所logo，打造律所专属协作空间，让律所成员也更有归属感。",
                    img:requireImg('step7.png'),
                },
                {tit:'发布律所公告',
                    txt:"内部的规章制度、新闻简报、公告事项等通过公告形式能够在律所内部成员之间得到泛的传播，组织内部的发展动态及时获悉。",
                    img:requireImg('step8.png'),
                },
                {tit:'律所名片展示与分发',
                    txt:"律所名片包含基本信息、服务简介、律所案例、律所成员四大模块，采取“PC网页创建、编辑，微信小程序展示、分发”的操作模式，方便律所对外展示律所竞争力与成员风采。",
                    img:requireImg('step9.png'),
                },
                {tit:'律所权限设置',
                    txt:"推行三级权限管理模式，律所管理员对律所成员的数据查看权限进行合理设置。基于角色赋予赋予律师、职能人员、财务人员不同访问权限，通过精细化管理保障数据安全性。",
                    img:requireImg('step10.png'),
                }, 
                // {tit:'团队公告发布',
                //     txt:"内部的规章制度、新闻简报、公告事项等通过公告形式能够在团队内部成员之间得到泛的传播，组织内部的发展动态及时获悉。",
                //     img:requireImg('step11.png'),
                // },
                // {tit:'团队权限设置',
                //     txt:"推行三级权限管理模式，团队管理员基于人员角色赋予团队成员不同的数据查看权限，通过精细化管理保障数据安全性。",
                //     img:requireImg('step12.png'),
                // }, 
            ],
            activeindex:0,
        }
    },
    mounted(){
        this.wrap_data = this.coop_data;
    },
    methods:{
        jumpGuide () {
            this.$router.push({ name: 'downApp' })
        },
        checkFu(index){
            this.activeindex = index;
            this.activeindex == 0 ? this.wrap_data = this.coop_data : this.wrap_data = this.admin_data;
        }
    }
    
}
</script>

<style lang="stylus" scoped>
    .item_wrap{
        &.step1{
            background: linear-gradient(180deg, #F2F8FE 0%, #EAF1FF 100%);
        }
        .item_imgbox{
        padding: 65px 63px 60px;
        }
        .item_btm{
            //background: #F2F3F5;
            padding: 0 0 60px 0;
            .item_tit{
                font-size: 48px;
                font-weight: 800;
                color: #303443;
                line-height: 58px;
                text-align: center;
                
            }
            .item_txt{
                font-size: 26px;
                font-weight: 500;
                color: #5E606B;
                line-height: 46px;;
                padding: 39px 35px 97px;
            }
            .item_btn{
                display: flex;
                justify-content: center;
                span{
                    width: 228px;
                    height: 80px;
                    background: #FABA4A;
                    border-radius: 10px;
                    color: #fff;
                    font-size: 30px;
                    font-weight: bold;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .item_wrap1{
        padding: 80px 40px 50px;
        &_tit{
            font-size: 34px;
            font-weight: bold;
            color: #303443;
            text-align: left;
            padding: 0 0 20px 0;
        }
        &_txt{
            font-size: 26px;
            font-weight: 500;
            color: #7E818F;
            line-height: 46px;
            text-align: left;
        }
        &:nth-child(odd){
            background: #fff;
        }
        &:nth-child(even){
            background: #F8F9FB;
        }
    }
    .ptp50{
        padding-top: 50px;
    }
    .tab_wrap{
        display: flex;
        justify-content: center;
        height: 140px;
        background: #F5F8FF;
        .tab_main{
            display: flex;
            align-items: center;
            span{
                font-size: 36px;
                font-weight: 500;
                color: #7E818F;
                display: flex;
                height: 100%;
                align-items: center;
                position relative;
                &:first-child{
                    margin: 0 143px 0 0;
                }
                img{
                    width 40px;
                    margin: 0 8px 0 0;
                }
                &.active{
                    color: #303443;
                    font-weight: bold;
                    &:after{
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        background: #303443;
                        height: 4px;
                        content: "";
                        left: 0;
                    }
                    
                }
            }
        }
    }
</style>