import Vue from 'vue';
import myDialogClass from './dialog.vue'
const myDialog = Vue.extend(myDialogClass);
let instance;
export let dialog = {
    open (msg) {
        instance = new myDialog({
            el: document.createElement('div')
        });
        document.body.appendChild(instance.$el);
        instance.msg = msg;
        instance.visible = true;
    }
}

export default {
    install (Vue) {
        Vue.prototype.$dialog = dialog;
    }
}