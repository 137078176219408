<template>
    <div class="help-list">
        <div class="list-wrap" v-if="type == 1">
            <div class="list-item" v-for="(item, index) in listItems" :key="index" @click="jumpDetail(item.a_num)">
                <span class="list-title">{{item.title}}</span>
                <i class="iconfont icon-youjiantou"></i>
            </div>
        </div>
        <div class="list-wrap" v-if="type == 2">
            <div class="list-item-wrap" v-for="(item, index) in faqItems" :key="index" @click="changeShowMore(item,index)">
                <div class="list-item">
                    <span class="list-title">{{item.question}}</span>
                    <i class="iconfont icon-youjiantou" :class="item.showMore ? 'rotate-icon' : ''"></i>
                </div>
                <div class="list-content-wrap" :style="{height: item.nowHeight}">
                    <div class="list-content" ref="collapse" :data-id="item.id">{{item.answer}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'helpList',
    components: {
    },
    data () {
        return {
            id: '',
            type: 1,
            listItems: [],
            faqItems: []
        }
    },
    mounted() {
        this.id = Number(this.$route.query.id);
        this.type = this.$route.query.type;
        if (this.type == 1) {
            this.getList(this.id)
        } else {
            this.getfaq()
        }
    },
    methods: {
        getList(id){
            this.$fetch.api_article.list("").then(({data}) => {
                if (id == 0) {
                    this.listItems = data['免费体验']
                } else if (id == 1) {
                    this.listItems = data['案件管理']
                } else if (id == 2) {
                    this.listItems = data['项目管理']
                } else if (id == 3) {
                    this.listItems = data['客户管理']
                } else if (id == 4) {
                    this.listItems = data['日程管理']
                } else if (id == 5) {
                    this.listItems = data['团队管理']
                } else if (id == 6) {
                    this.listItems = data['律所管理']
                } else if (id == 7) {
                    this.listItems = data['数据统计']
                } else if (id == 8) {
                    this.listItems = data['工具/合同模板']
                }
            }).catch((data) => {
                this.$createToast({
                    txt: data.msg,
                    type: 'error'
                }).show()
            })
        },
        jumpDetail(code) {
            this.$router.push({
                name: 'HelpDetail',
                query: {
                    code: code
                }
            })
        },
        getfaq() {
            this.$fetch.api_article.faq().then(({data}) => {
                this.faqItems = data.filter((item) => {
                    item.showMore = false
                    item.nowHeight = '0px'
                    if (this.id == item.type) {
                        return item
                    }
                })
            }).catch((data) => {
                this.$createToast({
                    txt: data.msg,
                    type: 'error'
                }).show()
            })
        },
        changeShowMore(item, index) {
            item.showMore = !item.showMore
            if (item.showMore) {
                item.nowHeight = this.$refs.collapse[index].offsetHeight + 'px'
            } else {
                item.nowHeight = '0px'
            }
        }
    }
}
</script>
