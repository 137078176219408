<template>
   <div style="background: #fff;">
        <div class="item_wrap step1">
            <div class="item_imgbox">
                <img :src="item_img1" width="100%" />
            </div>
            <div class="item_btm">
                <div class="item_tit">
                律所管理
                </div>
                <div class="item_txt">规范案件办理流程，高效进行所务管理
                </div>
                <div class="item_btn">
                <span @click="jumpGuide">立即下载</span>
                </div>
            </div>
        </div>
        <div class="item_wrap1" v-for="(item,index) in wrap_data" :key="index">
            <div class="item_wrap1_tit1" v-if="index == 0">解决问题</div>
            <div>
                <img :src="item.img" :class="item.class" width="100%" />
            </div>
            <div class="item_wrap1_tit">{{item.tit}}</div>
            <p class="item_wrap1_txt" v-html="item.txt"></p>
        </div>
        <div class="item_wrap2">
            <div class="item_wrap2_tit">
                方案优势
            </div>
            <swiper class="main_item_swiper" :options="swiperOption">   
                <swiper-slide class="main_item_slide" v-for="(item,index) in advantageData" :key="index">
                    <div class="slide_item" :style="`background:${item.bg}`">
                        <div class="slide_imgbox">
                            <img :src="item.img" />
                            <img :src="item.img1" />
                        </div>
                        <div class="main_item_titbox">
                            {{item.tit}}
                        </div>
                        <p class="txt">
                            {{item.txt}}
                        </p>
                    </div>
                </swiper-slide>
                <div class="swiper-pagination swiper-pagination0" slot="pagination"></div>
            </swiper>
        </div>
        <div class="ptp50">
            <nowdownload />
        </div>
        <Footer />
   </div>
</template>

<script>
import nowdownload from '@/components/nowdownload/index'
import { Footer} from '@/components'
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/swiper.min.css";
function requireImg(str) {
    return require("pbimages/solution/solution_child/" + str);
}
export default {
    components:{
        nowdownload,
        Footer,
        swiper,
        swiperSlide
    },
    data(){
        return{
            item_img1:requireImg("step2.png"),
            wrap_data:[
                {tit:'案件办理流程难以规范化管理',
                    txt:"统一设置律所案号，规范立案审批流程，审批过程留底、可追溯",
                    img:requireImg('q5.png'),
                    class:'wd124'
                },
                {tit:'行政审批申请流程冗杂，不能及时响应',
                    txt:"设置差异化审批规则，繁简得当，在线申请和审批，自动通知催办",
                    img:requireImg('q6.png'),
                    class:'wd132'
                },
                {tit:'归档后的案件存放无序，查阅困难',
                    txt:"走过审批流程的案件、项目自动归档，支持根据审批结果分类检索和关键词搜索",
                    img:requireImg('q7.png'),
                    class:'wd120'
                },
                {tit:'律所通知下达方式传统低效，知识共享不便',
                    txt:"公告、知识分享等自主上传到律所文件夹，所有人可以及时查看取用",
                    img:requireImg('q8.png'),
                    class:'wd145'
                },
            ],
            swiperOption: {
            　　pagination: {
                    el:'.swiper-pagination0',
                    clickable: true,
                },
            　　 slidesPerView: 1.5,
                spaceBetween: 20,
                loop: false,
            },
            advantageData:[
                {tit:'案冲检索和立案审批，避免利益冲突',
                    txt:"之前律所案件未“上网”时，接案子得拿着行政给的档案记录逐一比对，有时立案了才发现与其他律师的案子冲突了。现在立案之前先在系统检索一遍，然后交由审批者再审查一次，再也不会白忙活。",
                    img:requireImg('01.png'),
                    img1:requireImg('card4.png'),
                    bg:"#F2F6FF"
                },
                {tit:'在线行政、财务审批，精简管理流程',
                    txt:"律所规定行政、财务得经过管理者审批，但是管理者很忙碌，经常不在律所，有时电话也联系不上，很着急。走线上化审批流程后，律所行政、财务审批机动性增强了很多，效率大大提升。",
                    img:requireImg('02.png'),
                    img1:requireImg('card5.png'),
                    bg:"#ECFDF9"
                },
                {tit:'在线电子签章，省去邮寄和时间成本',
                    txt:"律所业务各地开花，所里每天迎来送往的尽是快递小哥。自从开通了“电子签”，不管是外派律师要的盖章文件，还是与客户签订合同，在线一分钟就能搞定，省时又省钱。",
                    img:requireImg('03.png'),
                    img1:requireImg('card6.png'),
                    bg:"#FEF7E9"
                },
            ]
        }
    },
    mounted(){
    },
    methods:{
        jumpGuide () {
            this.$router.push({ name: 'downApp' })
        },
    }
    
}
</script>

<style lang="stylus" scoped>
    .item_wrap{
        &.step1{
            background: linear-gradient(126.87deg, #F8FEFC 0%, #DDE3FB 100%);
        }
        .item_imgbox{
            padding: 0;
            margin: 0 0 40px 0;
        }
        .item_btm{
            //background: #F2F3F5;
            padding: 0 0 78px 0;
            .item_tit{
                font-size: 48px;
                font-weight: 800;
                color: #303443;
                line-height: 58px;
                text-align: center;
                
            }
            .item_txt{
                font-size: 26px;
                font-weight: 500;
                color: #5E606B;
                line-height: 46px;;
                padding: 39px 35px 70px;
                text-align: center;
            }
            .item_btn{
                display: flex;
                justify-content: center;
                span{
                    width: 228px;
                    height: 80px;
                    background: #FABA4A;
                    border-radius: 10px;
                    color: #fff;
                    font-size: 30px;
                    font-weight: bold;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .item_wrap1{
        padding: 67px 40px 55px;
        &_tit{
            font-size: 34px;
            font-weight: bold;
            color: #303443;
            text-align: left;
            padding: 65px 0 20px 0;
            text-align: center;
        }
        &_tit1{
            text-align: center;
            font-size: 48px;
            font-weight: bold;
            color: #303443;
            padding: 45px 0 65px;
        }
        &_txt{
            font-size: 26px;
            font-weight: 500;
            color: #7E818F;
            line-height: 46px;
            text-align: center;
        }
        &:nth-child(even){
            background: #fff;
        }
        &:nth-child(odd){
            background: #F8F9FB;
        }
        img{
            display: block;
            margin: auto;
            &.wd124{
                width: 124px;
            }
            &.wd132{
                width: 132px;
            }
            &.wd120{
                width: 120px;
            }
            &.wd145{
                width: 145px;
            }
        }
    }
    .item_wrap2{
        padding: 0 0 60px 35px;
        overflow: hidden;
        // .swiper-wrapper{
        //     padding-right: 1px;
        // }
        &_tit{
            font-size: 48px;
            font-weight: bold;
            color: #303443;
            text-align: center;
            padding: 100px 0 60px;
        }
        .main_item_slide{
            margin: 0 21px 0 0!important;
            /deep/ .slide_item{
                border-radius: 10px;
                padding: 58px 40px 0;
                .slide_imgbox{
                    display: flex;
                    justify-content: space-between;
                    &:first-child{
                        height: 45px;
                    }
                    &:last-child{
                        width: 38px;
                    }
                    img{
                        width: auto;
                    }
                }
                .main_item_titbox{
                    font-size: 34px;
                    font-weight: bold;
                    color: #303443;
                    line-height: 48px;
                    padding: 60px 0 16px;
                }
                .txt{
                    font-size: 26px;
                    font-weight: 500;
                    color: #7E818F;
                    line-height: 44px;
                    text-align: justify
                    min-height: 420px;
                }
            }
        }
    }
    .ptp50{
        padding-top: 50px;
    }
    
</style>