<template>
  <div class="article-wrap" style="background: #fff;">
    <div class="item_wrap step1">
        <!-- <div class="item_imgbox">
          <img :src="item_img1" width="100%" />
        </div> -->
        <div class="item_btm">
          <div class="item_tit">
          专注于法律人的日常办公
          </div>
          <div class="item_txt">案件云面向律所律师及企业法务，WEB端和移动端全覆盖，支持团队协作办案、行政审批，可轻松满足案件全流程记录、卷宗归档、客户管理、日常待办事务分发和提醒、财务分析、数据统计等一系列日常工作需要。
          </div>
          <div class="item_btn">
          <span @click="jumpGuide">立即下载</span>
          </div>
        </div>
    </div>
    <div class="wrap_item2">
      <div class="wrap_item2_tit">关于我们</div>
      <div class="wrap_item2_txtbox">
        <p>案件云是上海律杏科技有限公司推出的法律科技产品，深耕法律从业者日常事务流程管理，为法律团队规范管理、高效协同以及探索现代化团队成长路径提供有力支撑。</p>
        <p>案件云团队核心成员均具有法律和信息科技复合背景，不仅深谙法律人的工作流程、习惯，还善于运用大数据挖掘与分析、“机器学习”、“NLP”等前沿技术打造智能化管理系统。</p>
        <p>因为专业，所以极致；因为专注，所以优秀。案件云坚持以用户需求为导向，以创新技术为动能，持续为法律人提供具有竞争力的产品和高效的服务。</p>
      </div>
      <div>
        <img :src="img" width="100%"/>
      </div>
    </div>
    <div class="wrap_item3">
      <div class="wrap_item3_tit">服务愿景</div>
      <div class="wrap_item3_txt">
        以现代科技力量协助律师、企业完成智能化升级，全面提高法律服务的效率，让更多的专业律师、法务得以从繁琐的事务性工作中获得解放
      </div>
    </div>
    <div class="wrap_item4">
      <div class="wrap_item4_tit">服务群体</div>
      <div class="wrap_item4_txt">
        案件云平台正在为2万多位用户提供专业化案件管理服务
      </div>
      <ul class="group_list">
        <li v-for="(item,index) in group_list" :key="index">
          <img :src="item.img"  />
          <div class="txt">{{item.txt}}</div>
        </li>
      </ul>
    </div>
    <nowdownload />
    <Footer/>
  </div>
</template>

<script>
import nowdownload from '@/components/nowdownload/index'
import { Footer} from '@/components'
function requireImg(str) {
    return require("pbimages/qa/" + str);
}
export default {
  name: 'home',
  components: {
    nowdownload,
    Footer
  },
  data () {
    return {
      img: requireImg('t1.png'),
      group_list:[
        {img:requireImg("per1.png"),txt:'个人律师'},
        {img:requireImg("per2.png"),txt:'律师团队'},
        {img:requireImg("per3.png"),txt:'公司法务部'},
        {img:requireImg("per4.png"),txt:'律所'},
      ],
 
    }
  },
  created () {
  },
  methods: {
     jumpGuide(){
        this.$router.push({ name: 'downApp' })
     }
  }
}
</script>
<style scoped lang="stylus">
  .item_wrap{
        &.step1{
            background: url("../../public/images/qa/bg.jpg") no-repeat;
            background-size: cover;
            background-position-x: 20%;
            min-height: 1000px;
            display: flex;
            align-items: flex-end;
            width: 100%;
        }
        .item_imgbox{
        padding: 65px 65px 60px;
        
        }
        .item_btm{
            //background: #F2F3F5;
            padding: 0 0 60px 0;
            width: 100%;
            .item_tit{
                font-size: 48px;
                font-weight: 800;
                color: #fff;
                line-height: 58px;
                text-align: center;
                
            }
            .item_txt{
                font-size: 26px;
                font-weight: 500;
                color: #fff;
                line-height: 46px;;
                padding: 39px 35px 70px;
                text-align: center;
            }
            .item_btn{
                display: flex;
                justify-content: center;
                span{
                    width: 228px;
                    height: 80px;
                    background: #FABA4A;
                    border-radius: 10px;
                    color: #fff;
                    font-size: 30px;
                    font-weight: bold;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .wrap_item2{
      padding: 100px 0;
      &_tit{
        font-size: 48px;
        font-weight: bold;
        color: #303443;
        text-align: center;
        padding: 0 0 50px 0;
      }
      &_txtbox{
        padding: 0 36px;
        p{
          font-size: 26px;
          font-weight: 500;
          color: #7E818F;
          line-height: 46px;
          text-align: center;
          padding: 0 0 40px 0;
        }
      }
      img{
        display: block;
      }
    }
    .wrap_item3{
      background: url("../../public/images/qa/bg2.jpg") no-repeat;
      background-size: cover;
      background-position-x: 50%;
      min-height: 420px;
      &_tit{
        font-size: 48px;
        font-weight: bold;
        color: #303443;
        text-align: center;
        padding: 100px 0 50px 0;
      }
      &_txt{
        padding: 0 36px;
        font-size: 26px;
        font-weight: 500;
        color: #7E818F;
        line-height: 46px;
        text-align: center;
        padding: 0 36px;
      
      }
    }
    .wrap_item4{
      padding: 0 36px 80px;
      &_tit{
        font-size: 48px;
        font-weight: bold;
        color: #303443;
        text-align: center;
        padding: 100px 0 25px 0;
      }
      &_txt{
        padding: 0 36px;
        font-size: 26px;
        font-weight: 500;
        color: #7E818F;
        line-height: 46px;
        text-align: center;
        padding: 0 36px 45px;
      
      }
      .group_list{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        >li{
          width: calc((100% - 20px) / 2);
          position: relative;
          margin: 0 0 20px;
          img{
            width: 100%;
            margin: 0 0 20px 0;
            z-index 10;
          }
          .txt{
            position: absolute;
            z-index: 11;
            width: 100%;
            height: 66px;
            background: #303443;
            border-radius: 0px 0px;
            font-size: 34px;
            font-weight: bold;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 0;
          }
        }
      }
    }
</style>