<template>
  <div class="article-wrap">
    <div class="banner-wrap article-banner">
      <div class="banner-content">
        <h2 class="banner-content__h2">AI LEGAL VISION</h2>
        <p class="banner-content__h3">AI法律视野</p>
        <span class="banner-content__tag"></span>
      </div>
    </div>
    <div
      class="article-item"
      v-for="(item, index) in articleList"
      :key="index"
      @click=jumpDetail(item)
    >
      <div class="item-text">
        <h3 class="item-text__h3">{{item.title}}</h3>
        <p class="item-text__p">{{item.description}}</p>
        <span class="item-text__date">{{item.updated_at}}</span>
      </div>
      <img
        class="item-img"
        v-if="item.cover"
        :src="img_url + item.cover"
        alt=""
      >
      <img
        class="item-img"
        v-else
        src="../../public/images/article/default.png"
        alt=""
      >
    </div>
    <div class="loading-more">
      <div
        class="loading-btn"
        @click="turnPage(currentPage)"
      >{{loadingText}}</div>
    </div>
    <nowdownload />
    <Footer/>
  </div>
</template>

<script>
// import { JumpForm } from '@/components'
import { Footer } from '@/components'
import {img_url} from '../common/config/index'
import nowdownload from '@/components/nowdownload/index'
export default {
  name: 'home',
  components: {
    // JumpForm,
    Footer,
    nowdownload
  },
  data () {
    return {
      finalPage: 0,
      loadingText: '加载更多',
      articleList: [],
      currentPage: 1,
      img_url: img_url
    }
  },
  created () {
    this.getDefaultData({ page: 1 })
  },
  beforeDestroy () {
    Object.assign(this.$data, this.$options.data())
  },
  methods: {

    turnPage (page) {
      if (page === this.finalPage) {
        this.loadingText = '加载完毕'
        return false
      }
      this.currentPage = page + 1
      this.getDefaultData({ page: this.currentPage })
    },
    jumpDetail (item) {
      this.$router.push({ name: 'Detail', query: { code: item.a_num } })
    },
    getDefaultData (params) {
      // return false
      this.$fetch.api_article.list(params)
        .then(({
          data
        }) => {
          this.finalPage = data.last_page
          this.articleList.push(...data.data)
        })
        .catch((data) => {
          this.$createToast({
            txt: data.msg,
            type: 'error'
          }).show()
        })
    }
  }
}
</script>