<template>
   <div style="background: #fff;">
        <div class="item_wrap step1">
            <div class="item_imgbox">
                <img :src="item_img1" width="100%" />
            </div>
            <div class="item_btm">
                <div class="item_tit">
                个人业务管理
                </div>
                <div class="item_txt">快捷记录，移动办公，轻松管理日常业务
                </div>
                <div class="item_btn">
                <span @click="jumpGuide">立即下载</span>
                </div>
            </div>
        </div>
        <div class="item_wrap1" v-for="(item,index) in wrap_data" :key="index">
            <div class="item_wrap1_tit1" v-if="index == 0">解决问题</div>
            <div>
                <img :src="item.img" :class="item.class" width="100%" />
            </div>
            <div class="item_wrap1_tit" v-html="item.tit"></div>
            <p class="item_wrap1_txt" v-html="item.txt"></p>
        </div>
        <div class="item_wrap2">
            <div class="item_wrap2_tit">
                方案优势
            </div>
            <swiper class="main_item_swiper" :options="swiperOption">   
                <swiper-slide class="main_item_slide" v-for="(item,index) in advantageData" :key="index">
                    <div class="slide_item" :style="`background:${item.bg}`">
                        <div class="slide_imgbox">
                            <img :src="item.img" />
                            <img :src="item.img1" />
                        </div>
                        <div class="main_item_titbox">
                            {{item.tit}}
                        </div>
                        <p class="txt">
                            {{item.txt}}
                        </p>
                    </div>
                </swiper-slide>
                <div class="swiper-pagination swiper-pagination0" slot="pagination"></div>
            </swiper>
        </div>
        <div class="ptp50">
            <nowdownload />
        </div>
        <Footer />
   </div>
</template>

<script>
import nowdownload from '@/components/nowdownload/index'
import { Footer} from '@/components'
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/swiper.min.css";
function requireImg(str) {
    return require("pbimages/solution/solution_child/" + str);
}
export default {
    components:{
        nowdownload,
        Footer,
        swiper,
        swiperSlide
    },
    data(){
        return{
            item_img1:requireImg("step1.png"),
            wrap_data:[
                {tit:'多项业务同时开展时日程容易冲突或遗漏',
                    txt:"日程面板汇总所有日程和待办记录，时间节点清晰；重要日程可以设置提醒，避免遗漏。",
                    img:requireImg('q13.png'),
                    class:'wd124'
                },
                {tit:'重要客户经常忘记定期维护',
                    txt:"为重要客户设置服务和联络计划，到期提醒，参考过往用户记录更方便打开话题",
                    img:requireImg('q14.png'),
                    class:'wd132'
                },
                {tit:'多个在办案件的阶段、进展容易混淆',
                    txt:"为每个在办案件设置当前阶段和接下来的办理安排，在案件列表即可统一查阅",
                    img:requireImg('q11.png'),
                    class:'wd120'
                },
                {tit:'业务量和服务收支统计耗时',
                    txt:"案件、项目、客户财务收支记录自动汇总到“财务管理”，实时更新，心中有数",
                    img:requireImg('q4.png'),
                    class:'wd145'
                },
            ],
            swiperOption: {
            　　pagination: {
                    el:'.swiper-pagination0',
                    clickable: true,
                },
            　　 slidesPerView: 1.5,
                spaceBetween: 20,
                loop: false,
            },
            advantageData:[
                {tit:'清晰记录，合理安排',
                    txt:"同时处理的案子很多，事情安排经常“打架”怎么办？简要记录各个案件的阶段和待办，再根据日程列表合理调整，重要的事还得设置提醒，这样事情再多也能有条不紊。",
                    img:requireImg('01.png'),
                    img1:requireImg('card1.png'),
                    bg:"#F2F6FF"
                },
                {tit:'客户交流存档，联系时有的放矢',
                    txt:"客户电话不断，微信切换不停，为了避免张冠李戴，不妨及时将各个客户的交流简单记录，形成完整的记录日志，下次向客户通知消息或催款时，都能心中有数。",
                    img:requireImg('02.png'),
                    img1:requireImg('card2.png'),
                    bg:"#ECFDF9"
                },
                {tit:'随时标注记录灵感，利用好碎片化时间',
                    txt:"庭审结束后坐在返程的车上，回顾庭上对方律师和法官的几点质疑，觉得可以从几个方面各个击破，赶紧拿起手机记录在这个案件策略里，为下次开庭或谈判作准备。",
                    img:requireImg('03.png'),
                    img1:requireImg('card3.png'),
                    bg:"#FEF7E9"
                },
            ]
        }
    },
    mounted(){
    },
    methods:{
        jumpGuide () {
            this.$router.push({ name: 'downApp' })
        },
    }
    
}
</script>

<style lang="stylus" scoped>
    .item_wrap{
        &.step1{
            background: linear-gradient(90deg, #E9FCFC 0%, #E1E9FF 100%);
        }
        .item_imgbox{
            padding: 65px 58px;
            //margin: 65px 58px;
        }
        .item_btm{
            //background: #F2F3F5;
            padding: 0 0 78px 0;
            .item_tit{
                font-size: 48px;
                font-weight: 800;
                color: #303443;
                line-height: 58px;
                text-align: center;
                
            }
            .item_txt{
                font-size: 26px;
                font-weight: 500;
                color: #5E606B;
                line-height: 46px;;
                padding: 39px 35px 70px;
                text-align: center;
            }
            .item_btn{
                display: flex;
                justify-content: center;
                span{
                    width: 228px;
                    height: 80px;
                    background: #FABA4A;
                    border-radius: 10px;
                    color: #fff;
                    font-size: 30px;
                    font-weight: bold;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .item_wrap1{
        padding: 67px 40px 55px;
        &_tit{
            font-size: 34px;
            font-weight: bold;
            color: #303443;
            text-align: left;
            padding: 65px 0 20px 0;
            text-align: center;
            line-height: 56px;
        }
        &_tit1{
            text-align: center;
            font-size: 48px;
            font-weight: bold;
            color: #303443;
            padding: 45px 0 65px;
        }
        &_txt{
            font-size: 26px;
            font-weight: 500;
            color: #7E818F;
            line-height: 46px;
            text-align: center;
        }
        &:nth-child(even){
            background: #fff;
        }
        &:nth-child(odd){
            background: #F8F9FB;
        }
        img{
            display: block;
            margin: auto;
            &.wd124{
                width: 124px;
            }
            &.wd132{
                width: 132px;
            }
            &.wd120{
                width: 120px;
            }
            &.wd145{
                width: 145px;
            }
        }
    }
    .item_wrap2{
        padding: 0 0 60px 35px;
        overflow: hidden;
        // .swiper-wrapper{
        //     padding-right: 1px;
        // }
        &_tit{
            font-size: 48px;
            font-weight: bold;
            color: #303443;
            text-align: center;
            padding: 100px 0 60px;
        }
        .main_item_slide{
            margin: 0 21px 0 0!important;
            /deep/ .slide_item{
                border-radius: 10px;
                padding: 58px 40px 0;
                .slide_imgbox{
                    display: flex;
                    justify-content: space-between;
                    &:first-child{
                        height: 45px;
                    }
                    &:last-child{
                        width: 38px;
                    }
                    img{
                        width: auto;
                    }
                }
                .main_item_titbox{
                    font-size: 34px;
                    font-weight: bold;
                    color: #303443;
                    line-height: 48px;
                    padding: 60px 0 16px;
                    min-height: 96px;
                }
                .txt{
                    font-size: 26px;
                    font-weight: 500;
                    color: #7E818F;
                    line-height: 44px;
                    text-align: justify
                    min-height: 320px;
                }
            }
        }
    }
    .ptp50{
        padding-top: 50px;
    }
    
</style>