<template>
    <div class="footer-wrap">
        <div class="footer-img">
            <img src="../../../public/images/common/ft_logo.png" alt="logo">
        </div>
        <!-- <ul class="nav-list">
      <li class="item" :class="routeName == item.routeName ? 'item-active': ''" v-for="(item, index) in navList" :key="index" @click="jumpPage(item)">
        <h3>{{item.h3}}</h3>
      </li>
      <div class="blank"></div>
    </ul> -->
        <div class="footer_main">
            <div class="item" v-for="(item,index) in navList1" :key="index">
                <div class="tit">{{item.cat}}</div>
                <div class="txt" v-for="(it,ind) in item.data" :key="ind">
                    <a v-if="it.type && it.routeName == 'MiniProgram'">
                        <span v-show="isWx">
                            <wx-open-launch-weapp id="launch-btn1" username="gh_0e94cc427778" path="/pages/login-guide/index.html">
                                <script type="text/wxtag-template">
                                    <style>.fttxt_btn{width: 80px;height: 15px;color: #a4a9bc;font-size: 24px;display:inline-block;}</style>
                                    <button class="fttxt_btn">{{it.name}}</button>
                                </script>
                            </wx-open-launch-weapp>
                        </span>
                        <span v-show="!isWx" @click="jumpFu(it)">
                            {{it.name}}
                        </span>
                    </a>
                    <a v-else-if="it.type && it.routeName == 'https://oa.law086.com'" href="https://oa.law086.com">{{it.name}}</a>
                    <span v-else @click="jumpFu(it)">{{it.name}}</span>
                </div>
            </div>
        </div>
        <div class="footer_btm">
            <div class="lt">
                <div class="tit">关于我们</div>
                <div class="txt" @click="jes_show = true">
                    公司介绍
                </div>
                <!-- <div class="txt">
          案件云公众号
        </div> -->
                <!-- <a href="https://weibo.com/u/6889507251" class="txt">
          微博
        </a>
        <a href="https://www.zhihu.com/people/yun-mei-er-an-jian-yun" class="txt">
          知乎
        </a> -->
            </div>
            <div class="rt">
                <div class="txtbox">
                    PC网址：
                    <p class="txt">www.law086.com</p>
                </div>
                <div class="txtbox">
                    客服邮箱：
                    <p class="txt">2904108050@qq.com</p>
                </div>
                <div class="txtbox">
                    代理/渠道合作：
                    <p class="txt">lx@law086.com</p>
                </div>
                <div>
                    <div class="img-list">
                        <i class="iconfont icon-qq" style="font-size: 18px;">
                            <!-- <span class="qrcode" style="padding: 10px;">
                  <img src="../images/footer/qq.jpg" alt="">
                </span>
                <span class="sanj"></span> -->
                        </i>
                        <i class="iconfont icon-weixin31" style="font-size: 18px;">
                            <!-- <span class="qrcode">
                  <img src="../images/footer/wx.jpg" alt="">
                </span>
                <span class="sanj"></span> -->
                        </i>
                        <!-- <a href="https://weibo.com/u/6889507251" target="blank">
                  <i class="iconfont icon-weibo" ></i>
              </a>
              <a href="https://www.zhihu.com/people/yun-mei-er-an-jian-yun" target="blank">
                  <i class="iconfont icon-zhihu" style="font-size: 19px;"></i>
              </a> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="copyright">
      <p>Copyright © 2021 All rights reserved</p>
      <a href="http://www.beian.miit.gov.cn/">沪ICP备10018377号-3</a>
      <router-link to='./serve'>《服务协议与隐私政策》</router-link>
    </div> -->
        <div class="jes_dialog" v-if="jes_show">
            <div class="jes_dialog_bg"></div>
            <div class="jes_dialog_wrap">
                <span class="close_ic" @click="jes_show = false;">
                    <i class="iconfont icon1 icon-guanbi"></i>
                </span>
                <div class="tit">公司介绍</div>
                <p class="txt">
                    上海律杏科技一家专注于法律科技领域的公司，我们致力于成为法律行业数字化转型领航者。旗下产品“案件云”自2017年初上线以来，不懈探索法律从业者的业务需求，在利用先进技术手段提升法律工作效率与质量方面做出了长足的研发。<br />
                    2022年，律杏科技武汉分公司暨技术研发中心在光谷正式设立，致力于汇集九省通衢的科技人才，为法律科技的发展进一步添砖加瓦。
                </p>
                <div class="txtbox">
                    <div>
                        <span>
                            <i class="iconfont icon icon-dianhua"></i>
                        </span>
                        <p>联系电话：18062548440</p>
                    </div>
                    <div>
                        <span>
                            <i class="iconfont icon icon-dizhi"></i>
                        </span>
                        <p>武汉地址：武汉东湖新技术开发区金融港四路18号普天物联网创新研发基地（一期）2栋4层01室</p>
                    </div>
                </div>
                <img src="../../../public/images/common/jes.png" />
            </div>
        </div>
    </div>
</template>

<script>
    import wx from 'weixin-js-sdk';

    export default {
        name: 'Footer',
        props: {},
        data() {
            return {
                navList1: [{
                        cat: '产品',
                        data: [
                            // { name: '案件云pc端', routeName: 'https://oa.law086.com', type: 'out' },
                            { name: '案件云小程序', routeName: '/mini-program', type: 'out' },
                            { name: 'App下载', routeName: 'downApp' },
                        ]
                    },
                    {
                        cat: '解决方案',
                        data: [
                            { name: '个人业务管理', routeName: 'solution_pre' },
                            { name: '律师/法务团队管理', routeName: 'solution_team' },
                            { name: '律所管理', routeName: 'solution_firm' },
                            { name: '企业服务方案', routeName: 'deployment' },
                        ]
                    },
                    {
                        cat: '服务',
                        data: [
                            { name: '帮助中心', routeName: 'Help' },
                            { name: '服务隐私协议', routeName: 'serve' },
                        ]
                    }
                ],
                // navList: [
                //   {
                //     h3: '首页',
                //     p: 'HOMEPAGE',
                //     routeName: 'Home',
                //   },
                //   {
                //     h3: '产品介绍',
                //     routeName: 'Product',
                //   },
                //   {
                //     h3: '我们',
                //     p: 'ABOUT US',
                //     routeName: 'Qa',
                //   },
                //   {
                //     h3: '价格',
                //     p: 'PRICE',
                //     routeName: 'Price',
                //   },
                //   {
                //     h3: '小程序介绍',
                //     p: 'MINI PROGRAM',
                //     routeName: 'MiniProgram',
                //   },
                //   {
                //     h3: '解决方案',
                //     routeName: 'Solution',
                //   },
                //   {
                //     h3: '安全介绍',
                //     routeName: 'Safe',
                //   },
                //   {
                //     h3: '律师个人网站',
                //     routeName: 'PersonalWeb',
                //   },
                //   {
                //     h3: '帮助中心',
                //     routeName: 'Help',
                //   },
                //   {
                //     h3: 'APP下载',
                //     routeName: 'downApp',
                //   }
                // ],
                copyQQ: '2904108050',
                copyWX: 'law086',
                isWx: true,
                jes_show: false,
            }
        },
        computed: {
            routeName() {
                return this.$route.name
            }
        },
        created() {
            this.isWeiXin()
        },
        mounted() {
            if (this.isWx) {
                this.getWxData()
            };
        },
        methods: {
            jumpFu(item) {
                this.$router.push({ path: item.routeName })
            },
            isWeiXin() {
                var ua = window.navigator.userAgent.toLowerCase();
                if (ua.match(/MicroMessenger/i) == 'micromessenger') {
                    this.isWx = true
                } else {
                    this.isWx = false
                }
            },
            getWxData() {
                let params = {
                    url: window.location.href,
                    hideToast: true
                }
                this.$fetch.api_article.getH5ToWxminiParams(params).then(({ data }) => {
                    wx.config({
                        appId: data.appId,
                        timestamp: data.timestamp,
                        nonceStr: data.noncestr,
                        signature: data.signature,
                        jsApiList: ['openLocation', 'getLocation', 'openCard'],
                        openTagList: ['wx-open-launch-weapp']
                    });
                    var btn = document.getElementById('launch-btn1');
                    btn.addEventListener('launch', function(e) {
                        console.log('success', e.detail);
                    });
                    btn.addEventListener('error', function(e) {
                        console.log('fail', e.detail);
                    });
                }).catch((data) => {
                    this.$createToast({
                        txt: data.msg,
                        type: 'error'
                    }).show()
                })
            }
        }

    }
</script>

<style lang="stylus">
    @require 'assets/stylus/color-varaibles';

    .jes_dialog {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 45px;
        z-index: 1001;
        box-sizing: border-box;

        .jes_dialog_bg {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            background-color: rgba(0, 0, 0, .2);
        }

        .jes_dialog_wrap {
            background-color: #fff;
            position relative;
            z-index: 1002;
            width: 100%;
            border-radius: 22px;
            padding: 80px 45px 0 55px;
            min-height: 1000px;

            .close_ic {
                position: absolute;
                top: 20px;
                right: 37px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;

                .icon {
                    color: #666666;
                    font-size: 20px;
                }
            }

            .txtbox {
                position: relative;
                z-index: 1;
            }

            .tit {
                font-size: 36px;
                font-weight: 800;
                color: #303443;
                line-height: 30px;
            }

            .txt {
                font-size: 26px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #303443;
                line-height: 42px;
                padding: 30px 0 35px;
            }

            .txtbox {
                >div {
                    display: flex;
                    align-items: flex-start;
                    margin: 0 0 40px 0;
                    position: relative;
                    z-index: 10;

                    span {
                        width: 48px;
                        height: 48px;
                        background: #FFF1DC;
                        border-radius: 50%;
                        margin: 0 14px 0 0;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-align-items: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-justify-content: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        font-size: 28px;
                        color: #FF9D00;

                        z-ind .icon {
                            font-size: 28px;
                        }
                    }

                    p {
                        font-size: 26px;
                        font-weight: 500;
                        color: #303443;
                        line-height: 33px;
                        flex: 1;
                        padding: 10px 0 0;
                    }
                }
            }

            img {
                position: absolute;
                z-index: 0;
                bottom: 0;
                width: 100%;
                left: 0;
            }
        }
    }

    .footer-wrap {
        padding: 0 30px;
        background-color: $color-bold-black;

        .footer-img {
            text-align: left;
            padding-top: 30px;
            padding-bottom: 50px;

            img {
                width: 170px;
                height: 42px;
            }
        }

        .nav-list {
            display: flex;
            flex-wrap: wrap;
            border-bottom: 1px solid #494949;

            .item {
                width: 33%;
                margin-bottom: 40px;

                h3 {
                    font-size: 30px;
                    color: #D3D3D3;
                    margin-bottom: 15px;
                }

                p {
                    font-size: 20px;
                    color: #5D5D5D;
                }
            }

            .item-active {
                h3 {
                    color: #f8c272;
                }
            }

            .blank {
                width: 100%;
                height: 40px;
            }
        }

        .copyright {
            text-align: center;
            padding-top: 50px;
            padding-bottom: 20px;

            p {
                font-size: 24px;
                color: #5D5D5D;
            }

            a {
                font-size: 24px;
                color: #666;
                margin-bottom: 20px;
                cursor: pointer;
            }
        }

        .footer_main {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #5D616F;
            padding: 0 0 60px;

            .item {
                .tit {
                    font-size: 36px;
                    font-weight: bold;
                    color: #FFFFFF;
                    text-align: left;
                }

                .txt {
                    font-size: 24px;
                    font-weight: 500;
                    color: #A4A9BC;
                    margin: 40px 0 0;
                    text-align: left;

                    a {
                        color: #a4a9bc;
                        position relative;
                    }
                }
            }
        }

        .footer_btm {
            display: flex;
            justify-content: space-between;
            padding: 60px 0 70px;

            .lt {
                .tit {
                    font-size: 36px;
                    font-weight: bold;
                    color: #FFFFFF;
                    text-align: left;
                }

                .txt {
                    font-size: 24px;
                    font-weight: 500;
                    color: #A4A9BC;
                    margin: 40px 0 0;
                    text-align: left;
                }
            }

            .rt {
                padding: 0 46px 0 0;

                .txtbox {
                    font-size: 24px;
                    font-weight: 500;
                    color: #FFFFFF;
                    text-align: left;

                    .txt {
                        font-size: 24px;
                        font-weight: 500;
                        color: #A4A9BC;
                        margin: 20px 0 0;
                        text-align: left;
                        padding-bottom: 40px;
                    }
                }

                .img-list {
                    display: flex;
                    padding-top: 40px;

                    i {
                        margin-right: 20px;
                        color: #A4A9BC;
                        font-size: 20px;
                        position: relative;
                        cursor: pointer;
                    }

                    .icon-weibo {
                        display: block;
                        font-size: 34px;
                    }

                    .icon-zhihu {
                        display: block;
                        font-size: 34px;
                    }
                }
            }
        }
    }
</style>