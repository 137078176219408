<template>
<div class="help-detail markdown" v-html="content_html">
</div>
</template>

<script>
export default {
    name: 'helpDetail',
    components: {
    },
    data () {
        return {
            code: '',
            content_html: ''
        }
    },
    mounted () {
        this.code = this.$route.query.code
        this.getDetail(this.code)
    },
    methods: {
        getDetail(code) {
            this.$fetch.api_article.detail(code).then(({data}) => {
                this.content_html = data.content_html
            }).catch((data) => {
                this.$createToast({
                    txt: data.msg,
                    type: 'error'
                }).show()
            })
        }
    }
}
</script>
