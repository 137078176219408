<template>
    <div class="tt-tabbar">
        <ul class="tab-group">
            <li class="tab-group__item" v-for="item in tabbarData"
                :class="{'tab-group__active':tabActive===item.routerName}"
                :key='item.value' @click="touchTabbar(item)">
                <i class="iconfont tab-group__icon" :class="item.icon"></i>
                <span class="tab-group__label">{{item.label}}</span>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'Tabbar',
        props: {},
        data () {
            return {}

        },
        computed: {
            tabActive () {
                return this.$route.name
            },
            tabbarData () {
                let data = [{
                    label: '首页',
                    icon: 'icon-shouye',
                    value: 0,
                    router: 'home',
                    routerName: 'home'
                }, {
                    label: '个案',
                    icon: 'icon-falv',
                    value: 1,
                    router: 'service',
                    routerName: 'service'
                }, {
                    label: '批量',
                    icon: 'icon-anjian',
                    value: 2,
                    router: 'cases',
                    routerName: 'cases'
                }, {
                    label: '案例',
                    icon: 'icon-zhishi',
                    value: 3,
                    router: 'examples',
                    routerName: 'examples'
                }, {
                    label: '我们',
                    icon: 'icon-zhuanshuSF',
                    value: 4,
                    router: 'us',
                    routerName: 'us'
                }]

                if (this.$route.name === 'detail') {
                    data[3].routerName = 'detail'
                }
                return data
            }
        },
        methods: {
            touchTabbar (item) {
                this.$router.push({ name: item.router })
            }
        }

    }
</script>

<style lang="stylus">
    @require 'assets/stylus/color-varaibles'

    .tt-tabbar
        position fixed
        bottom 0
        left 0
        width 100%
        border-top 1px solid $color-light-grey
        background-color $color-white

    .tab-group
        display flex
        align-items center
        justify-content space-around
        height 120px
        &__item
            text-align center
            color $color-light-black
        &__active
            color $color-primary-green
        &__icon
            font-size 40px
        &__label
            display block
            font-size 24px
            padding-top 12px
</style>
