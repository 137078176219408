<template>
<div class="app-guide">
    <img class="guide-img" src="../../public/images/appGuide/guide.png" alt="">
</div>
</template>

<script>
export default {
    name: 'appguide',
    components: {

    },
    data () {
        return {
            isWx: false
        }
    },
    mounted () {
        this.isWx = this.checkWx()
        if (!this.isWx) {
            window.location.href = 'https://law086com.oss-cn-hangzhou.aliyuncs.com/app/law86combeta1.0.apk'
        }
    },
    methods: {
        checkWx() {
            var ua = navigator.userAgent.toLowerCase();
            var isWeixin = ua.indexOf('micromessenger') != -1;
            if (isWeixin) {
                return true;
            } else{
                return false;
            }
        }
    }
}
</script>
<style lang="stylus">
.app-guide{
    .guide-img{
        display: block;
        width: 750px;
        height 1206px;
        margin: 0 auto;
    }
}
</style>


