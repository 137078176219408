<template>
    <div class="home-wrap">
        <div class="banner-wrap program-banner">
            <div class="banner-title">案件云小程序</div>
            <div class="banner-txt">随时随地，拿起手机即可开始您的工作</div>
            <div class="qr-wrap">
                <img src="../../public/images/miniProgram/1.png" alt="" class="qr-img">
                <div class="qr-txt">微信扫一扫</div>
            </div>
        </div>
        <div class="introduction-wrap">
            <div class="introduction-item">
                <div class="introduction-title">集合PC全部操作功能，两端数据同步</div>
                <div class="introduction-txt">快捷记录，移动办公，轻松管理日常业务</div>
                <img src="../../public/images/miniProgram/back01.png" alt="" class="introduction-img">
            </div>
            <div class="introduction-item" style="background: #F7F7F7;">
                <div class="introduction-title">无需部署安装，还可微信免密登录</div>
                <div class="introduction-txt">快捷记录，移动办公，轻松管理日常业务</div>
                <img src="../../public/images/miniProgram/back02.png" alt="" class="introduction-img2">
            </div>
            <div class="introduction-item">
                <div class="introduction-title">电子名片编辑、分享，更快连结客户</div>
                <div class="introduction-txt">快捷记录，移动办公，轻松管理日常业务</div>
                <img src="../../public/images/miniProgram/back03.png" alt="" class="introduction-img3">
            </div>
        </div>
        <nowdownload />
        <Footer/>
    </div>
</template>

<script>
    // import { JumpForm } from '@/components'
    import { Footer } from '@/components'
    import nowdownload from '@/components/nowdownload/index'
    export default {
        name: 'home',
        components: {
            //JumpForm,
            Footer,
            nowdownload
        },
        data () {
            return {
                
            }
        },
        created () {
        },
        methods: {}
    }
</script>
