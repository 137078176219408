<template>
    <div class="home-wrap case-report">
        <!-- <div class="top-water"></div> -->
        <!-- <img src="../../public/images/caseReport/line-back.png" alt="" class="top-line"> -->
        <div class="report-wrap">
        <!-- <p class="report-title">案件服务报告</p> -->
        <div class="report-content">
            <p class="report-name">{{caseInfo.case_name}}</p>
            <div class="card-part">
                <div class="card-title">案件基本信息</div>
                <div class="card-content">
                    <p>委托人：<span>{{caseInfo.pc_text}}</span></p>
                    <p>对方当事人：<span v-if="caseInfo.c_type_pid != 1" style="line-height: 1.2">{{caseInfo.pd_text}}</span></p>
                    <p>委托时间：<span>{{caseInfo.pr_time}}</span></p>
                    <p>案由：<span>{{caseInfo.anyou}}</span></p>
                    <img src="../../public/images/caseReport/report-info.png" class="card-info-img">
                </div>
            </div>
            <div class="card-part" style="position: relative;">
                <div class="card-title lawyer-title" style="border-bottom: none;">承办律师</div>
                <div class="card-content" v-if="lawyers.host_lawyer">
                    <p>主办律师：<span>{{lawyers.host_lawyer.real_name}}</span></p>
                    <p>协办律师：<span>{{lawyers.assit_lawyers}}</span></p>
                    <img src="../../public/images/caseReport/report-lawyer.png" class="card-lawyer-img">
                </div>
            </div>
            <div class="card-part">
                <div class="card-title">办理情况</div>
                <div class="card-content">
                    <div class="card-situation">
                        <div class="situation-item">
                            <div :class="totalTime.length > 4 ? 'item-medium' : 'item-big'" v-if="timecost >= 60">{{totalTime}}<span>h</span></div>
                            <div class="item-big" v-else>{{timecost}}<span>m</span></div>
                            <div class="item-small">合计用时</div>
                        </div>
                        <div class="situation-item">
                            <div class="item-big">{{records.length}}<span>件</span></div>
                            <div class="item-small">完成事项</div>
                        </div>
                        <div class="situation-item">
                            <div class="item-big" >{{peopleNum}}<span>位</span></div>
                            <div class="item-small">服务团队</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-part">
                <div class="card-title">办理事务</div>
                <div class="card-content">
                    <p v-for="(item, index) in records" :key="index" v-if="hasRecord">
                        <span class="card-circle"></span>
                        <span>{{item.htime}}，{{item.rtype_text}}<span v-if="item.time_cost > 0">，用时【 <span v-if="item.time_cost >= 60">{{item.time_cost | changeHour}}h</span><span v-else>{{item.time_cost}}m</span> 】</span></span>
                    </p>
                    <p v-if="!hasRecord" style="text-align: center;">暂无数据</p>
                </div>
            </div>
            <div class="card-part">
                <div class="card-title">律师简介</div>
                <div class="card-content">
                    <div class="card-lawyer" v-if="lawyers.host_lawyer">
                        <img :src="lawyers.host_lawyer.img_path" alt="" v-if="lawyers.host_lawyer.img_path">
                        <img src="../../public/images/caseReport/user.png" alt="" v-else>
                        <div class="lawyer-right">
                            <p class="lawyer-name">{{lawyers.host_lawyer.real_name}} 律师</p>
                            <p class="lawyer-item"><i class="iconfont icon-gongsi gongsi-icon"></i>{{lawyers.host_lawyer.lawfirm}}</p>
                            <p class="lawyer-item"><i class="iconfont icon-shouji"></i>{{lawyers.host_lawyer.mobile}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blank"></div>
        </div>
        <!-- <div class="top-water" style="transform: rotate(180deg);margin-top: 20px;"></div> -->
        </div>
    </div>
</template>

<script>
import { img_url } from '../common/config/index'
export default {
  name: 'home',
  components: {
  },
  data () {
    return {
        caseInfo: {},
        lawyers: {},
        records: [],
        timecost: 0,
        imgUrl: img_url,
        code: ''
    }
  },
  computed:{
    totalTime() {
        return (this.timecost / 60).toFixed(1)
    },
    peopleNum() {
        let num
        if (this.caseInfo.assit) {
            num = this.caseInfo.assit.split(',').length + 1
        } else {
            num = 1
        }
        return num
    },
    hasRecord() {
        if (this.records.length > 0) {
            return true
        } else {
            return false
        }
    }
  },
  created () {
    this.code = this.$route.query.code
    let isFromGuide = localStorage.getItem('fromGuide');
    if (isFromGuide === '1') {
        localStorage.getItem('fromGuide');
        this.reportData()
    } else {
        this.$router.push({ name: 'ReportGuide'})
    }
  },
  beforeDestroy () {
    localStorage.clear('fromGuide');
    Object.assign(this.$data, this.$options.data())
  },
  methods: {
    reportData(){
        let params = {
            code: this.code
        }
        this.$fetch.api_article.caseReportData(params).then(({data, code}) => {
            if (code === 0) {
                this.caseInfo = data.case_info
                this.lawyers = data.lawyers
                if (data.lawyers.host_lawyer.img_path && data.lawyers.host_lawyer.img_path.indexOf('http') < 0) {
                    this.lawyers.host_lawyer.img_path = this.imgUrl + data.lawyers.host_lawyer.img_path
                } else {
                    this.lawyers.host_lawyer.img_path = data.lawyers.host_lawyer.img_path
                }
                this.timecost = data.timecost
                this.records = data.records
            }
        }).catch(() => {
            this.$router.push({ name: 'ReportGuide'})
        })
    }
  }
}
</script>