<template>
<div class="product-wrap solution-wrap">
    <div class="common-banner solution-banner">
        <p class="common-banner__chinese">解决方案</p>
        <p class="common-banner__english">solution</p>
    </div>
    <div class="common-explain">
        <div class="title">多样化功能重组，为您<br>定制贴身的业务管理解决方案</div>
        <div class="english">Diversified functional reorganization to customize your person</div>
    </div>
    <div class="common-tab">
        <div class="tab-item" :class="tabIndex == index ? 'tab-active' : ''" v-for="(item, index) in tabItems" :key="index" @click="tabChange(index)">
            <div class="item-icon">
                <i class="iconfont" :class="item.icon"></i>
            </div>
            <div class="item-txt">{{item.txt}}</div>
        </div>
    </div>
    <img class="tab-bottom" src="../../public/images/home/tab0.png" alt="" v-if="tabIndex == 0">
    <img class="tab-bottom" src="../../public/images/home/tab1.png" alt="" v-if="tabIndex == 1">
    <img class="tab-bottom" src="../../public/images/home/tab2.png" alt="" v-if="tabIndex == 2">
    <img class="tab-bottom" src="../../public/images/home/tab3.png" alt="" v-if="tabIndex == 3">
    <div class="product-content" v-if="tabIndex == index" v-for="(item, index) in contentItems" :key="index">
        <div class="content-title">{{item.title}}</div>
        <div class="title-more">{{item.titleMore}}</div>
        <div v-for="(item2, index2) in item.partItems" :key="index2">
            <div class="num-wrap">
                <div class="num">{{item2.num}}</div>
                <div class="num-rectangle"></div>
            </div>
            <div class="second-title">{{item2.secondTitle}}</div>
            <div class="small-title mg-top-20">{{item2.txt1}}</div>
            <div class="small-title">{{item2.txt2}}</div>
            <div class="small-title">{{item2.txt3}}</div>
            <div class="small-title" v-if="item2.txt4">{{item2.txt4}}</div>
            <div class="small-title" v-if="item2.txt5">{{item2.txt5}}</div>
            <img :src="item2.imgUrl" alt="" class="common-block-img" :class="item2.imgClass">
        </div>
    </div>
    <JumpForm />
    <Footer/>
</div>
</template>

<script>
import { JumpForm } from '@/components'
import { Footer } from '@/components'
export default {
    name: 'solution',
    components: {
        JumpForm,
        Footer
    },
    data () {
        return {
            tabIndex: 0,
            tabItems: [{
                icon: 'icon-geren',
                txt: '个人业务管理'
            }, {
                icon: 'icon-tuanduix',
                txt: '律师/法务团队管理'
            }, {
                icon: 'icon-gongsi2',
                txt: '律所管理'
            }, {
                icon: 'icon-piliangyewuBatchBusiness',
                txt: '批量业务管理'
            }],
            contentItems: [{
                title: '个人业务管理',
                titleMore: '快捷记录，移动办公，轻松管理日常业务',
                partItems: [{
                    num: '01',
                    secondTitle: '清晰记录，合理安排',
                    txt1: '同时处理的案子很多，事情安排经常“打架”怎么办？',
                    txt2: '简要记录各个案件的阶段和待办，再根据日程列表',
                    txt3: '合理调整，重要的事还得设置提醒，这样事情再多',
                    txt4: '也能有条不紊。',
                    imgUrl: `${process.env.BASE_URL}images/solution/solution01.png`,
                    imgClass: 'block-img17'
                }, {
                    num: '02',
                    secondTitle: '客户交流存档，联系时有的放矢',
                    txt1: '客户电话不断，微信切换不停，为了避免张冠李戴，',
                    txt2: '不妨及时将各个客户的交流简单记录，形成完整的',
                    txt3: '记录日志，下次向客户通知消息或催款时，都能心',
                    txt4: '中有数。',
                    imgUrl: `${process.env.BASE_URL}images/solution/solution02.png`,
                    imgClass: 'block-img18'
                }, {
                    num: '03',
                    secondTitle: '随时标注 记录灵感 利用好碎片化时间',
                    txt1: '庭审结束后坐在返程的车上，回顾庭上对方律师和',
                    txt2: '法官的几点质疑，觉得可以从几个方面各个击破，',
                    txt3: '赶紧拿起手机记录在这个案件策略里，为下次开庭',
                    txt4: '或谈判作准备。',
                    imgUrl: `${process.env.BASE_URL}images/solution/solution03.png`,
                    imgClass: 'block-img19'
                }]
            }, {
                title: '律师/法务团队管理',
                titleMore: '在线实时协同，提高团队作战能力和效率',
                partItems: [{
                    num: '01',
                    secondTitle: '合作办案，让协作者实时知晓案件处理进展',
                    txt1: '合作办案的律师分头行动，处理案件相关事项，事',
                    txt2: '情办结后归档办理结果，以便协作者实时查看。重',
                    txt3: '要日程安排设置协办和提醒，及时知会协作者。',
                    imgUrl: `${process.env.BASE_URL}images/solution/solution04.png`,
                    imgClass: 'block-img20'
                }, {
                    num: '02',
                    secondTitle: '掌握成员日程，合理派发任务',
                    txt1: '客户主动上门咨询，派谁接待？打开团队日程，结',
                    txt2: '合团队案件、项目进展情况，根据各成员忙碌程度',
                    txt3: '进行分配调度，力求资源配置最优化。',
                    imgUrl: `${process.env.BASE_URL}images/solution/solution05.png`,
                    imgClass: 'block-img21'
                }, {
                    num: '03',
                    secondTitle: '把控财务情况，辅助服务决策',
                    txt1: '这个项目投入的人力和临时出差比预计的多很多，',
                    txt2: '担心会亏本怎么办？打开项目收支记录看一眼，还',
                    txt3: '好，待收金额和入账收益除了覆盖成本，盈余较丰，',
                    txt4: '可以放心干下去。',
                    imgUrl: `${process.env.BASE_URL}images/solution/solution06.png`,
                    imgClass: 'block-img22'
                }, {
                    num: '04',
                    secondTitle: '把握团队整体状况，对症管理',
                    txt1: '前两个季度新接的案子数量差不多，合同金额也没',
                    txt2: '有差多少，为什么上季度团队工时整整多出了一倍？',
                    txt3: '是新招的人员还没上手呢，还是有些案子遇到了“拦',
                    txt4: '路虎”？得把工时统计好好对比研究一下，找出问题',
                    txt5: '才好解决。',
                    imgUrl: `${process.env.BASE_URL}images/solution/solution07.png`,
                    imgClass: 'block-img23'
                }]
            }, {
                title: '律所管理',
                titleMore: '规范案件办理流程，高效进行所务管理',
                partItems: [{
                    num: '01',
                    secondTitle: '案冲检索，避免利益冲突',
                    txt1: '之前律所案件未“上网”时，接案子得拿着行政给的',
                    txt2: '档案记录逐一比对，有时立案了才发现与其他律师',
                    txt3: '案子冲突了。现在立案之前先在系统检索一遍',
                    txt4: '再也不会白忙活。',
                    imgUrl: `${process.env.BASE_URL}images/solution/solution08.png`,
                    imgClass: 'block-img24'
                }, {
                    num: '02',
                    secondTitle: '在线立案审批，精简管理流程',
                    txt1: '律所规定立案得经过主任审批，但是主任很忙碌，',
                    txt2: '经常不在律所，有时电话也联系不上，很着急。走',
                    txt3: '线上化审批流程后，立案申请审批机动性增强了很',
                    txt4: '多，效率大大提升。',
                    imgUrl: `${process.env.BASE_URL}images/solution/solution09.png`,
                    imgClass: 'block-img25'
                }]
            }, {
                title: '批量业务管理',
                titleMore: '模板化创建，项目式管理，省时省心',
                partItems: [{
                    num: '01',
                    secondTitle: '快速批量创建，关联快速索引',
                    txt1: '同一批案件通过“批量创建”或“复制”案件快速新建，',
                    txt2: '再根据个案差异进行细节调整。将某个案件关联其',
                    txt3: '它所有案件，办案时快捷进入同类案件，方便批量',
                    txt4: '事务处理。',
                    imgUrl: `${process.env.BASE_URL}images/solution/solution10.png`,
                    imgClass: 'block-img26'
                }, {
                    num: '02',
                    secondTitle: '项目关联案件，批量管理',
                    txt1: '新建诉讼项目，关联批量案件，在项目的“关联案件”',
                    txt2: '模块快速查看批量案件的所有动态，也可逐一查看；',
                    txt3: '在项目“财务情况”里实时查看批量案件的收支状况。',
                    imgUrl: `${process.env.BASE_URL}images/solution/solution11.png`,
                    imgClass: 'block-img27'
                }]
            }]
        }
    },
    mounted () {

    },
    methods: {
        tabChange(index) {
            this.tabIndex = index
        }
    }
}
</script>
