<template>
	<div style="background: #fff;">
		<div class="item_wrap step1">
			<div class="item_imgbox">
				<img :src="item_img1" width="100%" />
			</div>
			<div class="item_btm">
				<div class="item_tit">超实用法律工具<br />助你高效完成工作</div>
				<div class="item_txt">法律人在日常工作中经常要用互联网查阅办案资料，但是法律网站五花八门，质量层次不齐，到底哪些才真正实用？案件云收录了一系列法律人常用费用工具、法规检索、导航网站，只需一键即可访问相应的网站，让你更快更准找到您想要的内容。</div>
				<div class="item_btn">
					<span @click="jumpGuide">立即下载</span>
				</div>
			</div>
		</div>
		<div class="item_wrap1" v-for="(item, index) in wrap_data" :key="index">
			<div class="item_wrap1_tit">{{ item.tit }}</div>
			<p class="item_wrap1_txt" v-html="item.txt"></p>
			<div>
				<img :src="item.img" width="100%" />
			</div>
		</div>
		<div class="ptp50">
			<nowdownload />
		</div>
		<Footer />
	</div>
</template>

<script>
import nowdownload from '@/components/nowdownload/index'
import { Footer } from '@/components'
function requireImg(str) {
	return require('pbimages/tool/' + str)
}
export default {
	components: {
		nowdownload,
		Footer,
	},
	data() {
		return {
			item_img1: requireImg('step1.png'),
			wrap_data: [
				{ tit: '费用计算工具', txt: '内置法律人常用的费用计算工具，快速计算律师费、诉讼费、交通赔偿等多种费用， 提高工作效率。', img: requireImg('step2.png') },
				{ tit: '通用查询', txt: '关联无讼法规、无讼案例，输入关键词，点击“查询”，即可直接访问无讼查询；另外还可以查询企业征信和公开信息等。', img: requireImg('step3.png') },
				{ tit: '合同模板', txt: '合同模板库里内置了1500多份合同模板，全部免费下载使用，支持关键字搜索。', img: requireImg('step4.png') },
				{ tit: '网站导航', txt: '汇集了法律人高效工作必备的100+网站清单,并将其分类整理,为您提供快捷方便的使用体验。', img: requireImg('step6.png') },
			],
		}
	},
	mounted() {},
	methods: {
		jumpGuide() {
			this.$router.push({ name: 'downApp' })
		},
	},
}
</script>

<style lang="stylus" scoped>
.item_wrap{
    &.step1{
        background: linear-gradient(180deg, #F2F8FE 0%, #EAF1FF 100%);
        background: url("../../public/images/case/bg.jpg") no-repeat;
        background-size: cover;
        background-position-x: 70%;
    }
    .item_imgbox{
    padding: 65px 63px 60px;
    }
    .item_btm{
        //background: #F2F3F5;
        padding: 0 0 60px 0;
        .item_tit{
            font-size: 48px;
            font-weight: 800;
            color: #303443;
            line-height: 58px;
            text-align: center;

        }
        .item_txt{
            font-size: 26px;
            font-weight: 500;
            color: #5E606B;
            line-height: 46px;;
            padding: 39px 35px 97px;
        }
        .item_btn{
            display: flex;
            justify-content: center;
            span{
                width: 228px;
                height: 80px;
                background: #FABA4A;
                border-radius: 10px;
                color: #fff;
                font-size: 30px;
                font-weight: bold;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
.item_wrap1{
    padding: 80px 40px 50px;
    &_tit{
        font-size: 34px;
        font-weight: bold;
        color: #303443;
        text-align: left;
        padding: 0 0 20px 0;
    }
    &_txt{
        font-size: 26px;
        font-weight: 500;
        color: #7E818F;
        line-height: 46px;
        text-align: left;
    }
    &:nth-child(even){
        background: #fff;
    }
    &:nth-child(odd){
        background: #F8F9FB;
    }
}
.ptp50{
    padding-top: 50px;
}
.tab_wrap{
    display: flex;
    justify-content: center;
    height: 140px;
    background: #F5F8FF;
    .tab_main{
        display: flex;
        align-items: center;
        span{
            font-size: 36px;
            font-weight: 500;
            color: #7E818F;
            display: flex;
            height: 100%;
            align-items: center;
            position relative;
            &:first-child{
                margin: 0 143px 0 0;
            }
            img{
                width 40px;
                margin: 0 8px 0 0;
            }
            &.active{
                &:after{
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    background: #303443;
                    height: 4px;
                    content: "";
                }

            }
        }
    }
}
</style>
