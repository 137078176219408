import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import App from './App.vue'
import './cube-ui'
import 'amfe-flexible'
import api from './common/api'
import {
    /* eslint-disable no-unused-vars */
    Style,
    Button,
    Loading,
    Tip,
    Popup,
    Toast,
    Picker,
    Scroll,
    Slide,
    createAPI
} from 'cube-ui'
import router from './router'

Vue.use(api) //导入api供全局使用

Vue.use(Button)
Vue.use(Loading)
Vue.use(Popup)
Vue.use(Toast)
Vue.use(Picker)
Vue.use(Scroll)
Vue.use(Slide)
Vue.use(VueClipboard)

import MyDialog from './components/dialog/index.js'
Vue.use(MyDialog)

Vue.config.productionTip = false

Vue.filter('changeHour', function(val) {
    return (val / 60).toFixed(1)
});

Vue.filter('numberChinese', function(num) {
    num = Number(num);
    var upperCaseNumber = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '百', '千', '万', '亿'];
    var length = String(num).length;
    if (length == 1) {
        return upperCaseNumber[num];
    } else if (length == 2) {
        if (num == 10) {
            return upperCaseNumber[num];
        } else if (num > 10 && num < 20) {
            return '十' + upperCaseNumber[String(num).charAt(1)];
        } else {
            return upperCaseNumber[String(num).charAt(0)] + '十' + upperCaseNumber[String(num).charAt(1)].replace('零', '');
        }
    }
});

Vue.filter('changeTime', function(val) {
    if (val) {
        return val.substring(0, 10)
    } else {
        return ''
    }
});

Vue.filter('numFormat2', function(value) {
    if (!value) return '0'
    value = Math.round(value)
    var dotNum = value.toString().split('.')[1]
    var intPart = parseInt(value) // 获取整数部分
    var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
    if (dotNum && dotNum != '00') {
        intPartFormat = intPartFormat + '.' + dotNum
    }
    return intPartFormat
});
Vue.config.ignoredElements.push('wx-open-launch-weapp')
new Vue({
    el: '#app',
    router,
    render: h => h(App),
    mounted() {
        document.dispatchEvent(new Event('render-event'))
    }
}).$mount('#app')
