/*
 * @Author: tombear 
 * @Date: 2018-09-26 16:54:35 
 * @Last Modified by: tombear
 * @Last Modified time: 2018-09-26 17:39:25
 */


//导入模块
import * as api_article from './article'
import * as api_finance from './finance'


const apiObj = {
    api_article,
    api_finance
}

const install = function (Vue) {
    if (install.installed) return
    install.installed = true

    //定义属性到Vue原型中
    Object.defineProperties(Vue.prototype, {
        $fetch: {
            get () {
                return apiObj
            }
        }
    })
}

export default {
    install
}
