<template>
	<div class="home-wrap">
		<swiper class="main_item_swiper" :options="swiperOptionbanner">
			<swiper-slide>
				<div class="item_wrap">
					<div class="item_imgbox step1">
						<img :src="item_img1" width="100%" />
					</div>
					<div class="item_btm">
						<div class="item_tit">
							智能案件管理云平台
						</div>
						<div class="item_txt">
							让案件管理更高效·团队协作更轻松
						</div>
						<div class="item_btn">
							<span @click="jumpGuide">立即下载</span>
						</div>
					</div>
				</div>
			</swiper-slide>
			<!-- <swiper-slide>
				<div class="item_wrap">
					<div class="item_imgbox stephd">
						<img :src="hd_img" width="100%" />
					</div>
					<div class="hd_wrap">
						<div class="hd_item1">
							<div>
								<img :src="hd_img1" />
								<span>折</span>
							</div>
						</div>
						<div class="hd_item2">
							<img :src="hd_img2" />
						</div>
						<p class="hd_txt">聚焦律界新生力量，5折助力新律师/律所高效办公<br />活动时间：2022年4月18日 - 2022年6月30日</p>
						<div class="hd_btn">
							<a href="https://mp.weixin.qq.com/s/Zqhcp_ms6xit31L1uGf_NA">立即参与</a>
						</div>
					</div>
				</div>
			</swiper-slide> -->
		</swiper>
		<div class="item_wrap1">
			<div class="main_item" v-for="(item, index) in pro_data" :key="index">
				<div class="main_item_tit">
					<div class="pbox"><img :src="item.smallimg" />{{ item.tit }}</div>
				</div>
				<swiper class="main_item_swiper" :options="swiperOption">
					<swiper-slide class="main_item_slide" v-for="(it, ind) in item.data" :key="ind">
						<div class="main_item_imgbox"><img width="100%" :src="it.img" /></div>
						<div class="main_item_tit1">{{ it.tit }}</div>
						<p class="main_item_txt1">{{ it.txt }}</p>
					</swiper-slide>
					<div class="swiper-pagination swiper-pagination0" slot="pagination"></div>
				</swiper>
			</div>
		</div>
		<div class="item_wrap2 step3">
			<div class="item_wrap2_tit">团队高效率协作</div>
			<p class="item_wrap2_txt">通过团队日程共享、任务分配与协作、文档协同编辑与存储、知识经验分享与沉淀，标准化团队办案流程，及时共享案件状态和工作进度，提升团队协作能力。</p>
			<div class="item_wrap2_imgbox">
				<img :src="wrap2_img" width="100%" />
			</div>
		</div>
		<div class="item_wrap2">
			<div class="item_wrap2_tit">律所一体化办公</div>
			<p class="item_wrap2_txt">规范律所案件办理流程，有效整合申请、审批与财务管理，施行线上化流程控制，使得律所内部的管理更加合规、高效，有利于提升律所整体的向心力与核心竞争力。</p>
			<div class="item_wrap2_imgbox pdbtm60">
				<img :src="wrap2_img1" width="100%" />
			</div>
		</div>
		<div class="item_wrap3">
			<div class="item_wrap3_imgbox">
				<img width="100%" src="../../public/images/home/qy.jpg" />
			</div>
			<div class="item_wrap3_main">
				<div class="item_wrap3_tit">企业法务全面统筹</div>
				<p class="item_wrap3_txt">支持企业私有化部署，集成案件云全部功能，增加企业法律供应商管理，案件字段定制，数据统计更全面，<em>芒果TV、锦江酒店（中国区）</em>等众多优秀企业法务部都在用。</p>
				<div class="item_wrap3_btn">
					<router-link :to="{ name: 'deployment' }">了解企业方案<i class="iconfont icon1 icon-zelvxuanzefeiyongdaosanjiaoxingfandui"></i></router-link>
				</div>
			</div>
		</div>
		<div class="item_wrap4">
			<div class="item_wrap4_tit">产品亮点</div>
			<swiper class="main_item_swiper" :options="swiperOption1">
				<swiper-slide class="main_item_slide" v-for="(it, ind) in ld_data" :key="ind">
					<div class="main_item_imgbox"><img :src="it.img" /></div>
					<div class="main_item_tit1">{{ it.tit }}</div>
					<p class="main_item_txt1">{{ it.txt }}</p>
				</swiper-slide>
				<div class="swiper-pagination swiper-pagination1" slot="pagination"></div>
			</swiper>
		</div>
		<div class="item_wrap5">
			<div class="item_wrap5_tit">律师、团队、律所的<br />同一信赖之选</div>
			<p class="item_wrap5_txt">用心提升产品和服务质量，赢得用户良好口碑</p>
			<swiper class="main_item_swiper" :options="swiperOption2">
				<swiper-slide class="main_item_slide" v-for="(it, ind) in firm_data" :key="ind">
					<div class="main_item_txt1">
						<div class="img1">
							<img src="../../public/images/home/index/fh1.png" />
						</div>
						{{ it.txt }}
						<div class="img2">
							<img src="../../public/images/home/index/fh2.png" />
						</div>
					</div>

					<div class="main_item_imgbox"><img :src="it.img" /></div>
					<div class="main_item_tit1">{{ it.tit }}</div>
					<p class="main_item_txt2">{{ it.txt1 }}</p>
				</swiper-slide>
				<div class="swiper-pagination swiper-pagination2" slot="pagination"></div>
			</swiper>
		</div>
		<div class="ptp50">
			<nowdownload />
		</div>
		<!-- <div class="banner-wrap home-banner">
      <div class="banner-content">
        <h2 class="banner-content__h2">专注于法律人的日常办公</h2>
        <h2 class="banner-content__h2">打造智能案件管理云平台</h2>
        <h3 class="banner-content__h3">随时随地管理·记录您的工作</h3>
        <p class="banner-content__p">Record your work anytime and anywhere</p>
        <div
          class="self-btn-primary btn-round"
          @click="jumpGuide"
        >下载案件云APP</div>
      </div>
    </div>
    <div style="position: absolute;top: -5000px;">
      <wx-open-launch-weapp id="launch-btn" username="gh_0e94cc427778" path="/pages/login-guide/index.html">
        <script type="text/wxtag-template">
          <style>.btn{width: 250px;height: 32px;background: #F8C272;border: none;color: #fff;font-size: 15px;font-weight: bold;border-radius: 5px;}</style>
          <button class="btn">点击进入案件云小程序</button>
        </script>
      </wx-open-launch-weapp>
    </div>
    <div class="introduction-wrap">
      <div class="introduction-item">
        <p class="introduction-home-title">高效管理</p>
        <p class="introduction-home-english">EFFICIENT</p>
        <p class="introduction-txt">以清晰、精简的形式展现所有案件重要</p>
        <p class="introduction-txt introduction-txt2">信息及所处阶段、待办事项，大容量管理井井有条</p>
        <img class="home-img1" src="../../public/images/home/back_01.png">
      </div>
      <div class="introduction-item">
        <p class="introduction-home-title">智能盘点</p>
        <p class="introduction-home-english">INVENTORY</p>
        <p class="introduction-txt">精准的数据统计，多维度分析个人、</p>
        <p class="introduction-txt introduction-txt2">团队的业务增量与工时，成本、收益一眼洞悉</p>
        <img class="home-img2" src="../../public/images/home/back_02.png">
      </div>
      <div class="introduction-item">
        <p class="introduction-home-title">无间协作</p>
        <p class="introduction-home-english">COOPERATION</p>
        <p class="introduction-txt">案件、项目、客户全流程记录，策略、</p>
        <p class="introduction-txt introduction-txt2">建议专区讨论，确保律师能快速开展深度协作</p>
        <img class="home-img3" src="../../public/images/home/back_03.png">
      </div>
    </div>
    <div class="common-explain">
      <div class="title">多样化功能重组，为您<br>定制贴身的业务管理解决方案</div>
      <div class="english">Diversified functional reorganization to customize your person</div>
    </div>
    <div class="common-tab">
      <div class="tab-item" :class="tabIndex == index ? 'tab-active' : ''" v-for="(item, index) in tabItems" :key="index" @click="tabChange(index)">
        <div class="item-icon">
          <i class="iconfont" :class="item.icon"></i>
        </div>
        <div class="item-txt">{{item.txt}}</div>
      </div>
    </div>
    <img class="tab-bottom" src="../../public/images/home/tab0.png" alt="" v-if="tabIndex == 0">
    <img class="tab-bottom" src="../../public/images/home/tab1.png" alt="" v-if="tabIndex == 1">
    <img class="tab-bottom" src="../../public/images/home/tab2.png" alt="" v-if="tabIndex == 2">
    <img class="tab-bottom" src="../../public/images/home/tab3.png" alt="" v-if="tabIndex == 3">
    <div class="home-content" v-for="(item, index) in contentItems" :key="index + 'a'" v-if="tabIndex == index">
      <div class="content-title">{{item.title}}</div>
      <img class="content-img" :src="item.imgUrl" alt="">
      <div class="content-txt" v-if="item.txt1">{{item.txt1}}</div>
      <div class="content-txt" v-if="item.txt2">{{item.txt2}}</div>
      <div class="content-txt" v-if="item.txt3">{{item.txt3}}</div>
      <div class="content-txt" v-if="item.txt4">{{item.txt4}}</div>
    </div>
    <JumpForm /> -->
		<Footer />
	</div>
</template>

<script>
import { JumpForm, Footer } from '@/components'
import nowdownload from '@/components/nowdownload/index'
import wx from 'weixin-js-sdk'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper.min.css'
function requireImg(str) {
	return require('pbimages/home/index/' + str)
}
export default {
	name: 'home',
	components: {
		JumpForm,
		Footer,
		swiper,
		swiperSlide,
		nowdownload,
	},
	data() {
		return {
			item_img1: requireImg('step1img.png'),
			swiperOption: {
				pagination: {
					el: '.swiper-pagination0',
					clickable: true,
				},
				slidesPerView: 1,
				spaceBetween: 10,
				loop: false,
			},
			swiperOptionbanner: {
				slidesPerView: 1,
				spaceBetween: 10,
				loop: true,
				autoplay: true,
			},
			swiperOption1: {
				pagination: {
					el: '.swiper-pagination1',
					clickable: true,
				},
				slidesPerView: 1.4,
				spaceBetween: 20,
				loop: false,
			},
			swiperOption2: {
				pagination: {
					el: '.swiper-pagination2',
					clickable: true,
				},
				slidesPerView: 1,
				spaceBetween: 20,
				loop: false,
			},
			pro_data: [
				{
					tit: '案件管理',
					smallimg: requireImg('ajgl.png'),
					data: [
						{ tit: '轻松录入案件信息', txt: '智能联想推荐或自动匹配数据，帮您快速录入案件信息。', img: requireImg('tab-1.png') },
						{ tit: '支持批量导入导出', txt: '支持一键上传已有数据和导出所有案件为excel表。', img: requireImg('tab-2.png') },
						{ tit: '个性化内容补充与展示', txt: '支持添加自定义字段、表头设置、列表风格切换。', img: requireImg('tab-3.png') },
					],
				},
				{
					tit: '日程管理',
					smallimg: requireImg('rcgl.png'),
					data: [
						{ tit: '重要事项 即时提醒', txt: '定时提醒工作安排，不必担心在繁杂事务中遗忘重要事项。', img: requireImg('tab-4.png') },
						{ tit: '关联案件 及时归档', txt: '将办案记录与日程关联起来，自动整合案件碎片化的工作。', img: requireImg('tab-5.png') },
						{ tit: '待办清单 自动汇总', txt: '将过期未办事项和待计划事项汇总，合理安排时间不遗漏。', img: requireImg('tab-6.png') },
					],
				},
				{
					tit: '文档在线',
					smallimg: requireImg('wdzx.png'),
					data: [
						{ tit: '在线查看编辑文档', txt: '支持多人、多设备同时编辑一篇在线文档，内容自动保存在云端。', img: requireImg('tab-10.png') },
						{ tit: '批量上传与下载', txt: '拖拽上传、文件夹上传、打包下载等各种文件操作一应具备。', img: requireImg('tab-11.png') },
						{ tit: '模板创建 省心省事', txt: '根据当前案件信息，自动生成各种所函、委托书等内部常用文档。', img: requireImg('tab-12.png') },
					],
				},
				{
					tit: '财务情况',
					smallimg: requireImg('fsgl.png'),
					data: [
						{ tit: '收支全留痕记录', txt: '在线记录收支情况，留痕每笔费用开销', img: requireImg('tab-7.png') },
						{ tit: '在线发布和认领收款', txt: '财务在线发布收款，律师认领收款并关联具体合同及收款记录。', img: requireImg('tab-8.png') },
						{ tit: '在线管理费用申请', txt: '在线提交开票/收款/报销/付款申请，审批流程自动流转。', img: requireImg('tab-9.png') },
					],
				},
			],
			wrap2_img: requireImg('step3img.png'),
			wrap2_img1: requireImg('step4img.png'),
			wrap3_img: requireImg('step2img.png'),
			ld_data: [
				{ tit: '高效管理', txt: '以清晰、精简的形式展现所有案件重要信息及所处阶段、待办事项大容量管理井井有条', img: requireImg('ld1.jpg') },
				{ tit: '智能盘点', txt: '精准的数据统计，多维度分析个人、团队的业务增量与工时成本、收益一眼洞悉', img: requireImg('ld2.jpg') },
				{ tit: '无间协作', txt: '案件、项目、客户全流程记录，策略、建议专区讨论，确保律师能快速开展深度协作', img: requireImg('ld3.jpg') },
			],
			firm_data: [
				{ tit: '北京大成（哈尔滨）律师事务所', txt: '案件云是同事推荐给我的，之前也用过很多软件，感觉都没有实践中的积淀。案件云不一样，案件云是在律师工作中不断升级完成的，比较接近律师的工作习惯。希望案件云能够再接再厉，持续更新，进一步探索律师的工作习惯，完善产品功能。', txt1: '律所合伙人', img: requireImg('men1.png') },
				{ tit: '北京市盈科（广州）律师事务所', txt: '案件云是我们对比过多个平台与APP后所最终选定的，算是大浪淘沙后剩下的精品。客服反应快捷，使用方便，贴近日常工作的需求。希望你们以后能继续保持，并坚持功能的完善更新。', txt1: '高级合伙人', img: requireImg('men2.png') },
				{ tit: '福建融成律师事务所', txt: '案件云完美融合了团队协作、任务管理、案件跟踪、链接客户等功能，提高团队效率，案件报告一键生成，提升客户体验感，而且操作简洁，强烈推荐！', txt1: '律所主任', img: requireImg('men3.png') },
				{ tit: '浙江工品律师事务所', txt: '案件云吸收沉淀了律师工作的底层逻辑，开发出来的东西是有内在考虑的，使用时我有种不是一个人在战斗的感觉～。', txt1: '执业律师', img: requireImg('men4.png') },
			],
			tabIndex: 0,
			isWx: true,
			// 活动
			hd_img: requireImg('hd.jpg'),
			hd_img1: requireImg('hd1.png'),
			hd_img2: requireImg('hd2.png'),
		}
	},
	created() {
		//this.isWeiXin()
	},
	mounted() {
		// if (this.isWx) {
		//   this.getWxData()
		// }
	},
	methods: {
		isWeiXin() {
			var ua = window.navigator.userAgent.toLowerCase()
			if (ua.match(/MicroMessenger/i) == 'micromessenger') {
				this.isWx = true
			} else {
				this.isWx = false
			}
		},
		jumpGuide() {
			this.$router.push({ name: 'downApp' })
		},
		tabChange(index) {
			this.tabIndex = index
		},
		getWxData() {
			let params = {
				url: window.location.href,
				hideToast: true,
			}
			this.$fetch.api_article
				.getH5ToWxminiParams(params)
				.then(({ data }) => {
					wx.config({
						appId: data.appId,
						timestamp: data.timestamp,
						nonceStr: data.noncestr,
						signature: data.signature,
						jsApiList: ['openLocation', 'getLocation', 'openCard'],
						openTagList: ['wx-open-launch-weapp'],
					})
					var btn = document.getElementById('launch-btn')
					btn.addEventListener('launch', function(e) {
						console.log('success', e.detail)
					})
					btn.addEventListener('error', function(e) {
						console.log('fail', e.detail)
					})
				})
				.catch((data) => {
					this.$createToast({
						txt: data.msg,
						type: 'error',
					}).show()
				})
		},
	},
}
</script>
<style lang="stylus" scoped>
.step3{
  background: url("../../public/images/home/index/step3.jpg") no-repeat!important;
  background-size: cover;
  background-position-x: 70%;
}
.item_wrap{
  position: relative;
  .item_imgbox{
    padding: 65px 0 0;
    &.step1{
      background: url("../../public/images/home/index/step1.jpg") no-repeat;
      background-size: cover;
      background-position-x: 70%;
    }
    &.stephd{
      padding: 0;
      img{
        display: block;
      }
    }
  }
  .hd_wrap{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    .hd_item1{
      display: flex;
      justify-content: center;
      padding: 40px 0 30px;
      >div{
        position: relative;
        img{
          display: block;
          height: 378px;
        }
        span{
          position: absolute;
          bottom: 15px;
          right: 0;
          font-size: 48px;
          font-weight: 400;
          color: #FF8F00;
        }
      }
    }
    .hd_item2{
      img{
        display: block;
        height: 221px;
        margin: auto;
      }
    }
    .hd_txt{
      font-size: 30px;
      font-weight: bold;
      color: #5A78FF;
      line-height: 42px;
      text-align: center;
      padding: 0 0 36px;
    }
    .hd_btn{
      display: flex;
      justify-content: center;
      align-items: center;
      a{
        border-radius: 6px;
        font-size: 16px;
        font-weight: bold;
        line-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 228px;
        height: 80px;
        background: #FFFFFF;
        border-radius: 10px;
        font-size: 30px;
        font-weight: bold;
        color: #2875FF;
      }
    }
  }
  .item_btm{
    background: #F2F3F5;
    padding: 0 0 90px 0;
    .item_tit{
      font-size: 66px;
      font-weight: 800;
      color: #303443;
      text-align: center;
      padding: 85px 0 0;
    }
    .item_txt{
      font-size: 32px;
      font-weight: 500;
      color: #FF9000;
      text-align: center;
      padding: 39px 0 67px;
    }
    .item_btn{
      display: flex;
      justify-content: center;
      span{
        width: 228px;
        height: 80px;
        background: #FABA4A;
        border-radius: 10px;
        color: #fff;
        font-size: 30px;
        font-weight: bold;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.item_wrap1{
  .main_item{
    padding: 0 35px 60px;
    &_tit{
      display: flex;
      justify-content: center;
      font-size: 36px;
      font-weight: 500;
      color: #303443;
      padding: 60px 0 30px;
      .pbox{
        display: flex;
        align-items: center;
        white-space: nowrap;
        img{
          height: 32px;
          margin: 0 10px 0 0;
        }
      }
    }
    &_swiper{
      // width: 100%;
      overflow: hidden;
      .swiper-pagination{
        text-align: center;
        line-height: 12px;
        margin: 35px 0 20px;
      }
      /deep/ .swiper-pagination-bullet {
          display: inline-block;
          width: 12px;
          height: 12px;
          background: rgba(0,0,0,.25);
          border-radius: 50%;
          bottom: 13px;
          margin:0 5px;
          opacity: 1;
      }
      /deep/ .swiper-pagination-bullet-active {
          background: #000000;
      };
    }
    &_slide{
      .main_item_tit1{
        font-size: 34px;
        font-weight: bold;
        color: #303443;
        padding: 40px 0 20px;
      }
      .main_item_txt1{
        font-size: 26px;
        font-weight: 500;
        color: #7E818F;
        line-height: 36px;
      }
    }
  }
}
.item_wrap2{
  padding: 80px 36px 0;

  .item_wrap2_tit{
    text-align: center;
    font-size: 48px;
    font-weight: bold;
    color: #303443;
  }
  .item_wrap2_txt{
    font-size: 26px;
    font-weight: 500;
    color: #7E818F;
    line-height: 36px;
    text-align: center;
    padding: 30px 0 40px;
  }
  .pdbtm60{
    padding: 0 0 60px;
  }
}
.item_wrap3{
  position: relative;
  &_imgbox{
    background: linear-gradient(90deg, rgba(255, 144, 0, .7), #FFC272);
    opacity: 0.7;
    img{
      display: block;
    }
  }
  &_main{
    position: absolute;
    width: 100%;
    top:0;
    left:0;
    padding: 80px 0 0;
  }
  &_tit{
    font-size: 48px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 60px
    text-align: center;
  }
  &_txt{
    font-size: 26px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 36px;
    text-align: center;
    width: 100%;
    padding: 32px 36px 55px;
    box-sizing: border-box;
    em{
      font-weight: 500;
      font-style: normal;
    }
  }
  &_btn{
    text-align: center;
    a{
      width: 278px;
      height: 80px;
      border: 1px solid #FFFFFF;
      border-radius: 10px;
      display: flex;
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      .icon1{
        transform: rotate(-90deg);
        margin: 0 0 0 30px;
      }
    }
  }
}
.item_wrap4{
  padding: 0 35px 60px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  &_tit{
    font-size: 48px;
    font-weight: bold;
    color: #303443;
    text-align: center;
    padding: 80px 0 60px;
  }
  .main_item_swiper{
    overflow: hidden;
    /deep/ .swiper-wrapper{
      justify-content: stretch;
      padding-right: 30px;
    }
    .main_item_slide{
      background: #FFFFFF;
      box-shadow: 0px 0px 8px 0px rgba(198, 204, 221, 0.45);
      border-radius: 10px;
      padding: 0 53px 85px;
      box-sizing: border-box;
      //margin: 0 30px 0 0!important;
      .main_item_imgbox{
        padding: 65px 0 55px;
        img{
          width: 209px;
          margin: 0 auto;
          display: block;
        }
      }
      .main_item_tit1{
        font-size: 34px;
        font-weight: bold;
        color: #303443;
        text-align: center;
        padding: 0 0 40px 0;
      }
      .main_item_txt1{
        font-size: 26px;
        font-weight: 500;
        color: #7E818F;
        text-align: center;
        line-height: 46px;
      }
    }
  }
}
.item_wrap5{
  background: #F6F7F9;
  padding: 80px 35px 112px;
  overflow: hidden;
  position: relative;
  &_tit{
    font-size: 54px;
    font-weight: bold;
    color: #303443;
    line-height: 72px;
    text-align: center;
  }
  &_txt{
    font-size: 26px;
    font-weight: 500;
    color: #7E818F;
    text-align: center;
    padding: 35px 0 60px;
  }
  .main_item_swiper{
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 0px rgba(198, 204, 221, 0.45);
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    /deep/ .swiper-pagination {
      position: absolute;
      width: 100%;
      text-align: center;
      left: 0;
    }
    /deep/ .swiper-pagination-bullet {
        display: inline-block;
        width: 12px;
        height: 12px;
        background: rgba(0,0,0,.25);
        border-radius: 50%;
        bottom: 13px;
        margin:0 5px;
        opacity: 1;
    }
    /deep/ .swiper-pagination-bullet-active {
        background: #000000;
    };
    /deep/ .swiper-wrapper{
      box-sizing: border-box;

    }
    .main_item_slide{
      box-sizing: border-box;
      background: #FFFFFF;
      border-radius: 10px;
      padding: 40px 35px 60px;
      box-sizing: border-box;
      //margin-right: 35px!important;
    }
    .main_item_txt1{
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #303443;
      line-height: 46px;
      text-align: center;
      min-height: 366px;
      img{
        height: 29px;
      }
      .img1{
        text-align: left;
        margin: 0 0 8px 0;
      }
      .img2{
        text-align: right;
        margin: 8px 0 0 0;
      }
    }
    .main_item_imgbox{
      img{
        width: 108px;
        height: 108px;
        border-radius: 50%;
        margin: 0 auto;
        display: block;
      }
    }
    .main_item_tit1{
      font-size: 32px;
      font-weight: bold;
      color: #303443;
      text-align: center;
      padding: 40px 0 20px;
    }
    .main_item_txt2{
      font-size: 22px;
      font-weight: 500;
      color: #858896;
      text-align: center;
    }
  }
}
.ptp50{
  padding-top: 50px;
}
</style>
