<template>
<div class="product-wrap">
    <div class="common-banner">
        <p class="common-banner__chinese">产品介绍</p>
        <p class="common-banner__english">product description</p>
    </div>
    <div class="product-tab">
        <div class="tab-item" :class="tabIndex == index ? 'tab-active' : ''" v-for="(item, index) in tabItems" :key="index" @click="tabChange(index)">
            <i class="iconfont" :class="item.icon"></i>
            <img :src="item.imgUrl" alt="">
            <p class="title">{{item.title}}</p>
        </div>
    </div>
    <div class="product-content" v-if="tabIndex == index" v-for="(item, index) in contentItems" :key="index">
        <div class="content-title">{{item.title}}</div>
        <div class="title-more">{{item.titleMore}}</div>
        <div v-for="(item2, index2) in item.partItems" :key="index2">
            <div class="num-wrap">
                <div class="num">{{item2.num}}</div>
                <div class="num-rectangle"></div>
            </div>
            <div class="small-title mg-top-20">{{item2.txt1}}</div>
            <div class="small-title">{{item2.txt2}}</div>
            <div class="small-title">{{item2.txt3}}</div>
            <div class="small-title" v-if="item2.txt4">{{item2.txt4}}</div>
            <img :src="item2.imgUrl" alt="" class="common-block-img" :class="item2.imgClass">
        </div>
    </div>
    <JumpForm />
    <Footer/>
</div>
</template>

<script>
import { JumpForm } from '@/components'
import { Footer } from '@/components'
export default {
    name: 'product',
    components: {
        JumpForm,
        Footer
    },
    data () {
        return {
            tabIndex: 0,
            tabItems: [{
                icon: 'icon-richeng-heijin',
                imgUrl: `${process.env.BASE_URL}images/product/icon0.png`,
                title: '日程'
            }, {
                icon: 'icon-anjiandongtai',
                imgUrl: `${process.env.BASE_URL}images/product/icon1.png`,
                title: '案件'
            }, {
                icon: 'icon-xiangmu-heibai',
                imgUrl: `${process.env.BASE_URL}images/product/icon2.png`,
                title: '项目'
            }, {
                icon: 'icon-kehu-heijin',
                imgUrl: `${process.env.BASE_URL}images/product/icon3.png`,
                title: '客户'
            }, {
                icon: 'icon-gaizhang-heijin',
                imgUrl: `${process.env.BASE_URL}images/product/icon4.png`,
                title: '律所'
            }, {
                icon: 'icon-shujutongji',
                imgUrl: `${process.env.BASE_URL}images/product/icon5.png`,
                title: '数据'
            }, {
                icon: 'icon-gongju',
                imgUrl: `${process.env.BASE_URL}images/product/icon6.png`,
                title: '工具'
            }],
            contentItems: [{
                title: '日程管理',
                titleMore: '记录已办，规划待办，适时提醒工作无忧',
                partItems: [{
                    num: '01',
                    txt1: '日程关联案件、项目、客户记录',
                    txt2: '待办日程微信提醒、开庭特殊标记',
                    txt3: '团队日程管理、任务派发【团队及律所版】',
                    imgUrl: `${process.env.BASE_URL}images/product/product01.png`,
                    imgClass: 'block-img1'
                }, {
                    num: '02',
                    txt1: '待办清单按月汇总',
                    txt2: '保全续封到期提醒',
                    txt3: '',
                    imgUrl: `${process.env.BASE_URL}images/product/product02.png`,
                    imgClass: 'block-img2'
                }]
            }, {
                title: '案件管理',
                titleMore: '记录办案全流程，掌控每个案件的进度、要点',
                partItems: [{
                    num: '01',
                    txt1: '自定义案件阶段和记录',
                    txt2: '编辑、分享案件报告',
                    txt3: '案件团队共享协作【团队及律所版】',
                    imgUrl: `${process.env.BASE_URL}images/product/product03.png`,
                    imgClass: 'block-img3'
                }, {
                    num: '02',
                    txt1: '案件卷宗、文档管理',
                    txt2: '案件成本、收益统计',
                    txt3: '财产保全执行及提醒',
                    imgUrl: `${process.env.BASE_URL}images/product/product04.png`,
                    imgClass: 'block-img4'
                }]
            }, {
                title: '项目管理',
                titleMore: '透视诉讼、非诉项目的差异化管理',
                partItems: [{
                    num: '01',
                    txt1: '非诉项目管理',
                    txt2: '诉讼项目关联案件',
                    txt3: '项目团队共享协作【团队及律所版】',
                    imgUrl: `${process.env.BASE_URL}images/product/product05.png`,
                    imgClass: 'block-img5'
                }, {
                    num: '02',
                    txt1: '项目成本、收益统计',
                    txt2: '项目合同、发票管理',
                    txt3: '项目文档、附件管理',
                    imgUrl: `${process.env.BASE_URL}images/product/product06.png`,
                    imgClass: 'block-img6'
                }]
            }, {
                title: '客户管理',
                titleMore: '客户资料统筹管理，关联信息动态同步',
                partItems: [{
                    num: '01',
                    txt1: '客情维护，上次联系情况及时间',
                    txt2: '关联案件、项目，动态汇总',
                    txt3: '',
                    imgUrl: `${process.env.BASE_URL}images/product/product07.png`,
                    imgClass: 'block-img7'
                }, {
                    num: '02',
                    txt1: '成本、收益统计情况',
                    txt2: '合同、发票整理收集',
                    txt3: '文档、附件管理',
                    imgUrl: `${process.env.BASE_URL}images/product/product08.png`,
                    imgClass: 'block-img8'
                }]
            }, {
                title: '律所审批',
                titleMore: '线上化流程控制，律所内部管理更合规、高效',
                partItems: [{
                    num: '01',
                    txt1: '立案申请',
                    txt2: '卷宗归档',
                    txt3: '律师管理',
                    imgUrl: `${process.env.BASE_URL}images/product/product09.png`,
                    imgClass: 'block-img9'
                }, {
                    num: '02',
                    txt1: '审核流程自定义',
                    txt2: '特权：Logo自定义',
                    txt3: '审核提醒、申请结果多种方式通知',
                    imgUrl: `${process.env.BASE_URL}images/product/product10.png`,
                    imgClass: 'block-img10'
                }, {
                    num: '03',
                    txt1: '律所盖章申请',
                    txt2: '律所开票申请',
                    txt3: '律所财务管理',
                    imgUrl: `${process.env.BASE_URL}images/product/product11.png`,
                    imgClass: 'block-img11'
                }]
            }, {
                title: '数据统计',
                titleMore: '汇总每一次记录，清晰量化投入产出比',
                partItems: [{
                    num: '01',
                    txt1: '业务存量、增量统计',
                    txt2: '',
                    txt3: '',
                    imgUrl: `${process.env.BASE_URL}images/product/product12.png`,
                    imgClass: 'block-img12'
                }, {
                    num: '02',
                    txt1: '工时统计、用时分布情况',
                    txt2: '',
                    txt3: '',
                    imgUrl: `${process.env.BASE_URL}images/product/product13.png`,
                    imgClass: 'block-img13'
                }, {
                    num: '03',
                    txt1: '财务整体统计',
                    txt2: '',
                    txt3: '',
                    imgUrl: `${process.env.BASE_URL}images/product/product14.png`,
                    imgClass: 'block-img14'
                }]
            }, {
                title: '工具汇总',
                titleMore: '搜罗办案必备小工具，随手索引各类信息',
                partItems: [{
                    num: '01',
                    txt1: '法规案例查询',
                    txt2: '法院法官查询',
                    txt3: '诉讼、司法鉴定、公证等费用计算',
                    txt4: '失信被执行人、裁判文书等',
                    imgUrl: `${process.env.BASE_URL}images/product/product15.png`,
                    imgClass: 'block-img15'
                }, {
                    num: '02',
                    txt1: '专业化分类合同模板',
                    txt2: '关键字检索更迅速、准确',
                    txt3: '',
                    imgUrl: `${process.env.BASE_URL}images/product/product16.png`,
                    imgClass: 'block-img16'
                }]
            }]
        }
    },
    created () {
    },
    methods: {
        tabChange(index) {
            this.tabIndex = index
        }
    }
}
</script>
