<template>
    <div class="item_wrap6">
      <div class="item_wrap6_tit">立即开通案件云，免费使用</div>
      <p class="item_wrap6_txt">无需下载部署，注册即用，让您和团队的案件管理更轻松</p>
      <div class="item_wrap6_btn">
        <span @click="jumpGuide">立即下载</span>
      </div>
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    methods:{
        jumpGuide () {
            this.$router.push({ name: 'downApp' })
        },
    }
}
</script>

<style lang="stylus" scoped>
    .item_wrap6{
        background: linear-gradient(90deg, #FFC765 0%, #FFB22C 100%);
        padding: 80px 0;
        &_tit{
        font-size: 48px;
        font-weight: 800;
        color: #FFFFFF;
        text-align: center;
        }
        &_txt{
        font-size: 26px;
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;
        padding: 30px 0 60px;
        }
        &_btn{
        span{
            width: 228px;
            height: 80px;
            background: #FFFFFF;
            border-radius: 10px;
            font-size: 30px;
            font-weight: bold;
            color: #FABA4A;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
        }
        }
    }
</style>