import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import MiniProgram from './views/MiniProgram.vue'
import Article from './views/Article.vue'
import Qa from './views/Qa.vue'
import Detail from './views/Detail.vue'
import Contact from './views/Contact.vue'
import Price from './views/Price.vue'
import CaseReport from './views/CaseReport.vue'
import ReportGuide from './views/ReportGuide.vue'
import ReportResult from './views/ReportResult.vue'
import Product from './views/Product.vue'
import Solution from './views/Solution.vue'
import Safe from './views/Safe.vue'
import PersonalWeb from './views/PersonalWeb.vue'
import Help from './views/Help.vue'
import HelpList from './views/HelpList.vue'
import HelpDetail from './views/HelpDetail.vue'
import Finance from './views/Finance.vue'
import Sign from './views/Sign.vue'
import AppGuide from './views/AppGuide.vue'
import serve from './views/serve.vue'
import downApp from './views/downApp.vue'
import ProductCase from './views/productCase.vue'
import ProductCard from './views/productCard.vue'
import ProductCoop from './views/productCoop.vue'
import ProductSign from './views/productSign.vue'
import ProductfProcess from './views/productfProcess.vue'
import SolutionFirm from './views/solutionFirm.vue'
import SolutionTeam from './views/solutionTeam.vue'
import SolutionPre from './views/solutionPre.vue'
import ProductPrt from './views/productPrt.vue'
import ProductCli from './views/productCli.vue'
import ProductCalendar from './views/productCalendar.vue'
import ProductFinance from './views/productFinance.vue'
import ProductStatics from './views/productStatics.vue'
import ProductDoc from './views/productDoc.vue'
import ProductTool from './views/productTool.vue'
import ProductWeb from './views/productWeb.vue'
import Deployment from './views/deployment.vue'
import empty from './views/empty.vue'



Vue.use(Router)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/serve',
        name: 'serve',
        component: serve
    },
    {
        path: '/empty',
        name: 'empty',
        component: empty
    },
    {
        path: '/downApp',
        name: 'downApp',
        component: downApp
    },
    {
        path: '/mini-program',
        name: 'MiniProgram',
        component: MiniProgram
        // component: () => import('./views/Cases.vue')
    },
    {
        path: '/article',
        name: 'Article',
        component: Article
        // component: () => import('./views/Service.vue')
    },
    {
        path: '/qa',
        name: 'Qa',
        component: Qa
        // component: () => import('./views/Examples.vue')
    },
    {
        path: '/detail',
        name: 'Detail',
        component: Detail
        // component: () => import('./views/Detail.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
        // component: () => import('./views/Detail.vue')
    },
    {
        path: '/price',
        name: 'Price',
        component: Price
        // component: () => import('./views/Detail.vue')
    },
    {
        path: '/caseReport',
        name: 'CaseReport',
        component: CaseReport,
        meta: {
            title: '查看案件报告'
        }
        // component: () => import('./views/Detail.vue')
    },
    {
        path: '/reportGuide',
        name: 'ReportGuide',
        component: ReportGuide,
        meta: {
            title: '案件服务报告'
        }
        // component: () => import('./views/Detail.vue')
    },
    {
        path: '/reportResult',
        name: 'ReportResult',
        component: ReportResult,
        meta: {
            title: '案件报告'
        }
    },
    {
        path: '/product',
        name: 'Product',
        component: Product
    },
    {
        path: '/solution',
        name: 'Solution',
        component: Solution
    },
    {
        path: '/safe',
        name: 'Safe',
        component: Safe
    },
    {
        path: '/personal-web',
        name: 'PersonalWeb',
        component: PersonalWeb
    },
    {
        path: '/help',
        name: 'Help',
        component: Help
    },
    {
        path: '/help-list',
        name: 'HelpList',
        component: HelpList
    },
    {
        path: '/help-detail',
        name: 'HelpDetail',
        component: HelpDetail
    },
    {
        path: '/finance',
        name: 'Finance',
        component: Finance
    },
    {
        path: '/sign',
        name: 'Sign',
        component: Sign
    },
    {
        path: '/app-guide',
        name: 'AppGuide',
        component: AppGuide
    },
    // 产品 - 案件
    {
        path: '/product_case',
        name: 'productCase',
        component: ProductCase
    },
    // 产品 - 电子名片
    {
        path: '/product_card',
        name: 'productCard',
        component: ProductCard
    },
    // 产品 - 电子签
    {
        path: '/product_sign',
        name: 'productSign',
        component: ProductSign
    },
    // 产品 - 项目
    {
        path: '/product_prt',
        name: 'productPrt',
        component: ProductPrt
    },
    // 产品 - 客户
    {
        path: '/product_cli',
        name: 'productCli',
        component: ProductCli
    },
    // 产品 - 日程
    {
        path: '/product_calendar',
        name: 'productCalendar',
        component: ProductCalendar
    },
    // 产品 - 财务
    {
        path: '/product_finance',
        name: 'productFinance',
        component: ProductFinance
    },
    // 产品 - 统计
    {
        path: '/product_statics',
        name: 'productStatics',
        component: ProductStatics
    },
    // 产品 - 知识库
    {
        path: '/product_doc',
        name: 'productDoc',
        component: ProductDoc
    },
    // 产品 - 知识库
    {
        path: '/product_tool',
        name: 'productTool',
        component: ProductTool
    },
    // 产品 - 个人网站
    {
        path: '/product_web',
        name: 'productWeb',
        component: ProductWeb
    },
    // 私有化部署
    {
        path: '/deployment',
        name: 'deployment',
        component: Deployment
    },
    


    // 团队协作办案
    {
        path: '/product_coop',
        name: 'productCoop',
        component: ProductCoop
    },
    //  律所一体化办公
    {
        path: '/product_fProcess',
        name: 'productfProcess',
        component: ProductfProcess
    },
    // 律所管理
    {
        path: '/solution_firm',
        name: 'solutionFirm',
        component: SolutionFirm
    },
    // 律师/法务团队管理
    {
        path: '/solution_team',
        name: 'solutionTeam',
        component: SolutionTeam
    },
    // 个人业务管理
    {
        path: '/solution_pre',
        name: 'solutionPre',
        component: SolutionPre
    }
]
let router

router = new Router({
    routes,
    mode: 'history', //default: hash ,history
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    }
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = '案件云 - 法律人首选的案件管理云平台'
  }
  next();
})

export default router