<template>
   <div style="background: #fff;">
        <div class="item_wrap step1">
            <div class="item_imgbox">
                <img :src="item_img1" width="100%" />
            </div>
            <div class="item_btm">
                <div class="item_tit">
                有实力<br/>就要晒出来
                </div>
                <div class="item_txt">全面展示律师的领域专长、精彩案例以及律师日常的思考和价值输出，将律师个人的在线形象提升到一个新的水平，方便客户更全面地认识自己，建立信任。个人网站上还设有留言栏目，可以24小时接收潜在客户的咨询，将律师有限的服务时间无限延长，增加律师业务拓展的几率。
                </div>
                <div class="item_btn">
                <span @click="jumpGuide">极速创建</span>
                </div>
            </div>
        </div>
        <div class="item_wrap1">
            <div class="item_wrap1_tit">全方位形象展示</div>
            <swiper class="main_item_swiper" :options="swiperOption">   
                <swiper-slide class="main_item_slide" v-for="(item,index) in wrap_data" :key="index">
                    <div class="slide_item">
                        <img :src="item.img" />
                        <div class="main_item_titbox">
                            {{item.tit}}
                        </div>
                        <p class="txt">
                            {{item.txt}}
                        </p>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
        <div class="item_wrap2">
            <div class="item_wrap2_tit">多风格模板可选</div>
            <div class="item_wrap2_content">
                <div class="tit">
                    <span>时尚出彩风格</span>
                </div>
                <p class="txt">采用一屏式网站设计，风格独特，样式新颖，在视觉<br/>体验上带来耳目一新的感觉。</p>
                <div class="item">
                    <swiper class="main_item_swiper" :options="swiperOption1">   
                        <swiper-slide class="main_item_slide" v-for="(item,index) in web1_img" :key="index">
                            <div class="slide_item">
                                <img :src="top_img" width="100%">
                                <img :src="item" width="100%" />
                            </div>
                        </swiper-slide>
                        <div class="swiper-pagination swiper-pagination1" slot="pagination"></div>
                    </swiper>
                </div>
            </div>
            <div class="item_wrap2_content">
                <div class="tit">
                    <span>简约社交风格</span>
                </div>
                <p class="txt">左右结构样式设计，画面一目了然，清新<br/>简约，自成一派</p>
                <div class="item">
                    <swiper class="main_item_swiper" :options="swiperOption1">   
                        <swiper-slide class="main_item_slide" v-for="(item,index) in web2_img" :key="index">
                            <div class="slide_item">
                                <img :src="top_img" width="100%">
                                <img :src="item" width="100%" />
                            </div>
                        </swiper-slide>
                        <div class="swiper-pagination swiper-pagination1" slot="pagination"></div>
                    </swiper>
                </div>
            </div>
            <div class="item_wrap2_content">
                <div class="tit">
                    <span>沉静严谨风格</span>
                </div>
                <p class="txt">暗黑系列，黄色辅助，低调中不缺活力，严谨中带丝<br/>愉悦，采用居中式设计，聚焦客户的目光</p>
                <div class="item">
                    <swiper class="main_item_swiper" :options="swiperOption1">   
                        <swiper-slide class="main_item_slide" v-for="(item,index) in web3_img" :key="index">
                            <div class="slide_item">
                                <img :src="top_img" width="100%">
                                <img :src="item" width="100%" />
                            </div>
                        </swiper-slide>
                        <div class="swiper-pagination swiper-pagination1" slot="pagination"></div>
                    </swiper>
                </div>
            </div>
        </div>
        <div>
            <nowdownload />
        </div>
        <Footer />
   </div>
</template>

<script>
import nowdownload from '@/components/nowdownload/index'
import { Footer} from '@/components'
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/swiper.min.css";
function requireImg(str) {
    return require("pbimages/web/" + str);
}
export default {
    components:{
        nowdownload,
        Footer,
        swiper,
        swiperSlide
    },
    data(){
        return{
            item_img1:requireImg("bg.png"),
            top_img: requireImg("top.jpg"),
            wrap_data:[
                {tit:'个人名片',
                    txt:"PERSONAL BUSINESS CARD",
                    img:requireImg('top1.png'),
                },
                {tit:'精彩案例',
                    txt:"WONDERFUL CASE",
                    img:requireImg('top2.png'),
                },
                {tit:'律师文集',
                    txt:"LAWYES ANTHOLOGY",
                    img:requireImg('top3.png'),
                },
                {tit:'留言板',
                    txt:"MESSAGE BORAD",
                    img:requireImg('top4.png'),
                },   
            ],
            swiperOption: {
            　　pagination: {
                    el:'.swiper-pagination0',
                    clickable: true,
                },
            　　 slidesPerView: 1.5,
                spaceBetween: 12,
                loop: false,
            },
            swiperOption1: {
            　　pagination: {
                    el:'.swiper-pagination1',
                    clickable: true,
                },
            　　 slidesPerView: 1,
                spaceBetween: 40,
                loop: false,
            },
            web1_img:[requireImg('web1_1.jpg'),requireImg('web1_2.jpg'),requireImg('web1_3.jpg')],
            web2_img:[requireImg('web2_1.jpg'),requireImg('web2_2.jpg'),requireImg('web2_3.jpg')],
            web3_img:[requireImg('web3_1.jpg'),requireImg('web3_2.jpg'),requireImg('web3_3.jpg')],
        }
    },
    methods:{
        jumpGuide () {
            window.location = "https://oa.law086.com/web-activity";
            //this.$router.push({ name: 'downApp' })
        },
    }
    
}
</script>

<style lang="stylus" scoped>
.slide_item{
    border-radius: 10px;
    overflow: hidden;
}
    .item_wrap{
        &.step1{
            background: linear-gradient(90deg, #5575FE 0%, #4C6AFF 100%);
        }
        .item_imgbox{
        padding: 0 34px 50px;
        
        }
        .item_btm{
            //background: #F2F3F5;
            padding: 0 0 60px 0;
            .item_tit{
                font-size: 48px;
                font-weight: 800;
                color: #fff;
                line-height: 58px;
                text-align: center;
                
            }
            .item_txt{
                font-size: 26px;
                font-weight: 500;
                color: #fff;
                line-height: 46px;;
                padding: 39px 35px 40px;
            }
            .item_btn{
                display: flex;
                justify-content: center;
                span{
                    width: 228px;
                    height: 80px;
                    background: #FFFFFF;
                    border-radius: 10px;
                    color: #fff;
                    font-size: 30px;
                    font-weight: bold;
                    color: #4D6BFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .item_wrap1{
        padding: 80px 35px 5px;
        overflow: hidden;
        &_tit{
            font-size: 48px;
            font-weight: bold;
            color: #303443;
            text-align: center;
            padding: 0 0 60px 0;
        }
        .slide_item{
            background: #FFFFFF;
            box-shadow: 0px 0px 8px 0px rgba(198, 204, 221, 0.45);
            border-radius: 1;
            padding: 45px 0 60px;
            text-align: center;
            border-radius: 10px;
            img{
                width: 160px;
            }
            .main_item_titbox{
                font-size: 34px;
                font-weight: bold;
                color: #303443;
                position relative;
                padding: 40px 0 48px 0;
                margin: 0 0 38px 0;
                &:after{
                    width: 46px;
                    height: 6px;
                    background: #FABA4A;
                    content: "";
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    margin-left: -23px;
                }       
            }
            .txt{
                font-size: 26px;
                font-weight: 500;
                color: #7E818F;
                text-align: center;
            }

        }
    }
    .item_wrap2{
        &_tit{
            font-size: 48px;
            font-weight: bold;
            color: #303443;
            text-align: center;
            padding: 80px 0;
        }
        &_content{
            margin: 0 0 100px 0;
            .tit{
                font-size: 34px;
                font-weight: bold;
                color: #303443;
                text-align: center;
                span{
                    position relative;
                    &:after{
                        width: 32px;
                        height: 32px;
                        border: 4px solid #FFE400;
                        border-radius: 10px;
                        content: "";
                        display: inline-block;
                        box-sizing: border-box;
                        transform: rotate(-35deg);
                        position: absolute;
                        top: -21px;
                        right: -40px;
                    }
                    
                }
            }
            .txt{
                font-size: 26px;
                font-weight: 500;
                color: #7E818F;
                line-height: 35px;
                text-align: center;
                padding: 30px 0 35px;
            }
            .item{
                background-color: #6283FF;
                padding: 40px 45px 28px;
                img{
                    display: block;
                }
            }
        }
        .main_item_swiper{
            // width: 100%;
            overflow: hidden;
            position relative;
            .swiper-pagination{
                text-align: center;
                line-height: 12px;
            }
            /deep/ .swiper-pagination-bullet {
                display: inline-block;
                width: 20px;
                height: 2px;
                background: #ACBEFF;
                bottom: 13px;
                margin:0 5px;
                opacity: 1;
            }
            /deep/ .swiper-pagination-bullet-active {
                background: #FFFFFF;
            };
        }
    }
    .ptp50{
        padding-top: 50px;
    }
</style>