<template>
    <div class="cld_header" v-if="headershow">
        <div class="header">
            <div class="logo">
                <router-link :to="{name:'Home'}">
                    <img :src="logoURl" />
                </router-link>
            </div>
        </div>
        <div class="menu_box">
            <div class="menu" :class="{menu_active:sidebarshow}" @click="sidebarshow = !sidebarshow">
                <div class="line line1"></div>
                <div class="line line2"></div>
                <div class="line line3"></div>
            </div>
        </div>
        <transition name="level1-slide">
            <div class="header_sidebar" v-if="sidebarshow">
                <div class="header_sidebar_main">
                    <div class="slide_lt">
                        <dl class="slide_lt_list">
                            <dt @click="sidebarshow = false">
                                <router-link :to="{name:'Home'}">
                                    <img :src="logoURl" />
                                </router-link>
                            </dt>
                            <dd v-for="(item,index) in catlist" 
                                :key="index"
                                @click="chackcatFu(item)"
                                :class="{active:cat_active == item.value}" >
                                {{item.name}}
                            </dd>
                        </dl>
                    </div>
                    <div class="slide_rt" v-if="rt_data">
                        <div class="rt_item" v-for="(item,index) in rt_data" :key="index" @click="childcatFu(item)">
                            <p>{{item.name}}<i class="iconfont icon1 icon-youjiantou" :class="{active:child_active==item.value}"></i></p>
                            <transition name="slide-down" v-if="child_active==item.value">
                                <div class="txt_box">
                                    <a v-for="(it,ind) in item.child" :key="ind" @click="routeFu(it)">{{it.name}}</a>
                                </div>
                            </transition>  
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
function requireImg(str) {
    return require("pbimages/home/" + str);
}
export default {
    data(){
        return{
            logoURl:requireImg('logo.svg'),
            catlist:[
                {name:'产品',value:'product',
                    data:[
                        {name:'业务管理',value:'ywgl',
                            child:[
                                {name:'案件',value:'product_case'},
                                {name:'项目',value:'product_prt'},
                                {name:'客户',value:'product_cli'},
                                {name:'日程',value:'product_calendar'},
                                {name:'财务',value:'product_finance'},
                                {name:'统计',value:'product_statics'},
                                {name:'知识库',value:'product_doc'},
                                {name:'工具',value:'product_tool'},
                                {name:'电子名片',value:'product_card'},
                                {name:'个人网站',value:'product_web'},
                                {name:'电子签',value:'product_sign'},

                            ]
                        },
                        {name:'协同办公',value:'xtbg',
                            child:[
                                {name:'团队协作办案',value:'product_coop'},
                                {name:'律所一体化办公',value:'product_fProcess'},
                                {name:'企业服务方案',value:'deployment'},

                            ]
                        },
                        {name:'移动办公',value:'ydbg',
                            child:[
                                {name:'App下载',value:'downApp'},
                                {name:'微信小程序',value:'mini-program'},
                            ]
                        },
                    ]
                }, 
                {name:'解决方案',value:'solution',
                    data:[
                        {name:'标准解决方案',value:'bzjj',
                            child:[
                                {name:'个人业务管理',value:'solution_pre'},
                                {name:'律师/法务团队管理',value:'solution_team'},
                                {name:'律所管理',value:'solution_firm'},

                            ]
                        },
                        {name:'企业法务解决方案',value:'qyfw',
                            child:[
                                {name:'企业服务方案',value:'deployment'},
                            ]
                        }
                    ]
                },
                {name:'企业服务方案',value:'deployment'},
                {name:'定价',value:'price'},
                {name:'帮助中心',value:'help'},
                {name:'我们',value:'qa'},
            ],
            cat_active:'product',
            sidebarshow: false,
            rt_data:[],
            child_active:'',
            headershow: true,
        }
    },
    created(){
        this.hidedemFu(this.$route);
    },
    mounted(){
        this.rt_data = this.catlist[0].data;
    },
    methods:{
        chackcatFu(item){
            this.cat_active = item.value;
            this.child_active == '';
            this.rt_data = [];
            if(item.data){
                this.rt_data = item.data;
            }else{
                this.$router.push({path:item.value});
                this.sidebarshow = false;
            };
        },
        childcatFu(item){
            this.child_active == item.value ? this.child_active = '' : this.child_active = item.value;
        },
        routeFu(item){
            this.$router.push({path:item.value});
            this.sidebarshow = false;
        },
        hidedemFu(val){
            let arr = ["downApp"]
            if(arr.indexOf(val.name) !== -1){
                this.headershow = false;
            }else{
                this.headershow = true;
            }
        }

    },
    watch:{
        $route(val){
            this.hidedemFu(val);
        }
    }
}
</script>

<style lang='stylus' scoped>
.level1-slide-enter,
.level1-slide-leave-to {
  transform: translate(-100%, 0);
}
.slide-down-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-down-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-down-enter-to,
.slide-down-leave {
  max-height: 5000px;
  overflow: hidden;
}

.slide-down-enter,
.slide-down-leave-to {
  overflow: hidden;
  max-height: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.slide-bottom-enter,
.slide-bottom-leave-to {
  transform: translate(0, 100%);
}

.slide-left-enter,
.slide-left-leave-to {
  transform: translate(-100%, 0);
}
    .cld_header{
        width: 100%;
        height: 100px;
        position: relative;
        .header{
            height: 100px;
            display: flex;
            padding: 0 30px;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            background: #fff;
            border-bottom: 1px solid #dfdfdf;
            position: relative;
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1000;
            box-sizing: border-box;
            .logo{
                img{
                    height: 49px;
                    display: block;
                }
            }
        }
        .menu_box{
            height: 100px;
            display: flex;
            align-items: center;
            padding: 0 30px 0 0;
            position: fixed;
            right: 0;
            z-index 2003
            .menu{
                width: 32px;
                position: relative;
                display: flex;
                flex-direction: column;
                .line{
                    width: 32px;
                    height: 3px;
                    background: #3b3e40;
                    display: block;
                    margin: 6px auto 0;
                    -webkit-transition: all .3s ease-in-out;
                    transition: all .3s ease-in-out;
                    border-radius: 2px;
                }
                &.menu_active{
                    .line{
                        &.line1 {
                            -webkit-transform: translateY(8.9px) rotate(45deg);
                            transform: translateY(8.9px) rotate(45deg);
                        }
                        &.line2{
                            opacity 0;
                        }
                        &.line3{
                            -webkit-transform: translateY(-8.9px) rotate(-45deg);
                            transform: translateY(-8.9px) rotate(-45deg);
                        }
                    }
                }
            }
        }
        .header_sidebar{
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            font-size: 14px;
            color: #616265;
            -webkit-transition: all .3s;
            transition: all .3s;
            z-index: 1001;
            overflow-y: auto;
            .header_sidebar_main{
                display: flex;
                position relative;
                height: 100%;
                background: rgb(242, 248, 255);
                .slide_lt{
                    width: 264px;
                    background: #fff;
                    .slide_lt_list{
                        position: relative;
                        z-index:1;
                        dt{
                            display:flex;
                            align-items: center;
                            padding: 0 0 0 30px;
                            height: 100px;
                            margin-bottom: 70px;
                            img{
                                display: block;
                                margin: -2px 0 0;
                                height: 49px;
                            }
                        }
                        dd{
                            justify-content: center;
                            width: 100%;
                            height: 106px;
                            font-size: 30px;
                            color: #303443;
                            display: flex;
                            align-items: center;
                            &.active{
                                background: #FABA4A;
                                color: #fff;
                            }
                        }
                    }
                }
                &:after{
                    content: "";
                    width:100%;
                    height:100%;
                    position: absolute;
                    left:0;
                    top:0;
                    background: inherit;
                    filter: blur(2px);
                    z-index: -1;
                }
                .slide_rt{
                    flex: 1;
                    padding: 143px 38px 0 50px;
                    .rt_item{
                        margin: 0 0 80px 0;
                        &:first-child{
                            margin-top: 40px;
                        }
                        >p{
                            display: flex;
                            width: 100%;
                            justify-content: space-between;
                            font-size: 26px;
                            padding: 0 0 20px 0;
                            border-bottom: 1px solid #D7DAE5;
                            .icon1{
                                color: #A6A9B6;
                                font-size: 12px;
                                display: inline-block;
                                transition: all .2s;
                                &.active{
                                    transform: rotate(90deg);
                                    color: #303443;
                                }
                            }
                        }
                        .txt_box{
                            margin: 20px 0 0;
                            a{
                                font-size: 26px;
                                font-family: PingFang SC;
                                font-weight: 500;
                                color: #7E818F;
                                height: 75px;
                                display: flex;
                                align-items: center;
                                padding: 0 20px;
                                &.active{
                                    background: #FABA4A;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>