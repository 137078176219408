<template>
<div class="safe-wrap">
    <div class="common-banner safe-banner">
        <p class="common-banner__chinese">安全介绍</p>
        <p class="common-banner__english">Safety introduction</p>
    </div>
    <div class="safe-item" style="background: #fff;">
        <div class="safe-title">数据存储</div>
        <div class="safe-line"></div>
        <div class="safe-txt">案件云系saas平台，并在服务器端设置了周密的实时备份方案，确保数据不会丢失或缺失。</div>
        <img class="safe-img1" src="../../public/images/safe/safe01.png" alt="">
    </div>
    <div class="safe-item">
        <div class="safe-title text-right">数据传输</div>
        <div class="clearfix">
            <div class="safe-line fr"></div>
        </div>
        <div class="safe-txt text-right">案件云整体部署了银行级的 SSL加密访问功能，保证了数据在网路传输的安全。</div>
        <img class="safe-img2" src="../../public/images/safe/safe02.png" alt="">
    </div>
    <div class="safe-item" style="background: #fff;">
        <div class="safe-title">数据保密</div>
        <div class="safe-line"></div>
        <div class="safe-txt">案件云为纯互联网产品，独立的开发 与运营，不依托任何法律平台进行任何经营性活动。</div>
        <img class="safe-img3" src="../../public/images/safe/safe03.png" alt="">
    </div>
    <div class="safe-item">
        <div class="safe-title text-right">内部管理</div>
        <div class="clearfix">
            <div class="safe-line fr"></div>
        </div>
        <div class="safe-txt text-right">一方面，案件云后台为全封闭式管理，员工均无法通过后台得知用户录入的业务数据；另一方面，案件云团队制定了严格的员工保密制度，禁止与团队外任何人交流关于用户的相关信息，包括但不限于从日常与用户的交流、用户咨询等活动中获取的信息。</div>
        <img class="safe-img4" src="../../public/images/safe/safe04.png" alt="">
    </div>
    <div style="background: #fff;">
        <JumpForm />
    </div>
    <Footer/>
</div>
</template>

<script>
import { JumpForm } from '@/components'
import { Footer } from '@/components'
export default {
    name: 'safe',
    components: {
        JumpForm,
        Footer
    },
    data () {
        return {
            
        }
    },
    mounted () {

    },
    methods: {
        
    }
}
</script>
<style lang="stylus">
.safe-wrap{
    .jump-form{
        background: #fff;
    }
}
</style>


