<template>
    <div class="finance">
        <div class="finance-top" :class=" tabIndex == 0 ?  'top_height' :   ''  ">
            <div class="top-tab">
                <div class="tab-item" :class="tabIndex == item.value ? 'tab-active' : ''" v-for="(item, index) in tabItems" :key="index" @click="tabChange(item.value)">{{item.text}}</div>
            </div>
            <div class="top-content" :class=" tabIndex == 0 ?  'new_height' :   ''  ">
                <canvas class="chart-item" :class="tabIndex == 0 ? 'chart-active' : ''" id="myChart1" width="345" height="300"></canvas>
                <canvas class="chart-item" :class="tabIndex == 1 ? 'chart-active' : ''" id="myChart2" width="345" height="162"></canvas>
                <canvas class="chart-item" :class="tabIndex == 2 ? 'chart-active' : ''" id="myChart3" width="345" height="162"></canvas>
                <canvas class="chart-item" :class="tabIndex == 3 ? 'chart-active' : ''" id="myChart4" width="345" height="162"></canvas>
            </div>
        </div>
        <div class="finance-bottom">

            <div class="fee-chart-info" v-if="pageFrom == 'firmFinance'">
                <div class="chart-item">
                    <div class="chart-icon"><i class="iconfont icon-chengben"></i></div>
                    <div class="type">
                        <div class="name">合同总金额</div>
                    </div>
                    <div class="num"><span class="money-icon">￥</span>{{staticData.contract_amount }}</div>
                </div>
                <div class="chart-item">
                    <div class="chart-icon"><i class="iconfont icon-yishoukuan"></i></div>
                    <div class="type">
                        <div class="name">已收金额</div>
                    </div>
                    <div class="num"><span class="money-icon">￥</span>{{staticData.reciept_amount }}</div>
                </div>
                <div class="chart-item">
                    <div class="chart-icon"><i class="iconfont icon-daifukuan"></i></div>
                    <div class="type">
                        <div class="name">待收金额</div>
                    </div>
                    <div class="num"><span class="money-icon">￥</span>{{staticData.pre_reciept_amount }}</div>
                </div>
                <div class="chart-item">
                    <div class="chart-icon"><i class="iconfont icon-chengben"></i></div>
                    <div class="type">
                        <div class="name">已收待确认</div>
                    </div>
                    <div class="num"><span class="money-icon">￥</span>{{staticData.reciept_amount_dqr }}</div>
                </div>
                <div class="chart-item">
                    <div class="chart-icon"><i class="iconfont icon-kaipiaotongji"></i></div>
                    <div class="type">
                        <div class="name">开票金额</div>
                    </div>
                    <div class="num"><span class="money-icon">￥</span>{{staticData.bill_amount }}</div>
                </div>
                <div class="chart-item">
                    <div class="chart-icon"><i class="iconfont icon-chengben"></i></div>
                    <div class="type">
                        <div class="name">总支出</div>
                        <div class="explain">报销 + 付款</div>
                    </div>
                    <div class="num"><span class="money-icon">￥</span>{{staticData.expense_amount }}</div>
                </div>
                <div class="chart-item">
                    <div class="chart-icon"><i class="iconfont icon-kehu5"></i></div>
                    <div class="type">
                        <div class="name">实际支出</div>
                        <div class="explain">已报销 + 已付款</div>
                    </div>
                    <div class="num"><span class="money-icon">￥</span>{{staticData.expense_amount_ybx }}</div>
                </div>
                <div class="chart-item">
                    <div class="chart-icon"><i class="iconfont icon-baoxiao"></i></div>
                    <div class="type">
                        <div class="name">待报销</div>
                    </div>
                    <div class="num"><span class="money-icon">￥</span>{{staticData.expense_amount_dbx }}</div>
                </div>
                <div class="chart-item">
                    <div class="chart-icon"><i class="iconfont icon-jine1"></i></div>
                    <div class="type">
                        <div class="name">总收益</div>
                        <div class="explain">合同总金额 - 总支出</div>
                    </div>
                    <div class="num"><span class="money-icon">￥</span>{{(staticData.contract_amount - staticData.expense_amount) }}</div>
                </div>
                <div class="chart-item">
                    <div class="chart-icon"><i class="iconfont icon-tubiao_ruzhang"></i></div>
                    <div class="type">
                        <div class="name">实际入账收益</div>
                        <div class="explain">已收金额 - 实际支出</div>
                    </div>
                    <div class="num"><span class="money-icon">￥</span>{{(staticData.reciept_amount - staticData.expense_amount) }}</div>
                </div>
            </div>

            <div class="fee-chart-info" v-else>
                <div class="chart-item">
                    <div class="chart-icon"><i class="iconfont icon-jine2"></i></div>
                    <div class="type">
                        <div class="name">应收总金额</div>
                    </div>
                    <div class="num"><span class="money-icon">￥</span>{{staticData.yd_ }}</div>
                </div>
                <div class="chart-item">
                    <div class="chart-icon"><i class="iconfont icon-daifukuan"></i></div>
                    <div class="type">
                        <div class="name">已收金额</div>
                    </div>
                    <div class="num"><span class="money-icon">￥</span>{{staticData.ys_ }}</div>
                </div>
                <div class="chart-item">
                    <div class="chart-icon"><i class="iconfont icon-kaipiaotongji"></i></div>
                    <div class="type">
                        <div class="name">待收金额</div>
                    </div>
                    <div class="num"><span class="money-icon">￥</span>{{staticData.ds_ }}</div>
                </div>
                <div class="chart-item">
                    <div class="chart-icon"><i class="iconfont icon-yishoukuan"></i></div>
                    <div class="type">
                        <div class="name">开票金额</div>
                    </div>
                    <div class="num"><span class="money-icon">￥</span>{{staticData.kp_ }}</div>
                </div>
                <div class="chart-item">
                    <div class="chart-icon"><i class="iconfont icon-chengben"></i></div>
                    <div class="type">
                        <div class="name">总支出</div>
                    </div>
                    <div class="num"><span class="money-icon">￥</span>{{staticData.zcb }}</div>
                </div>
                <div class="chart-item">
                    <div class="chart-icon"><i class="iconfont icon-kehu5"></i></div>
                    <div class="type">
                        <div class="name">客户承担</div>
                    </div>
                    <div class="num"><span class="money-icon">￥</span>{{staticData.kgcd }}</div>
                </div>
                <div class="chart-item">
                    <div class="chart-icon"><i class="iconfont icon-baoxiao"></i></div>
                    <div class="type">
                        <div class="name">待报销</div>
                    </div>
                    <div class="num"><span class="money-icon">￥</span>{{staticData.dbx }}</div>
                </div>
                <div class="chart-item">
                    <div class="chart-icon"><i class="iconfont icon-jine1"></i></div>
                    <div class="type">
                        <div class="name">实际成本</div>
                        <div class="explain">非客户承担</div>
                    </div>
                    <div class="num"><span class="money-icon">￥</span>{{staticData.sjcb }}</div>
                </div>
                <div class="chart-item">
                    <div class="chart-icon"><i class="iconfont icon-fuwushouyi"></i></div>
                    <div class="type">
                        <div class="name">服务收益</div>
                        <div class="explain">约定金额 – 实际成本</div>
                    </div>
                    <div class="num"><span class="money-icon">￥</span>{{staticData.fwsy }}</div>
                </div>
                <div class="chart-item">
                    <div class="chart-icon"><i class="iconfont icon-tubiao_ruzhang"></i></div>
                    <div class="type">
                        <div class="name">入账收益</div>
                        <div class="explain">已收金额 – 实际成本 + 已报销</div>
                    </div>
                    <div class="num"><span class="money-icon">￥</span>{{staticData.rzsy }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { img_url } from '@/common/config/index'
const F2 = require('@antv/f2/lib/index-all')
export default {
    name: 'finance',
    components: {},
    data() {
        return {
            select_org_id: 0,
            type: '',
            pageFrom: '',
            linkid: '',
            linktype: 1,
            urlToken: '',
            tabIndex: 0,
            tabItems: [
                {
                    text: '成本分布',
                    value: 0,
                },
                {
                    text: '成本情况',
                    value: 1,
                },
                {
                    text: '收益分布',
                    value: 2,
                },
                {
                    text: '收益情况',
                    value: 3,
                },
            ],
            staticData: {},
        }
    },
    onShow() {},
    mounted() {
        this.urlToken = this.$route.query.token
        this.pageFrom = this.$route.query.pageFrom
        switch (this.pageFrom) {
            // 具体案件项目客户详情页中的财务概览数据
            case 'sourceFinance':
                this.linktype = this.$route.query.linktype
                this.linkid = this.$route.query.linkid
                this.getData()
                break;
            // 个人或者当前组织全部案件、项目、客户中的财务数据统计
            case 'allSourceFinance':
                this.uid = this.$route.query.uid
                this.select_org_id = this.$route.query.select_org_id
                this.finance()
                break;
            // 汇总申请与审批中的财务数据
            case 'firmFinance':
                this.select_org_id = this.$route.query.select_org_id
                this.getFirmData()
                break;
            default:
                this.$dialog.open(
                    '您的app版本太低，请升级到最新版查看'
                )
                break;
        }
    },
    methods: {
        tabChange(index) {
            this.tabIndex = index
            this.drawCanvas(this.staticData)
        },
        // 汇总申请与审批中的财务数据
        getFirmData() {
            let params = {
                select_org_id: this.select_org_id
            }
            axios({
                methods: 'get',
                url: `${img_url}api/firmfinance`,
                headers: { 'x-wps-weboffice-token': this.urlToken },
                params: params,
            })
                .then(({ data }) => {
                    if (data.code == 0) {
                        this.staticData = data.data
                        this.drawCanvas(data.data)
                    } else {
                        this.$dialog.open(data.msg)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        let resError = error.response
                        // let resMsg = resError.data.msg
                        if (resError.status === 429) {
                            this.$dialog.open(
                                '您的操作过于频繁，我都忙不过来了哟！'
                            )
                        } else {
                            this.$dialog.open('网络请求异常')
                        }
                    }
                })
        },
        // 具体案件项目客户详情页中的财务概览数据
        getData() {
            let params = {
                linkid: this.linkid,
                linktype: this.linktype,
            }
            axios({
                methods: 'get',
                url: `${img_url}api/finance`,
                headers: { 'x-wps-weboffice-token': this.urlToken },
                params: params,
            })
                .then(({ data }) => {
                    if (data.code == 0) {
                        this.staticData = data.data
                        this.drawCanvas(data.data)
                    } else {
                        this.$dialog.open(data.msg)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        let resError = error.response
                        // let resMsg = resError.data.msg
                        if (resError.status === 429) {
                            this.$dialog.open(
                                '您的操作过于频繁，我都忙不过来了哟！'
                            )
                        } else {
                            this.$dialog.open('网络请求异常')
                        }
                    }
                })
        },
        // 个人或者当前组织全部案件、项目、客户中的财务数据统计
        finance() {
            let params = {
                select_org_id: this.select_org_id,
                huser: this.uid
            }
            axios({
                methods: 'get',
                url: `${img_url}api/analytics/v3/finance`,
                headers: { 'x-wps-weboffice-token': this.urlToken },
                params: params,
            })
                .then(({ data }) => {
                    console.log('返回回调结果', data)
                    if (data.code == 0) {
                        this.staticData = data.data
                        this.drawCanvas(this.staticData)
                    } else {
                        this.$dialog.open(data.msg)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log('回调异常')
                        let resError = error.response
                        // let resMsg = resError.data.msg
                        if (resError.status === 429) {
                            this.$dialog.open(
                                '您的操作过于频繁，我都忙不过来了哟！'
                            )
                        } else {
                            this.$dialog.open('网络请求异常')
                        }
                    }
                })
        },
        drawChart1(val) {
            let total = 0
            let data = []
            let map = {}
            if (this.pageFrom == 'allSourceFinance') {
                data = val.zc_fb
                data.forEach((item) => {
                    item.a = '1'
                    item.num = item.amount
                    total += item.amount
                })
                data.map(function (obj) {
                    map[obj.name] =
                        parseFloat(((obj.amount / total) * 100).toFixed(2)) +
                        '%'
                })
            } else {
                if (this.pageFrom == 'firmFinance') {
                    for (let i in val.zc_fb) {
                        let obj1 = {}
                        obj1.name = i
                        obj1.num = parseInt(val.zc_fb[i])
                        obj1.percent = obj1.num
                        total = total + obj1.num
                        obj1.a = '1'
                        data.push(obj1)
                    }
                } else {
                    for (let i in val.cb_fb) {
                        let obj1 = {}
                        obj1.name = i
                        obj1.num = parseInt(val.cb_fb[i])
                        obj1.percent = obj1.num
                        total = total + obj1.num
                        obj1.a = '1'
                        data.push(obj1)
                    }
                }
                data.map(function (obj) {
                    map[obj.name] =
                        parseFloat(((obj.num / total) * 100).toFixed(2)) + '%'
                })
            }
            let chart = new F2.Chart({
                id: 'myChart1',
                pixelRatio: window.devicePixelRatio,
                padding: [10, 'auto'],
            })
            chart.source(data, {
                num: {
                    formatter: function formatter(val) {
                        return val + '%'
                    },
                },
            })
            chart.tooltip(false)
            chart.legend({
                position: 'left',
                clickable: false,
                nameStyle: {
                    fill: '#303443',
                },
                itemFormatter: function itemFormatter(val) {
                    return val + ' ' + map[val]
                },
            })
            chart.coord('polar', {
                transposed: true,
                innerRadius: 0.7,
                radius: 0.85,
            })
            chart.axis(false)
            chart
                .interval()
                .position('a*num')
                .color('name', [
                    '#B49FF8',
                    '#FC9283',
                    '#FFB384',
                    '#A1DDAA',
                    '#FFD277',
                    '#81CCF7',
                ])
                .adjust('stack')
            chart.render()
        },
        drawChart2(val) {
            let data = []
            let total = 0
            let map = {}

            if (this.pageFrom == 'allSourceFinance') {
                data = val.cbcd_fb
                data.forEach((item) => {
                    item.a = '1'
                    item.num = item.amount
                    total += item.amount
                })
                data.map(function (obj) {
                    map[obj.name] =
                        parseFloat(((obj.amount / total) * 100).toFixed(2)) +
                        '%'
                })
            } else {
                if (this.pageFrom == 'firmFinance') {
                    for (let i in val.sk_fb) {
                        let obj1 = {}
                        obj1.name = i
                        obj1.num = parseInt(val.sk_fb[i])
                        total = total + obj1.num
                        obj1.a = '1'
                        data.push(obj1)
                    }
                } else {
                    for (let i in val.cb_qk) {
                        let obj1 = {}
                        obj1.name = i
                        obj1.num = parseInt(val.cb_qk[i])
                        total = total + obj1.num
                        obj1.a = '1'
                        data.push(obj1)
                    }
                }
                data.map(function (obj) {
                    map[obj.name] =
                        parseFloat(((obj.num / total) * 100).toFixed(2)) + '%'
                })
            }
            let chart = new F2.Chart({
                id: 'myChart2',
                pixelRatio: window.devicePixelRatio,
                padding: [10, 'auto'],
            })
            chart.source(data, {
                num: {
                    formatter: function formatter(val) {
                        return val + '%'
                    },
                },
            })
            chart.tooltip(false)
            chart.legend({
                position: 'right',
                clickable: false,
                nameStyle: {
                    fill: '#303443',
                },
                itemFormatter: function itemFormatter(val) {
                    return val + ' ' + map[val]
                },
            })
            chart.coord('polar', {
                transposed: true,
                innerRadius: 0.7,
                radius: 0.85,
            })
            chart.axis(false)
            chart
                .interval()
                .position('a*num')
                .color('name', [
                    '#B49FF8',
                    '#FC9283',
                    '#FFB384',
                    '#A1DDAA',
                    '#FFD277',
                    '#81CCF7',
                ])
                .adjust('stack')
            chart.render()
        },
        drawChart3(val) {
            if (this.pageFrom == 'allSourceFinance') {
                let data = val.sy_fb
                let total = 0
                data.forEach((item) => {
                    item.a = '1'
                    total += item.r_amount
                })
                let map = {}
                data.map(function (obj) {
                    map[obj.name] =
                        parseFloat(((obj.r_amount / total) * 100).toFixed(2)) +
                        '%'
                })

                let chart = new F2.Chart({
                    id: 'myChart3',
                    pixelRatio: window.devicePixelRatio,
                    padding: ['auto'],
                })
                chart.source(data, {})
                chart.tooltip(false)

                chart.legend({
                    position: 'right',
                    clickable: false,
                    nameStyle: {
                        fill: '#303443',
                    },
                    itemFormatter: function itemFormatter(val) {
                        return val + ' ' + map[val]
                    },
                })
                chart.coord('polar', {
                    transposed: true,
                    innerRadius: 0.7,
                    radius: 0.85,
                })
                chart.axis(false)
                chart
                    .interval()
                    .position('a*r_amount')
                    .color('name', [
                        '#B49FF8',
                        '#FC9283',
                        '#FFB384',
                        '#A1DDAA',
                        '#FFD277',
                        '#81CCF7',
                    ])
                    .adjust('stack')
                chart.render()
            } else {
                if (this.pageFrom == 'firmFinance') {
                    let data = val.expense_qs.map((item) => {
                        item.value = parseFloat(item.amount)
                        item.name = item.month
                        return item
                    })
                    let chart = new F2.Chart({
                        id: 'myChart3',
                        pixelRatio: window.devicePixelRatio,
                        padding: ['auto'],
                    })
                    chart.source(data, {})
                    chart.tooltip({
                        triggerOn: ['touchstart', 'touchmove', 'tap', 'click'],
                        triggerOff: 'false',
                        showCrosshairs: true,
                        showItemMarker: false,
                        onShow: function onShow(ev) {
                            var items = ev.items
                            items[0].name = null
                            items[0].value = items[0].value
                        },
                    })
                    chart.axis('name', {
                        label: function label(text, index, total) {
                            var textCfg = {}
                            if (index === 0) {
                                textCfg.textAlign = 'left'
                            } else if (index === total - 1) {
                                textCfg.textAlign = 'right'
                            }
                            return textCfg
                        },
                    })
                    chart.line().position('name*value')
                    chart.point().position('name*value').style({
                        stroke: '#fff',
                        lineWidth: 1,
                    })
                    chart.render()
                } else {
                    let data = []
                    let total = 0
                    for (let i in val.sy_fb) {
                        let obj1 = {}
                        obj1.name = i
                        obj1.num = parseInt(val.sy_fb[i])
                        total = total + obj1.num
                        obj1.a = '1'
                        data.push(obj1)
                    }
                    let map = {}
                    data.map(function (obj) {
                        map[obj.name] =
                            parseFloat(((obj.num / total) * 100).toFixed(2)) +
                            '%'
                    })
                    let chart = new F2.Chart({
                        id: 'myChart3',
                        pixelRatio: window.devicePixelRatio,
                        padding: [10, 'auto'],
                    })
                    chart.source(data, {
                        num1: {
                            formatter: function formatter(val) {
                                return val + '%'
                            },
                        },
                    })
                    chart.tooltip(false)
                    chart.legend({
                        position: 'right',
                        clickable: false,
                        nameStyle: {
                            fill: '#303443',
                        },
                        itemFormatter: function itemFormatter(val) {
                            return val + ' ' + map[val]
                        },
                    })
                    chart.coord('polar', {
                        transposed: true,
                        innerRadius: 0.7,
                        radius: 0.85,
                    })
                    chart.axis(false)
                    chart
                        .interval()
                        .position('a*num')
                        .color('name', [
                            '#B49FF8',
                            '#FC9283',
                            '#FFB384',
                            '#A1DDAA',
                            '#FFD277',
                            '#81CCF7',
                        ])
                        .adjust('stack')
                    chart.render()
                }
            }
        },
        drawChart4(val) {
            if (this.pageFrom == 'firmFinance') {
                let data = val.firmreciept_qs.map((item) => {
                    item.value = parseFloat(item.amount)
                    item.name = item.month
                    return item
                })
                let chart = new F2.Chart({
                    id: 'myChart4',
                    pixelRatio: window.devicePixelRatio,
                    padding: ['auto'],
                })
                chart.source(data, {})
                chart.tooltip({
                    triggerOn: ['touchstart', 'touchmove', 'tap', 'click'],
                    triggerOff: 'false',
                    showCrosshairs: true,
                    showItemMarker: false,
                    onShow: function onShow(ev) {
                        var items = ev.items
                        items[0].name = null
                        items[0].value = items[0].value
                    },
                })
                chart.axis('name', {
                    label: function label(text, index, total) {
                        var textCfg = {}
                        if (index === 0) {
                            textCfg.textAlign = 'left'
                        } else if (index === total - 1) {
                            textCfg.textAlign = 'right'
                        }
                        return textCfg
                    },
                })
                chart.line().position('name*value')
                chart.point().position('name*value').style({
                    stroke: '#fff',
                    lineWidth: 1,
                })
                chart.render()
            } else {
                let data = [
                    {
                        name: '入账收益',
                        y: val.rzsy,
                    },
                    {
                        name: '待收金额',
                        y: val.ds_,
                    },
                    {
                        name: '待报销',
                        y: val.dbx,
                    },
                ]
                // let map = {};
                let chart = new F2.Chart({
                    id: 'myChart4',
                    pixelRatio: window.devicePixelRatio,
                    padding: ['auto'],
                })
                chart.source(data, {
                    num1: {
                        formatter: function formatter(val) {
                            return val + '%'
                        },
                    },
                })
                chart.tooltip({
                    showItemMarker: false,
                    triggerOn: ['touchstart', 'click'],
                    onShow: function onShow(ev) {
                        var items = ev.items
                        items[0].name = null
                        items[0].name = items[0].title
                        items[0].value = '¥ ' + items[0].value
                    },
                })
                chart.legend(false)
                chart.interval().position('name*y').color('name', ['#84A1F4'])
                chart.render()
            }
        },
        drawCanvas(val) {
            if (this.tabIndex == 0) {
                this.drawChart1(val)
            }
            if (this.tabIndex == 1) {
                this.drawChart2(val)
            }
            if (this.tabIndex == 2) {
                this.drawChart3(val)
            }
            if (this.tabIndex == 3) {
                this.drawChart4(val)
            }
        },
    },
}
</script>
<style lang="stylus"></style>