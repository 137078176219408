<template>
  <div class="jump-form">
    <h2>立即开通案件云，免费试用</h2>
    <p>无需下载部署，注册即用，最快处理案件的桥梁</p>
    <span class="jump-btn" @click="jumpGuide">开始免费使用</span>
  </div>
</template>

<script>
export default {
  name: 'JumpForm',
  props: {},
  data () {
    return {}

  },
  methods: {
    jumpGuide () {
      this.$router.push({name:'Contact'})
    }
  }

}
</script>

<style lang="stylus">
@require 'assets/stylus/color-varaibles';

.jump-form {
  text-align: center;
  padding 60px 0
  background-color: $color-light-grey-sss;

  h2 {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 28px;
    color: $color-bold-black;
  }

  p {
    font-size: 24px;
    margin-bottom: 50px;
    color: $color-primary-black;
  }

  .jump-btn {
      display inline-block
      font-size 30px
      color $color-white
      padding 15px 25px
      background-color $color-primary-gold
      border-radius 32px
  }
}
</style>
