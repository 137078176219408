<template>
  <div class="serve-content-txt">
    <div id="write" class="is-node">
      <h1>
        <a name="header-n0" class="md-header-anchor"></a
        ><strong>案件云服务协议、隐私政策</strong>
      </h1>
      <p>
        欢迎您签署本《案件云服务协议、隐私政策》（以下简称“本协议”）并使用案件云平台服务！为维护您自身权益，请您仔细阅读各条款，特别是免除或限制责任的条款、法律适用和争议解决条款。如您对协议有任何疑问，可向案件云平台客服咨询。
      </p>
      <p>
        当您按照注册页面填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您可随时停止注册程序。
      </p>
      <h2>
        <a name="header-n7" class="md-header-anchor"></a
        ><strong>一、协议的范围与变更</strong>
      </h2>
      <p style="margin-bottom:18px;text-align:left;line-height:30px;background:white"><span style=";font-family:'微软雅黑',sans-serif;">1.1</span><span style=";font-family:'微软雅黑',sans-serif;">本《案件云服务协议》（以下简称“本协议”）由您个人或您所代表的单位（以下统称“您”）与案件云签署。案件云归<strong style="font-weight:bold">上海律杏信息科技有限公司</strong>【注册地址：上海市崇明区城桥镇三沙洪路89号4幢919室(上海崇明供销经济开发区)，以下简称“律杏科技”】所有并由律杏科技负责运营。</span></p>
      <p>
        1.2本协议项下的服务是指案件云向用户提供的“案件云”网站、软件及服务（以下简称“本服务”），专为律师、律师团队、律所以及企业法务快速提升办案效率的在线协作平台。
      </p>
      <p>
        1.3本协议内容同时包括《隐私政策》,且您在使用某一特定服务时，该服务可能会另有单独的协议、相关业务规则等（以下统称为“单独协议”）。上述内容一经正式发布，即为本协议不可分割的组成部分，您同样应当遵守。您对前述任何业务规则、单独协议的接受，即视为您对本协议全部的接受。
      </p>
      <p>
        1.4律杏科技有权在必要时修改增加或删除本协议部分内容的权利，相应变更自通过互联网发布之时立即生效。如有争议，以最新条款为准。您可以在相关服务页面查阅最新版本的协议条款。
      </p>
      <p>
        1.5本协议条款变更后，如果您继续使用案件云提供的服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用案件云提供的服务。
      </p>
      <h2>
        <a name="header-n18" class="md-header-anchor"></a
        ><strong>二、案件云账号</strong>
      </h2>
      <p>
        2.1您在使用案件云的服务时可能需要注册一个账号。账号所有权属于案件云。
      </p>
      <p>
        2.2您在注册案件云账号过程中，需要填写一些必要的信息，请保持这些信息的及时更新，以便案件云向您提供帮助，或更好地为您提供服务。根据国家法律法规规定，您需要填写真实的身份信息。若您填写的信息不完整或不准确，则可能无法使用服务或在使用过程中受到限制。
      </p>
      <p>
        2.3案件云账号使用权仅属于初始申请注册人。未经案件云许可，您不得赠与、借用、租用、转让或售卖案件云账号或者以其他方式许可非初始申请注册人使用案件云账号。
      </p>
      <p>
        2.4您理解并同意，作为案件云账号的初始申请注册人和使用人，您应承担该号码项下所有活动产生的全部责任。
      </p>
      <p>
        2.5因案件云原因导致您的账号被盗，案件云将依法承担相应责任。非因案件云原因导致的，案件云不承担任何责任。
      </p>
      <p>
        2.6如您违反法律法规、案件云各服务协议或业务规则的规定，案件云有权进行独立判断并随时限制、冻结或终止您对案件云账号的使用，可能会给您造成一定的损失，该损失由您自行承担，案件云不承担任何责任，且根据实际情况决定是否恢复您对案件云账号使用。
      </p>
      <p>
        2.7您不得有偿或无偿转让案件云账号，以免产生纠纷。您应当自行承担由此产生的任何责任，同时案件云保留追究上述行为人法律责任的权利。
      </p>
      <p>
        2.8如果案件云发现您并非账号初始申请注册人，案件云有权在未经通知的情况下终止您使用该账号。
      </p>
      <p>
        2.9案件云特别提醒您应妥善保管您的帐号和密码。当您使用完毕后，应安全退出。因您保管不善可能导致遭受盗号或密码失窃，责任由您自行承担。
      </p>
      <h2>
        <a name="header-n37" class="md-header-anchor"></a
        ><strong>三、用户个人信息保护</strong>
      </h2>
      <p>
        3.1保护用户个人信息是案件云的一项基本原则。案件云将按照本协议及《隐私政策》的规定收集、使用、储存和分享您的个人信息。本协议对个人信息保护规定的内容与上述《隐私政策》有相冲突的，及本协议对个人信息保护相关内容未作明确规定的，均应以《隐私政策》的内容为准。
      </p>
      <p>
        3.2案件云将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。
      </p>
      <p>3.3案件云不会将您的个人信息转移或披露给任何非关联的第三方，除非：</p>
      <p>（1）相关法律法规或法院、政府机关要求；</p>
      <p>（2）为完成合并、分立、收购或资产转让而转移；</p>
      <p>（3）为提供您要求的服务所必需。</p>
      <h2>
        <a name="header-n50" class="md-header-anchor"></a
        ><strong>四、使用本服务的方式</strong>
      </h2>
      <p>
        4.1您应当通过案件云提供或认可的方式使用本服务。您依本协议条款所取得的权利不可转让。
      </p>
      <p>
        4.2您不得使用未经案件云授权的插件、外挂或第三方工具对本协议项下的服务进行干扰、破坏、修改或施加其他影响。
      </p>
      <p>
        4.3服务费用，案件云平台提供的服务包括收费服务和免费服务。其中，收费服务包括但不限于高阶及以上版本服务。对于收费服务，律杏科技会在用户使用之前给予明确的提示，只有用户根据提示确认相关内容、支付费用后，才可以使用相关收费服务。案件云拥有制定及调整服务费标准的权利，具体服务费用以您使用本服务时案件云上所列之收费标准公告或您与案件云或案件云上的服务提供方通过案件云达成的其他书面协议为准。
      </p>
      <h2>
        <a name="header-n57" class="md-header-anchor"></a
        ><strong>五、按现状提供服务</strong>
      </h2>
      <p>
        您理解并同意，案件云的服务是按照现有技术和条件所能达到的现状提供的。案件云会尽最大努力向您提供服务，确保服务的连贯性和安全性；但案件云不能随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。
      </p>
      <h2>
        <a name="header-n60" class="md-header-anchor"></a
        ><strong>六、自备设备</strong>
      </h2>
      <p>
        6.1您应当理解，您使用案件云的服务需自行准备与相关服务有关的终端设备（如电脑、调制解调器等装置），并承担所需的费用（如电话费、上网费等费用）。
      </p>
      <p>6.2您理解并同意，您使用本服务时会耗用您的终端设备和带宽等资源。</p>
      <h2>
        <a name="header-n65" class="md-header-anchor"></a
        ><strong>七、基于软件提供服务</strong>
      </h2>
      <p>案件云还将依托“软件”向您提供服务，您还应遵守以下约定：</p>
      <p>
        7.1您在使用本服务的过程中需要下载软件，对于这些软件，案件云给予您一项个人的、不可转让及非排他性的许可。您仅可为访问或使用本服务的目的而使用这些软件。
      </p>
      <p>
        7.2为了改善用户体验、保证服务的安全性及产品功能的一致性，案件云可能会对软件进行更新。您应该将相关软件更新到最新版本，否则案件云并不保证其能正常使用。
      </p>
      <p>
        7.3案件云可能为不同的终端设备开发不同的软件版本，您应当根据实际情况选择下载合适的版本进行安装。您可以直接从案件云的网站上获取软件，也可以从得到案件云授权的第三方获取。如果您从未经案件云授权的第三方获取软件或与软件名称相同的安装程序，案件云无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。
      </p>
      <p>7.4除非案件云书面许可，您不得从事下列任一行为：</p>
      <p>（1）删除软件及其副本上关于著作权的信息；</p>
      <p>
        （2）对软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现软件的源代码；
      </p>
      <p>
        （3）对案件云拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
      </p>
      <p>
        （4）乙方有权在甲方存在爬取、盗用案件云数据等不正常使用行为时，取消甲方用户权限，包括但不限于封停案件云账号，追究其法律责任等。
      </p>
      <p>
        （5）对软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经案件云授权的第三方工具/服务接入软件和相关系统；
      </p>
      <p>
        （6）通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
      </p>
      <p>
        （7）通过非案件云开发、授权的第三方软件、插件、外挂、系统，登录或使用案件云软件及服务，或制作、发布、传播非案件云开发、授权的第三方软件、插件、外挂、系统。
      </p>
      <p>7.5第三方产品和服务：</p>
      <p>
        （1）在使用案件云提供的第三方服务或产品时，需在案件云账户体系下使用，否则第三方服务与产品无法使用，涉及第三方费用将不予退还。
      </p>
      <p>
        （2）在使用案件云提供的第三方服务或产品时，除遵守本协议约定以外，还应遵守第三方的用户协议，对可能出现的任何争议，案件云和第三方将在法律规定和协议约定的范围内各自承担。
      </p>
      <p>（3）案件云提供的第三方服务或产品价格的解释权归案件云所有。</p>
      <h2>
        <a name="header-n98" class="md-header-anchor"></a
        ><strong>八、知识产权</strong>
      </h2>
      <p>
        8.1案件云在本服务中提供的全部内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权归案件云所有；不论您是否付费，对于案件云在本服务中提供的全部内容，您仅有使用权，非经案件云事先书面同意，您不得无偿或有偿授权任何他人擅自使用，也不得自行或擅自指令或允许他人公开传播、散布、发行或公开发表前述内容。
      </p>
      <p>
        8.2除非本协议另有规定，您在使用本服务过程中所创建的独创性数据归属您本人所有，您有权对前述数据进行任何形式的处置，包括从案件云复制、导出和删除。
      </p>
      <p>
        8.3除另有特别声明外，案件云提供本服务时所依托软件的著作权、专利权及其他知识产权均归律杏科技或相关权利人所有。
      </p>
      <p>
        8.4案件云在本服务中所使用的“案件云”及图形等商业标识，其著作权或商标权归案件云所有。未经律杏科技或相关权利人书面授权，任何组织、单位或个人不得出于盈利目的或者以恶意方式使用。
      </p>
      <p>
        8.5上述及其他任何本服务包含的内容的知识产权均受到法律保护，未经律杏科技事先书面许可，互联网任何主体不得使用机器人、蜘蛛、爬虫等其他自动设备或程序，或者手动程序复制、传播案件云平台包含的内容。一经发现，案件云平台有权立即对该用户终止服务，并保留追究相应法律责任的权利。
      </p>
      <h2>
        <a name="header-n109" class="md-header-anchor"></a
        ><strong>九、用户违法行为</strong>
      </h2>
      <p>
        9.1您在使用本服务时须遵守法律法规，不得利用本服务从事违法违规行为，包括但不限于：
      </p>
      <p>
        （1）发布、传送、传播、储存危害国家安全统一、破坏社会稳定、违反公序良俗、侮辱、诽谤、淫秽、暴力以及任何违反国家法律法规的内容；
      </p>
      <p>
        （2）发布、传送、传播、储存侵害他人知识产权、商业秘密等合法权利的内容；
      </p>
      <p>（3）恶意虚构事实、隐瞒真相以误导、欺骗他人；</p>
      <p>（4）发布、传送、传播广告信息及垃圾信息；</p>
      <p>（5）其他法律法规禁止的行为。</p>
      <p>
        9.2如果您违反了本条约定，相关国家机关或机构可能会对您提起诉讼、罚款或采取其他制裁措施，并要求案件云给予协助。造成损害的，您应依法予以赔偿，案件云不承担任何责任。
      </p>
      <p>
        9.3如果案件云发现或收到他人举报您发布的信息违反本条约定，案件云有权进行独立判断并采取技术手段予以删除、屏蔽或断开链接。同时，案件云有权视用户的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止案件云账号使用，追究法律责任等措施。
      </p>
      <p>
        9.4您违反本条约定，导致任何第三方损害的，您应当独立承担责任；案件云因此遭受损失的，您也应当一并赔偿。
      </p>
      <h2>
        <a name="header-n128" class="md-header-anchor"></a
        ><strong>十、不可抗力</strong>
      </h2>
      <p>
        您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，案件云将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失案件云在法律允许的范围内免责。
      </p>
      <h2>
        <a name="header-n131" class="md-header-anchor"></a
        ><strong>十一、免责声明</strong>
      </h2>
      <p>
        11.1
        案件云平台中提供的各种形式的资料仅供用户参考使用，用户使用时应自行甄别、比对、审核，律杏科技不对以上资料所引起的纠纷承担法律责任。
      </p>
      <p>
        11.2
        案件云平台需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，该检修或维护将造成平台网络服务（包括收费服务）的暂时中断，用户同意案件云平台有权随时变更、中断部分或全部的网络服务。
      </p>
      <p>
        如案件云平台终止全部服务（包括收费服务），律杏科技将根据具体情形在服务终止前的合理期限内通知用户，提醒用户转移个人信息和数据。一旦服务终止，用户使用本平台的权利立即终止，由于用户个人原因未在通知转移期限内转移个人信息和数据，其在平台中储存的个人信息和数据可能无法恢复，案件云平台不对此承担责任。
      </p>
      <p>
        11.3
        在案件云平台提供服务过程中，基于运维管理等需要，有可能对用户录入、上传、发布的内容进行迁移、备份等操作。在案件云平台遵守保密义务并采取适当保护措施的前提下，用户明确同意并授权案件云平台基于为用户提供服务的目的，迁移、备份前述内容及其涉及的数据。用户知晓并认可对前述内容及其涉及的任何数据进行迁移、备份的行为是案件云平台为用户提供服务所必须的，不构成对用户隐私资料、保密信息的任何侵犯。
      </p>
      <p>
        11.4在法律允许的范围内，案件云对以下情形导致的服务中断或受阻不承担责任：
      </p>
      <p>（1）受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；</p>
      <p>（2）用户或案件云的电脑软件、系统、硬件和通信线路出现故障；</p>
      <p>（3）用户操作不当；</p>
      <p>（4）用户通过非案件云授权的方式使用本服务；</p>
      <p>（5）其他案件云无法控制或合理预见的情形。</p>
      <p>
        11.5案件云提供的所有法律文件或知识内容均由专业律师准备和审阅，但案件云不是一家律师事务所，且案件云自身不提供法律服务，也不能替代律师事务所或律师的法律服务。任何用户不论是否付费使用案件云提供的所有法律文件或知识内容仅供参考，并非最终法律文件，不具有法律效力，用户应根据实际情况自行修改，案件云对此并不承担任何法律责任。
      </p>
      <p>
        11.6除本协议另有规定外，在任何情况下，案件云对本协议所承担的违约赔偿责任总额以案件云向您收取的当次服务费用（如有）为上限。
      </p>
      <h2>
        <a name="header-n156" class="md-header-anchor"></a
        ><strong>十二、管辖与法律适用</strong>
      </h2>
      <p>
        12.1本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
      </p>
      <p>12.2本协议签订地为中华人民共和国上海市。</p>
      <p>
        12.3若您和案件云之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。
      </p>
      <p>
        12.4本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
      </p>
      <p>
        12.5本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
      </p>
      <h2>
        <a name="header-n167" class="md-header-anchor"></a
        ><strong>十三、联系我们</strong>
      </h2>
     <p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left: 0;line-height:30px"><span style="font-size: 14px;font-family:'微软雅黑',sans-serif">如果您对本协议或本服务有意见或建议，可与案件云联系，我们会给予您必要的帮助。有任何意见、问题、建议、申诉或投诉，请通过官网右下方以及软件我的页面下方的在线客服（周一到周五9:00-18:30）与我们联系。我们的联系/邮寄方式如下：</span></p>
     <p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left: 0;line-height:30px"><span style="font-size: 14px;font-family:'微软雅黑',sans-serif">上海律杏信息科技有限公司&nbsp;&nbsp; 案件云运营团队</span></p>
     <p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left: 0;line-height:30px"><span style="font-size: 14px;font-family:'微软雅黑',sans-serif">地址： 上海市浦东新区陆家嘴环路1366号富士康大厦12层（邮编200120）</span></p>
     <p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left: 0;line-height:30px"><span style="font-size: 14px;font-family:'微软雅黑',sans-serif">电子邮箱： <a href="mailto:yunmeier@law086.com">yunmeier@law086.com</a></span></p>
     <p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left: 0;line-height:30px"><span style="font-size: 14px;font-family:'微软雅黑',sans-serif">（正文完）</span></p>
      <h2><a name="header-n170" class="md-header-anchor"></a>引言</h2>
      <p>
        案件云重视用户的隐私。您在使用我们的服务时，我们可能会收集和使用您的相关信息。我们希望通过本《隐私政策》向您说明，在使用我们的服务时，我们如何收集、使用、储存和分享这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。本《隐私政策》与您所使用的案件云服务息息相关，希望您仔细阅读，在需要时，按照本《隐私政策》的指引，作出您认为适当的选择。
        您使用或继续使用我们的服务，即意味着同意我们按照本《隐私政策》收集、使用、储存和分享您的相关信息。
      </p>
      <h2>
        <a name="header-n173" class="md-header-anchor"></a
        ><strong>一、我们可能收集的信息</strong>
      </h2>
      <p>（一）您提供的信息</p>
      <p>
        您在注册账户或使用我们的服务时，向我们提供的相关个人信息，例如电话号码、电子邮件等；
      </p>
      <p>
        您通过我们的服务向其他方提供的共享信息，以及您使用我们的服务时所储存的信息。
      </p>
      <p>（二）其他方分享的您的信息</p>
      <p>其他方使用我们的服务时所提供有关您的共享信息。</p>
      <p>（三）我们获取的您的信息</p>
      <p>您使用服务时我们可能收集如下信息：</p>
      <p>
        (1)&nbsp;设备或软件信息，例如您的移动设备、网页浏览器或用于接入我们服务的其他程序所提供的配置信息、您的IP地址和移动设备所用的版本和设备识别码；
      </p>
      <p>
        (2）在使用我们服务时搜索或浏览的信息，例如您使用的网页搜索词语、访问的页面url地址，以及您在使用我们服务时浏览或要求提供的其他信息和内容详情；
      </p>
      <p>
        (3）您通过我们的服务分享的内容所包含的信息（元数据），例如拍摄或上传的照片的日期、时间或地点等。
      </p>
      <p>
        (4）位置信息，指您开启设备定位功能并使用我们基于位置提供的相关服务时，收集的有关您位置的信息，包括：
      </p>
      <p>
        (5）您通过具有定位功能的移动设备使用我们的服务时，通过GPS或WiFi等方式收集的您的地理位置信息；
      </p>
      <p>
        (6）您或其他用户提供的包含您所处地理位置的实时信息，例如您提供的账户信息中包含的您所在地区信息，您或其他人上传的显示您当前或曾经所处地理位置的信息，您或其他人上传的照片包含的地理标记信息；
      </p>
      <p>您可以通过关闭定位功能，停止对您的地理位置信息的收集。</p>
      <h2>
        <a name="header-n202" class="md-header-anchor"></a
        ><strong>二、应用隐私权限读取调用说明</strong>
      </h2>
      <p>
        2.1
        拨打电话权限，此权限为案件云团队版以上版本的必要功能,方便用户拨打电话沟通协作。
      </p>
      <p>
        2.2修改或删除存储卡中的内容，用于应用实时将拍摄的照片保存至手机存储卡中。
      </p>
      <p>2.3读取存储卡中的内容，用于读取放在存储卡中的相册内容。</p>
      <p>
        2.4访问确切位置信息（使用 GPS
        和网络进行定位），为了更好匹配应用网络环境。
      </p>
      <p>2.5访问大致位置信息（使用网络进行定位），为了更好匹配应用网络环境。</p>
      <p>2.6相机权限，通过实时拍摄照片或录制视频等方式上传文件至案件云。</p>
      <p>2.7录制音频，用于用户在拨打电话时录取语音信息传输到对方。</p>
      <p>2.8读取通话状态和移动网络信息，用于判断网络质量。</p>
      <p>2.9修改系统设置，用于修改应用的权限管理</p>
      <h2>
        <a name="header-n221" class="md-header-anchor"></a
        ><strong>三、集成的第三方SDK名称</strong>
      </h2>
      <hr />
      <table>
        <thead>
          <tr>
            <th style="text-align: left">SDK名称</th>
            <th style="text-align: left">SDK包名</th>
            <th style="text-align: left">使用场景描述</th>
            <th style="text-align: left">可能获取的个人信息类型</th>
            <th style="text-align: left">调用的设备权限</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="text-align: left">淘宝</td>
            <td style="text-align: left">com.taobao</td>
            <td style="text-align: left">用于进行更优质的广告推荐服务</td>
            <td style="text-align: left">
              设备标识信息<br />（如IMEI、IDFA、Android ID、MAC、OAID等）
            </td>
            <td style="text-align: left">读取外置存储器、写入外置存储器</td>
          </tr>
          <tr>
            <td style="text-align: left">阿里</td>
            <td style="text-align: left">com.alipay</td>
            <td style="text-align: left">实现支付功能</td>
            <td style="text-align: left">
              设备标识信息<br />（如IMEI、IDFA、Android ID、MAC、OAID等）
            </td>
            <td style="text-align: left">读取外置存储器、写入外置存储器</td>
          </tr>
          <tr>
            <td style="text-align: left">fresco图片库</td>
            <td style="text-align: left">com.facebook.fresco</td>
            <td style="text-align: left">用于加载图片使用</td>
            <td style="text-align: left">
              存储的个人文件<br />（如IMEI、IDFA、Android ID、MAC、OAID等）
            </td>
            <td style="text-align: left">读取外置存储</td>
          </tr>
          <tr>
            <td style="text-align: left">小米push</td>
            <td style="text-align: left">com.xiaomi.mipush</td>
            <td style="text-align: left">用于小米手机的个性化推送</td>
            <td style="text-align: left">
              设备标识信息<br />（如IMEI、IDFA、Android ID、MAC、OAID等）
            </td>
            <td style="text-align: left">读取外置存储器、写入外置存储器</td>
          </tr>
          <tr>
            <td style="text-align: left">vivo</td>
            <td style="text-align: left">com.vivo</td>
            <td style="text-align: left">用于vivo手机的个性化推送</td>
            <td style="text-align: left">
              设备标识信息<br />（如IMEI、IDFA、Android ID、MAC、OAID等）
            </td>
            <td style="text-align: left">读取外置存储器、写入外置存储器</td>
          </tr>
          <tr>
            <td style="text-align: left">个推</td>
            <td style="text-align: left">com.getui</td>
            <td style="text-align: left">个性化消息推送</td>
            <td style="text-align: left">
              设备信息（如IMEI、IDFA、Android
              ID、MAC、OAID等）、应用列表信息、网络信息以及位置相关信息
            </td>
            <td style="text-align: left">读取外置存储器、写入外置存储器</td>
          </tr>
          <tr>
            <td style="text-align: left">个验一键登录</td>
            <td style="text-align: left">com.g.gysdk、com.igexin.sdk</td>
            <td style="text-align: left">一键登录</td>
            <td style="text-align: left">运营商信息</td>
            <td style="text-align: left">读取外置存储器、写入外置存储器</td>
          </tr>
        </tbody>
      </table>
      <p></p>
      <h2>
        <a name="header-n280" class="md-header-anchor"></a
        ><strong>四、我们可能如何使用信息</strong>
      </h2>
      <p>
        我们可能将在向您提供服务的过程之中所收集的信息用作下列用途：向您提供服务；
      </p>
      <p>
        在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；
      </p>
      <p>帮助我们设计新服务，改善我们现有服务；</p>
      <p>
        使我们更加了解您如何接入和使用我们的服务，从而针对性地回应您的个性化需求，例如语言设定、位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应；
      </p>
      <p>向您提供与您更加相关的广告以替代普遍投放的广告；</p>
      <p>评估我们服务中的广告和其他促销及推广活动的效果，并加以改善；</p>
      <p>软件认证或管理软件升级；</p>
      <p>让您参与有关我们产品和服务的调查。</p>
      <p>
        为了让您有更好的体验、改善我们的服务或您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某一项服务所收集的信息，以汇集信息或者个性化的方式，用于我们的该服务或其他服务。例如，在您使用我们的一项服务时所收集的信息，可能在另一服务中用于向您提供特定内容，或向您展示与您相关的、非普遍推送的信息。如果我们在相关服务中提供了相应选项，您也可以授权我们将该服务所提供和储存的信息用于我们的其他服务。
      </p>
      <h2>
        <a name="header-n299" class="md-header-anchor"></a
        ><strong>五、您如何访问和控制自己的个人信息</strong>
      </h2>
      <p>
        我们将尽一切可能采取适当的技术手段，保证您可以访问、更新和更正自己的注册信息或使用我们的服务时提供的其他个人信息。在访问、更新、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障账户安全。
      </p>
      <h2>
        <a name="header-n302" class="md-header-anchor"></a
        ><strong>六、我们可能分享的信息</strong>
      </h2>
      <p>
        除以下情形外，未经您同意，我们以及我们的关联公司不会与任何第三方分享您的个人信息：
      </p>
      <p>
        我们以及我们的关联公司，可能将您的个人信息与我们的关联公司、合作伙伴及第三方服务供应商、承包商及代理（例如代表我们发出电子邮件或推送通知的通讯服务提供商、为我们提供位置数据的地图服务供应商）分享，用作下列用途：
      </p>
      <p>向您提供我们的服务；</p>
      <p>实现“我们可能如何使用信息”部分所述目的；</p>
      <p>
        履行我们在《案件云服务协议》或本《隐私政策》中的义务和行使我们的权利；
      </p>
      <p>理解、维护和改善我们的服务。</p>
      <p>
        如我们或我们的关联公司与任何上述第三方分享您的个人信息，我们将努力确保该等第三方在使用您的个人信息时遵守本《隐私政策》及我们要求其遵守的其他适当的保密和安全措施。
      </p>
      <p>
        随着我们业务的持续发展，我们以及我们的关联公司有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移。
      </p>
      <p>
        我们或我们的关联公司还可能为以下需要而保留、保存或披露您的个人信息：
      </p>
      <p>遵守适用的法律法规；</p>
      <p>遵守法院命令或其他法律程序的规定；</p>
      <p>遵守相关政府机关的要求；</p>
      <p>
        为遵守适用的法律法规、维护社会公共利益，或保护我们的客户、我们或我们的集团公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。
      </p>
      <h2>
        <a name="header-n329" class="md-header-anchor"></a
        ><strong>七、信息安全</strong>
      </h2>
      <p>
        我们仅在本《隐私政策》所述目的所必需的期间和法律法规要求的时限内保留您的个人信息。
      </p>
      <p>
        我们使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。例如，在某些服务中，我们将利用加密技术（例如SSL）来保护您提供的个人信息。但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。
      </p>
      <h2>
        <a name="header-n334" class="md-header-anchor"></a
        ><strong>八、我们可能如何收集信息</strong>
      </h2>
      <p>
        我们或我们的第三方合作伙伴，可能通过cookies和web
        beacon收集和使用您的信息，并将该等信息储存为日志信息。
      </p>
      <p>
        我们使用自己的cookies和web
        beacon，目的是为您提供更个性化的用户体验和服务，并用于以下用途：
      </p>
      <p>
        记住您的身份。例如：cookies和web
        beacon有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供的有关您的喜好或其他信息；
      </p>
      <p>
        分析您使用我们服务的情况。例如，我们可利用cookies和web
        beacon来了解您使用我们的服务进行什么活动，或哪些服务最受您的欢迎；
      </p>
      <p>
        广告优化。Cookies和web
        beacon有助于我们根据您的信息，向您提供与您相关的广告而非进行普遍的广告投放。
      </p>
      <p>
        我们为上述目的使用cookies和web beacon的同时，可能将通过cookies和web
        beacon收集的非个人身份信息，经统计加工后提供给广告商或其他合作伙伴，用于分析用户如何使用我们的服务，并用于广告服务。
      </p>
      <p>
        我们的产品和服务上可能会有广告商或其他合作方放置的cookies和web
        beacon。这些cookies和web
        beacon可能会收集与您相关的非个人身份信息，以用于分析用户如何使用该等服务、向您发送您可能感兴趣的广告，或用于评估广告服务的效果。这些第三方cookies和web
        beacon收集和使用该等信息，不受本《隐私政策》约束，而是受相关使用者的隐私政策约束，我们不对第三方的cookies或web
        beacon承担责任。
      </p>
      <h2>
        <a name="header-n349" class="md-header-anchor"></a
        ><strong>九、广告服务</strong>
      </h2>
      <p>我们可能使用您的相关信息，向您提供与您更加相关的广告。</p>
      <p>
        我们也可能使用您的信息，通过我们的服务、电子邮件或其他方式向您发送营销信息，提供或推广我们或第三方的如下商品和服务：
      </p>
      <p>
        我们的商品或服务，我们的关联公司和合作伙伴的商品或服务，包括网上媒体服务、互动娱乐服务、社交网络服务、付款服务、互联网搜索服务、位置和地图服务、应用软件和服务、数据管理软件和服务、网上广告服务、互联网金融，以及其他社交媒体、娱乐、电子商务、资讯和通讯软件或服务（统称“互联网服务”）；
      </p>
      <p>
        第三方互联网服务供应商，以及与下列有关的第三方商品或服务：食物和餐饮、体育、音乐、电影、电视、现场表演及其他艺术和娱乐、书册、杂志和其他刊物、服装和配饰、珠宝、化妆品、个人健康和卫生、电子、收藏品、家用器皿、电器、家居装饰和摆设、宠物、汽车、酒店、交通和旅游、银行、保险及其他金融服务、会员积分和奖励计划，以及我们认为可能与您相关的其他商品或服务。
      </p>
      <p>
        如您不希望我们将您的个人信息用作前述广告用途，您可以通过我们在广告中提供的相关提示，或在特定服务中提供的指引，要求我们停止为上述用途使用您的个人信息。
      </p>
      <h2>
        <a name="header-n360" class="md-header-anchor"></a
        ><strong>十、我们可能向您发送的邮件和信息</strong>
      </h2>
      <p>邮件和信息推送</p>
      <p>
        您在使用我们的服务时，我们可能使用您的信息向您的设备发送电子邮件、新闻或推送通知。如您不希望收到这些信息，可以按照我们的相关提示，在设备上选择取消订阅。
      </p>
      <p>与服务有关的公告</p>
      <p>
        我们可能在必要时（例如因系统维护而暂停某一项服务时）向您发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。
      </p>
      <h2>
        <a name="header-n369" class="md-header-anchor"></a
        ><strong>十一、隐私政策的适用例外</strong>
      </h2>
      <p>
        本《隐私政策》仅适用于我们所收集的信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，我们对任何第三方使用由您提供的信息不承担任何责任。
      </p>
      <h2>
        <a name="header-n372" class="md-header-anchor"></a
        ><strong>十二、隐私政策的适用范围</strong>
      </h2>
      <p>
        除某些特定服务外，我们所有的服务均适用本《隐私政策》。这些特定服务将适用特定的隐私政策。针对某些特定服务的特定隐私政策，将更具体地说明我们在该等服务中如何使用您的信息。该特定服务的隐私政策构成本《隐私政策》的一部分。如相关特定服务的隐私政策与本《隐私政策》有不一致之处，适用该特定服务的隐私政策。
      </p>
      <p>
        除本《隐私政策》另有规定外，本《隐私条款》所用词语将与《案件云服务协议》所定义的词语具有相同的涵义。
      </p>
      <p>请您注意，本《隐私政策》不适用于以下情况：</p>
      <p>通过我们的服务而接入的第三方服务（包括任何第三方网站）收集的信息；</p>
      <p>通过在我们服务中进行广告服务的其他公司或机构所收集的信息。</p>
      <h2>
        <a name="header-n383" class="md-header-anchor"></a
        ><strong>十三、变更</strong>
      </h2>
      <p>
        我们可能适时修订本《隐私政策》的条款，该等修订构成本《隐私政策》的一部分。如该等修订造成您在本《隐私政策》下权利的实质减少，我们将在修订生效前通过在主页上显著位置提示或向您发送电子邮件或以其他方式通知您。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本《隐私政策》的约束。
      </p>
      <h2>
        <a name="header-n383" class="md-header-anchor"></a
        ><strong>十四、联系我们</strong>
      </h2>
      <p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left: 0;line-height:30px"><span style="font-size: 14px;font-family:'微软雅黑',sans-serif">如果您对隐私保护政策或数据处理有任何疑问、意见或建议，可以通过邮件或电话与我们联系，我们联系（邮寄）方式如下：</span></p>
      <p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left: 0;line-height:30px"><span style="font-size: 14px;font-family:'微软雅黑',sans-serif">电话：15872364417</span></p>
      <p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left: 0;line-height:30px"><span style="font-size: 14px;font-family:'微软雅黑',sans-serif">联系部门： 数据及隐私保护小组 </span></p>
      <p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left: 0;line-height:30px"><span style="font-size: 14px;font-family:'微软雅黑',sans-serif">地址： 上海市浦东新区陆家嘴环路1366号富士康大厦12层（邮编200120）</span></p>
      <p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left: 0;line-height:30px"><span style="font-size: 14px;font-family:'微软雅黑',sans-serif">电子邮箱： lx@law086.com</span></p>
      <p style="margin-top:0;margin-right:48px;margin-bottom:18px;margin-left: 0;line-height:30px"><span style="font-size:14px;font-family:'微软雅黑',sans-serif">&nbsp;</span></p>
      <p></p>
      <p></p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
table {
  padding: 0px;
  word-break: initial;
  font-size: 16px;
}
table tr {
  border-top: 1px solid rgb(204, 204, 204);
  background-color: white;
  margin: 0px;
  padding: 0px;
}
table tr:nth-child(2n) {
  background-color: rgb(248, 248, 248);
}
table tr th {
  font-weight: bold;
  border: 1px solid rgb(204, 204, 204);
  text-align: left;
  margin: 0px;
  padding: 6px 13px;
}
table tr td {
  border: 1px solid rgb(204, 204, 204);
  text-align: left;
  margin: 0px;
  padding: 6px 13px;
}
table tr th:first-child,
table tr td:first-child {
  margin-top: 0px;
}
table tr th:last-child,
table tr td:last-child {
  margin-bottom: 0px;
}
.serve-content-txt {
  background: #fff;
}
h1 {
  font-size: 40px;
  color: rgb(51, 51, 51);
}
h2 {
  font-size: 36px !important;
  color: rgb(51, 51, 51);
}
h1 strong,
h2 strong {
  font-weight: bold;
}
</style>