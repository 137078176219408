<template>
    <div class="deployment">
        <div class="item_wrap step1">
            <div class="item_imgbox">
                <img :src="item_img1" width="100%" />
            </div>
            <div class="item_btm">
                <div class="item_tit">
                    专属于企业的新一代案件管理<br/>解决方案
                </div>
                <div class="item_txt1">
                    企业专属的私有化部署方案
                </div>
                <div class="item_txt">
                    专注企业法务开发，以满足企业管理需求为使命<br/>多年法律科技服务经验，万名法律人用户的智慧结晶<br/>基于最新数据安全要求，为企业提供高水平的信息安全管控
                </div>
                <div class="item_btn">
                    <span @click="jumpGuide">立即获取企业方案</span>
                </div>
            </div>
        </div>
        <div class="item_wrap1">
            <div class="item_wrap1_tit">企业需求及解决方案</div>
            <ul class="item_wrap1_list">
                <li class="item_wrap1_li" v-for="(item,index) in qyneedlist" :key="index">
                    <div class="item1">
                        <div class="item1_wrap">
                            <div class="item1_main">
                                <div class="tit"><span>{{index + 1}}.</span>{{item.tp.tit}}</div>
                                <p class="txt1">需求痛点</p>
                                <p class="txt2">{{item.tp.txt2}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="item2">
                        <div class="item2_wrap">
                            <div class="item2_main">
                                <div class="rt_faitem">
                                    <div class="faitem_main">
                                        <img :src="jjfaimg" />
                                        <span>
                                            <em>解</em>
                                            <em>决</em>
                                            <em>方</em>
                                            <em>案</em>
                                        </span>
                                    </div>
                                </div>
                                <div class="tit">{{item.btm.tit}}</div>
                                <p class="txt">{{item.btm.txt}}</p>
                                <img width="100%" :src="item.btm.img" />
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="item_wrap2">
            <div class="item_wrap2_tit">产品介绍</div>
            <dl class="deployment_prolist">
                <dt class="deployment_protablist">
                    <div v-for="(item,index) in tablist" :key="index" :class="{active:tab_active == index}" @click.stop="tab_active = index">
                        <span>{{item}}</span>
                    </div>
                </dt>
                <swiper class="main_item_swiper" :options="swiperOption" ref="Swiper">   
                    <swiper-slide v-for="(item,index) in prolist" :key="index">
                        <dd class="deployment_prodd">
                            <div class="tit">{{item.tit}}</div>
                            <p class="txt" v-for="(it,ind) in item.txt" :key="ind" v-html="it"></p>
                            <img :src="item.img" width="100%">
                        </dd>
                    </swiper-slide>
                </swiper>
            </dl>
        </div>
        <div class="item_wrap3">
            <div class="item_wrap3_wrap">
                <img :src="serviceImg" width="100%" />
                <div class="item_wrap3_main">
                    <div class="tit">支持企业私有化部署</div>
                    <p class="txt">私有化部署方案是指将案件云的案件管理服务部署在企业自有服务器，支持公有云，私有云与自有机房，在企业数据自主掌控的前提下，使用案件云的案件管理方案，并支持通过 API 与现有系统打通，让现有系统与案件云高效链接，构建企业内部的诉讼管理系统。</p>
                </div>
            </div>
        </div>
        <div class="item_wrap4">
            <div class="item_wrap4_tit">
                方案价值
            </div>
            <ul class="item_wrap4_list">
                <li>
                    <div class="txtbox">
                        <div>更高效</div>
                        <p>信息集中管理、内外协作办案，案件进度、资料信息实时同步。</p>
                    </div>
                </li>
                <li>
                    <div class="txtbox">
                        <div>更安全</div>
                        <p>系统为企业提供物理、系统、数据、文件、内容 5 层防护，操作过程全程 留痕，异常行为实时监控，有效控制数据风险。</p>
                    </div>
                </li>
                <li>
                    <div class="txtbox">
                        <div>降成本</div>
                        <p>企业可大幅提升案件管理效率，并通过对数据的有效利用提升风险管控能力，不断降低未来法律风险带来的隐形成本。</p>
                    </div>
                </li>
                <li>
                    <div class="txtbox">
                        <div>数字化</div>
                        <p>利用数字化技术、流程化管控、数据分析，可以为企业法务部门提供完备 的线上管控、分析平台，真正帮战企业实现法务数字化快速建设。</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="item_wrap5">
            <div class="item_wrap5_tit">
                与优秀企业法务部门<br/>一起高效办公
            </div>
            <div class="item_wrap5_list">
                <div class="item" v-for="(item,index) in gs_list" :key="index">
                    <img :src="item" />
                </div>
            </div>
        </div>
        <div class="item_wrap6">
            <div class="item_wrap6_tit">企业专属的私有化部署方案</div>
            <p class="item_wrap6_txt">基于最新数据安全要求，为企业提供高水平的信息安全管控</p>
            <div class="item_wrap6_btn">
                <span @click="jumpGuide">立即获取企业方案</span>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import { Footer} from '@/components'
import { swiper, swiperSlide } from "vue-awesome-swiper";
function requireImg(str) {
    return require("pbimages/deployment/" + str);
};console.log(this)
export default {
    components:{
        Footer,
        swiper,
        swiperSlide
    },
    computed:{
        swiper(){
            return this.$refs.Swiper
        }
    },
    data(){
        return{
            item_img1: requireImg('bg.jpg'),
            jjfaimg: requireImg('jjfa.png'),
            jej1:requireImg('jej1.png'),
            swiperOption:{
                slidesPerView: 1,
                spaceBetween: 10,
                loop: false,     
                on: {
                    slideChangeTransitionStart: (() =>{console.log(this);
                       let swiper = this.$refs.Swiper.swiper;
                       let i = swiper.activeIndex;
                       this.tab_active = i;
                    }),
                },       
            },
            qyneedlist:[
                {
                    tp:{tit:"提升协同办案效率",txt1:'需求痛点',txt2:'企业案件管理通常涉及跨部门、跨地区协作，部分案件还需要由外部律师参与处理，但多数企业欠缺统一的管理系统，致使数据分散、信息割裂、沟通滞后，风险难以有效控制；'},
                    btm:{tit:'无间协作，高效配合',txt:'通过企业案件云系统，企业可以设置不同的角色与权限，如业务部门上报案件、法务人员跟进处理、管理人员把控进度，甚至可直接从供应商库中选聘律师加入案件办理，随时共享案件情况。',
                       img:requireImg('jej1.png'),
                    }
                },
                {
                    tp:{tit:"升级案件管理方式",txt1:'需求痛点',txt2:'企业案件种类繁多，关注点及风险点也不尽相同，然而，由于多数企业缺乏合适的工具，致使需人工处理大量繁琐工作，效率不高，容易出错，更令企业无暇从宏观视角优化案件管控'},
                    btm:{tit:'标准管理，精细办案',txt:'通过企业案件云系统，企业可自由定制不同类案件的处理模型与办案流程，实现案件标准化管理，提高办案效率、优化风险控制，并不断利用数据，优化工作流，同时，高自由度的字段定制，让企业既从宏观角度统筹观察企业整体涉诉状况，亦可随时聚焦于特定案件的情况、进展、办案策略，有效控制风险',
                       img:requireImg('jej3.png'),
                    }
                },
                {
                    tp:{tit:"充分利用沉淀数据",txt1:'需求痛点',txt2:'大多数行业中，大数据的应用已经十分成熟，企业可利用数据反哺业务，实现不断升级，然而在法律领域中，多数企业却仍然限于传统的管理方式，浪费了大量数据'},
                    btm:{tit:'数据反哺，风控升级',txt:'案件云系统可协助企业建立定制化的数据可视化模型，开展多维度的数据分析，辅助企业在案件分配策略、诉讼调解策略、成本控制策略等方面优化决策。',
                       img:requireImg('jej4.png'),
                    }
                },
                {
                    tp:{tit:"无缝对接企业系统",txt1:'需求痛点',txt2:'随着信息化的发展，企业内部往往有多类生产系统，如人事系统、财务系统、业务系统，如系统之间互相割裂，将导致多数工作需重复进行，效率低，且易出错。'},
                    btm:{tit:'一键同步，自动整合',txt:'案件云系统可利用标准接口，与OA、企业微信等企业系统进行对接，实现信息统一管理，组织架构、人员账号、业务流程均可互通，大幅提升管理效率。',
                       img:requireImg('jej5.png'),
                    }
                },
            
            ],
            tablist:["案件管理","批量案件",'字段配置'],
            tab_active:0,
            prolist:[
                {tit:'案件精细管理',
                    txt:['精细化维护案件基础信息、案件进程、办案过程、费用支出、案件文档上传，保证案件信息完整记录。',
                     '整合：跨越地理空间，跨区域、跨部门案件协同管理<br/>灵活：高自由度管理，随时、随地配置公司所需字段<br/>聚焦：高效信息处理，从检索到统计，多种功能辅助企业聚焦案件管理核心<br/>安全：精细安全管控，全面保障企业数据安全'
                    ],
                    img:requireImg('pro1.jpg')
                },
                {tit:'批量案件管理',
                    txt:['支持批量上传或修改案件、批量上传或下载关联文件、批量生产标准化文书、批量结案、批量选择案件跟进人等操作，可解决大批量案件管理的业务需求。'],
                    img:requireImg('pro2.jpg')
                },
                {tit:'字段灵活可配',
                    txt:['案件基础字段均可自定义配置，支持地区、日期、数字、人员等数据类型，同时字段可自由选择是否加入排序、搜索等，后期业务需求调整可及时响应。',
                    ],
                    img:requireImg('pro3.jpg')
                },
            ],
            serviceImg: requireImg("service.jpg"),
            gs_list:[
                requireImg("gs1.png"),
                requireImg("gs2.png"),
                requireImg("gs3.png"),
            ]
        }
        
    },
    methods:{
        jumpGuide(){
            window.location.href = "http://oalaw86com.mikecrm.com/0OvcIFQ";
        },
    }
}
</script>

<style lang="stylus" scoped>
    .swiper-container{
        overflow: hidden;
    }
    .deployment{
        background-color: #fff;
    }
    .item_wrap{
        &.step1{
            // background: url("../../public/images/case/bg.jpg") no-repeat;
            // background-size: cover;
            // background-position-x: 70%;
            background:  linear-gradient(90deg, #F8F8FA 0%, #EEEFF3 100%);
            min-height: 1000px;
        }
        position relative;
        .item_imgbox{
            padding: 0;
            
        }
        .item_btm{
            //background: #F2F3F5;
            padding: 0 0 60px 0;
            position: absolute;
            bottom: 0;
            .item_tit{
                font-size: 48px;
                font-weight: 800;
                color: #303443;
                line-height: 58px;
                text-align: center;
                
            }
            .item_txt{
                font-size: 26px;
                font-weight: 500;
                color: #5E606B;
                line-height: 46px;;
                padding: 20px 35px 35px;
                text-align: center;
            }
            .item_txt1{
                font-size: 32px;
                font-weight: 500;
                color: #FF9000;
                text-align: center;
                padding: 25px 0 0;
            }
            .item_btn{
                display: flex;
                justify-content: center;
                span{
                    width: 308px;
                    height: 80px;
                    background: #FF9000;
                    border-radius: 10px;
                    color: #fff;
                    font-size: 30px;
                    font-weight: bold;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .item_wrap1{
        padding: 80px 25px 50px;
        &_tit{
            font-size: 48px;
            font-weight: bold;
            color: #2F211A;
            text-align: left;
            padding: 0 0 60px 0;
            text-align: center;
        }
        .item_wrap1_list{
            .item_wrap1_li{
                margin: 0 0 60px 0;
                &:last-child{
                    margin: 0;
                }
            }
        }
        .item1{
            padding: 0 50px;
            .item1_wrap{
                background: linear-gradient(152deg,#FFE3BF,#FFF);
                //box-shadow: -11px -7px 29px 1px rgba(237, 218, 194, 0.35), 3px 5px 18px 0px #FFFFFF;
                border-radius: 20px 20px 0 0;
                position: relative;
                box-sizing: border-box;
                padding: 2px;
                flex: 1;
                .item1_main{
                    border-radius: 20px 20px 0 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(25deg,#FFF 0,#FFFCF6 100%);
                    position: relative;
                    display: block;
                    padding: 20px 30px;
                    box-sizing: border-box;
                }
                
            }
            
            .tit{
                font-size: 30px;
                font-weight: 800;
                color: #2F211A;
                display: flex;
                align-items: flex-end;
                span{
                    font-size: 60px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-style: italic;
                    color: #FF9000;
                    display: inline-block;
                    margin: 0 10px 0 0;
                }
            }
            .txt1{
                font-size: 24px;
                font-weight: 500;
                color: #F25B5B;
                padding: 30px 0 15px;
            }
            .txt2{
                font-size: 26px;
                font-weight: 500;
                color: #6E6662;
                line-height: 36px;
            }
        }
        .item2{
            .item2_wrap{
                background: linear-gradient(-42deg,#FFC1A2,#FFF);
                box-shadow: -8px -6px 18px 2px rgba(237, 218, 194, 0.35), 3px 5px 18px 0px #FFFFFF;
                border-radius: 20px;
                position: relative;
                box-sizing: border-box;
                padding: 2px;
                .item2_main{
                    border-radius: 20px;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(-24deg,#FFF5F0 0,#FFFFF5 100%);
                    position: relative;
                    text-align: center;
                    display: block;
                    //min-height: 725px;
                    .rt_faitem{
                        position: absolute;
                        top: 0;
                        left: 34px;
                        .faitem_main{
                            position:relative;
                            img{
                                width: 85px;
                            }
                            span{
                                position: absolute;
                                width: 100%;
                                left: 0;
                                top: 0;
                                padding: 10px 15px 0;
                                font-size:20px;
                                font-weight: 500;
                                color: #FFF;
                                line-height: 24px;
                                display: flex;
                                justify-content: space-between;
                                flex-wrap: wrap;
                                box-sizing: border-box;
                                em{
                                    font-style: normal;
                                    // &:first-child{
                                    //     margin: 0 10px 10px 0;
                                    // }
                                    // &:nth-child(2){
                                    //     margin: 0 10px 10px 0;
                                    // }
                                }
                            }
                        }
                    } 
                    .tit{
                        font-size: 46px;
                        font-family: PingFang SC;
                        font-weight: 800;
                        color: #2F211A;
                        padding: 80px 0 25px;
                    }
                    .txt{
                        font-size: 26px;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: #6E6662;
                        line-height: 36px;
                        text-align: center;
                        padding: 0 44px;
                        width: 100%;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
    .item_wrap2{
        padding: 80px 0 0;
        &_tit{
            font-size: 48px;
            font-weight: bold;
            color: #2F211A;
            text-align: left;
            padding: 0 0 50px 0;
            text-align: center;
        }
        .deployment_protablist{
            width: 100%;
            margin: 0 auto 60px;
            display: flex;
            border-bottom: 1px solid #EEE9E4;
        }
        .deployment_protablist>div{
            cursor: pointer;
            width: calc(100% / 3);
            display: flex;
            justify-content: center;
            font-size: 16px;
            color: #5D5F6A;
            font-weight: normal;
            height: 56px;
            box-sizing: border-box;
            align-items: center;
            border-right: 1px solid #E3E3E3;
            border-top: 1px solid #E3E3E3;
            &:first-child{
                border-left: 1px solid #E3E3E3;
            }
        }
        .deployment_protablist span{
            position: relative;
        }
        .deployment_protablist>div.active{
            cursor: default;
            color: #fff;
            background: #FF9000;
            border-color: #FF9000;
        }
        .deployment_protablist>div.active span:before{
            position: absolute;
            width: calc(100%);
            left: 0;
            bottom: 0;
            height: 2px;
            background: #FF9000;
            content: '';
        }
        .deployment_prodd{
            padding: 0 30px 80px 35px;
            .tit{
                font-size: 34px;
                font-weight: bold;
                color: #2F211A;
            }
            .txt{
                font-size: 26px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #6E6662;
                line-height: 36px;
                margin: 20px 0 0;
            }
            img{
                display: block;
                margin: 22px 0 0;
            }
        }
    }
    .item_wrap3{
        .item_wrap3_wrap{
            position: relative;
            img{
                display: block;
            }
            .item_wrap3_main{
                position: absolute;
                top: 0;
                left: 0;
                .tit{
                    font-size: 48px;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: 60px;
                    text-align: center;
                    padding: 74px 0 25px;
                }
                .txt{
                    font-size: 26px;
                    font-weight: 500;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 40px;
                    padding: 0 36px;
                    box-sizing: border-box;
                    width: 100%;
                }
            }
        }
    }
    .item_wrap4{
        &_tit{
            font-size: 48px;
            font-weight: bold;
            color: #2F211A;
            text-align: left;
            padding: 80px 0 60px 0;
            text-align: center;
        }
        &_list{
            padding: 0 35px;
            li{
                width: 100%;
                min-height: 284px;
                background: linear-gradient(-24deg, #FFF5F0 0%, #FFFFF5 100%);
                position: relative;
                padding: 40px 30px 23px;
                margin: 0 0 20px 0;
                box-sizing: border-box;
                .txtbox {
                    position: relative;
                    z-index: 2;
                    div {
                        font-size: 34px;
                        font-weight: 600;
                        color: #222222;
                        line-height: 34px;
                        padding: 0 0 40px 0;
                        position: relative;
                        margin: 0 0 20px 0;
                        &::after {
                            content: "";
                            position: absolute;
                            width: 60px;
                            height: 6px;
                            background: #FF9000;
                            left: 0;
                            bottom: 0;
                        }
                    }
                    p {
                        font-size: 26px;
                        font-weight: 500;
                        color: #6E6662;
                        line-height: 46px;
                    }
                }
            }
        }
    }
    .item_wrap5{
        padding: 71px 0 60px;
        &_tit{
            font-size: 48px;
            font-weight: bold;
            color: #2F211A;
            line-height: 60px;
            text-align: center;
            margin: 0 0 56px 0;
        }
        &_list{
            padding: 0 85px;
            .item{
                height: 128px;
                margin: 0 0 20px 0;
                display: flex;
                align-items: center;
                justify-content: center;
                &:first-child{
                    background: linear-gradient(90deg, #F2FAFF 0%, #FBFBFB 100%);
                    img{
                        display: block;
                        height: 59px;
                    }
                }
                &:nth-child(2){
                    background: linear-gradient(90deg, #FFF5EE 0%, #FBFBFB 100%);
                    img{
                        display: block;
                        height: 50px;
                    }
                }
                &:nth-child(3){
                    background: linear-gradient(90deg, #F3F9F2 0%, #FBFBFB 100%);
                    img{
                        display: block;
                        height: 59px;
                    }
                }
            }
        }
    }
    .item_wrap6{
        background: linear-gradient(90deg, #FFAE00 0%, #FF9000 100%);
        padding: 80px 0;
        &_tit{
        font-size: 48px;
        font-weight: 800;
        color: #FFFFFF;
        text-align: center;
        }
        &_txt{
        font-size: 26px;
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;
        padding: 30px 0 60px;
        }
        &_btn{
        span{
            width: 308px;
            height: 80px;
            background: #FFFFFF;
            border-radius: 10px;
            font-size: 30px;
            font-weight: bold;
            color: #FABA4A;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
        }
        }
    }
</style>