<template>
  <div class="cantainer"><img src="../assets/images/downApp.png" alt=""></div>
</template>
<script>
export default {
  data(){
    return{
      wex:'',
      ios:false,
    }
  },
  created(){
 
    this.wex=this.is_weixin()
    this.ios=this.isIOS()
    const a_b = document.createElement('a');
    if(this.ios){
        a_b.href = 'https://apps.apple.com/cn/app/案件云/id1575648009';
        a_b.click()
      }else{
         if(!this.wex){
            this.$fetch.api_article.downloadApp().then(res=>{
                console.log(res)
                a_b.href = res.data.url;
                a_b.click()
              })
          }
        //设置href指向
        //设置图片下载名字
        //点击a标签
      }
   
  },
  methods:{
    is_weixin(){
    var ua = navigator.userAgent.toLowerCase();
    if(ua.match(/MicroMessenger/i)=="micromessenger") {
        return true;
    } else {
        return false;
    }
    },
    isIOS(){
      if(/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)){
        //document.write("This is iOS'browser.");//这是iOS平台下浏览器
        return true;
      }else{
        return false
      }
    },
  }
}
</script>

<style lang="stylus"  scoped>
  .cantainer{
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    padding: 10px 0;
    box-sizing: border-box;
  }
  img{
    width: 100%;
  }
</style>